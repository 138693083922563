import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
  item: {
    backgroundColor: theme.palette.darkBlue,
    color: '#FFF',
    padding: theme.spacing(1),
    textAlign: 'center',
    minHeight: '160px',
    borderRadius: '2em',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontSize: '1rem',
  },
}))

function DataBox({ title, children }) {
  const classes = useStyles()

  return (
    <div className={classes.item}>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

export default DataBox
