import { IconButton, Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MuiAlert from '@material-ui/lab/Alert'
import { deletePost, list } from 'api/library/posts'
import TableIcon from 'components/TableIcon'
import TableSearchBox from 'components/TableSearchBox'
import TableToolbar from 'components/TableToolbar'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import debounce from 'lodash.debounce'
import { useContext, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  pagination: {
    marginBottom: '60px',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  fabShift: {
    bottom: 90,
  },
})

function List(props) {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const [pages, setPages] = useState({})
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [hideContent, setHideContent] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [filters, setFilters] = useState({
    search: '',
  })
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setPages(await list('pages'))
      setLoading(false)
      setHideContent(false)
    }

    if (props.location?.state) {
      setSnackbarSeverity(props.location.state.severity)
      setSnackbarMessage(props.location.state.message)
      setSnackbarOpen(true)
      props.history.replace()
    }

    fetchData()
  }, [setLoading, props.location, props.history])

  const handleChangePage = async (event, newPage) => {
    setLoading(true)
    setPages(
      await list('pages', '', parseInt(pages.meta.per_page), parseInt(newPage + 1))
    )
    setLoading(false)
  }

  const handleChangeRowsPerPage = async event => {
    setLoading(true)
    setPages(await list('pages', '', parseInt(event.target.value, 10)))
    setLoading(false)
  }

  const handleOpenDeleteDialog = id => {
    setDeleteId(id)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleChangeSearchFilter = async event => {
    const newFilters = { ...filters, search: event.target.value }
    setFilters(newFilters)
    debouncedSearch(parseInt(pages.meta.per_page), newFilters)
  }

  const debouncedSearch = useRef(
    debounce(async (perPage, newFilters) => {
      setLoading(true)
      setPages(await list('pages', '', perPage, 1, newFilters))
      setLoading(false)
    }, 1000)
  ).current

  const handleDelete = async id => {
    setLoading(true)
    const success = await deletePost('pages', id)

    if (success) {
      setPages(await list('pages', '', parseInt(pages.meta.per_page)))
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully deleted page')
    } else {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to delete page')
    }

    setDeleteOpen(false)
    setLoading(false)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Pages
      </Typography>
      <TableContainer component={Paper}>
        <TableToolbar>
          <TableSearchBox label='Search pages' handleChange={handleChangeSearchFilter} />
        </TableToolbar>

        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages.data.map(row => (
              <TableRow key={row.id}>
                <TableCell scope='row'>{row.name}</TableCell>
                <TableCell scope='row'>{row.published ? 'Published' : 'Draft'}</TableCell>
                <TableCell align='right'>
                  <Tooltip title='Edit'>
                    <IconButton
                      aria-label='edit'
                      color='default'
                      component={NavLink}
                      to={`/site-content/pages/${row.id}`}>
                      <TableIcon icon='edit' color='yellow' />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Delete'>
                    <IconButton
                      aria-label='delete'
                      onClick={() => handleOpenDeleteDialog(row.id)}>
                      <TableIcon icon='delete' />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 15, 25]}
        component='div'
        count={pages.meta.total}
        rowsPerPage={parseInt(pages.meta.per_page)}
        page={pages.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='alert-dialog-title'>{'Page Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleDelete(deleteId)}
            color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Fab
        className={`${classes.fab} ${user?.live_chat_url !== '' && classes.fabShift}`}
        color='secondary'
        aria-label='add'
        component={NavLink}
        to='/site-content/pages/create'>
        <AddIcon />
      </Fab>
    </div>
  )
}

export default List
