import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from 'contexts/AuthContext'
import NotFound from 'containers/not-found/NotFound'
import BreadcrumbContext from 'contexts/BreadcrumbContext'
import ComponentWithBreadCrumbs from './ComponentWithBreadCrumbs'

function AuthenticatedRoute({
  types = [],
  roles = [],
  permission = '',
  component: C,
  breadcrumbs,
  title,
  ...routeProps
}) {
  const { authenticating, authenticated, user, hasPermission } = useContext(AuthContext)

  const hasType = () => {
    if (types.length > 0 && !types.includes(user.type)) {
      return false
    }

    return true
  }

  const hasRole = () => {
    if (roles.length > 0 && !roles.includes(user.role)) {
      return false
    }

    return true
  }

  const userHasPermission = () => {
    if (permission && !hasPermission(permission)) {
      return false
    }

    return true
  }

  return (
    !authenticating && (
      <Route
        {...routeProps}
        render={props =>
          authenticated ? (
            hasType() && hasRole() && userHasPermission() ? (
              <BreadcrumbContext>
                <ComponentWithBreadCrumbs
                  component={C}
                  {...props}
                  breadcrumbs={breadcrumbs}
                />
              </BreadcrumbContext>
            ) : (
              <NotFound />
            )
          ) : (
            <Redirect
              to={`/login?redirect=${props.location.pathname}${props.location.search}`}
            />
          )
        }
      />
    )
  )
}

export default AuthenticatedRoute
