import { Box, IconButton, Tooltip } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import RestoreIcon from '@material-ui/icons/Restore'
import MuiAlert from '@material-ui/lab/Alert'
import {
  categoryMoveDown,
  categoryMoveUp,
  deleteProduct,
  list,
  moveDown,
  moveUp,
  restoreProduct,
  setFavourite,
} from 'api/products/hardware'
import CostPriceTableDisplay from 'components/CostPriceTableDisplay'
import ProductCategories from 'components/ProductCategories'
import ProductDeleteModal from 'components/ProductDeleteModal'
import ProductFavourite from 'components/ProductFavourite'
import ProductMoveUpDown from 'components/ProductMoveUpDown'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import TableIcon from 'components/TableIcon'
import { AuthContext } from 'contexts/AuthContext'
import useProductsList from 'hooks/ProductsList'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  pagination: {
    marginBottom: '60px',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  fabShift: {
    bottom: 90,
  },
  muted: {
    '& > *': {
      color: theme.palette.muted,
    },
  },
}))

function List(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { loading } = useContext(LoadingContext)

  const productsList = useProductsList({
    queryKey: 'hardware-products',
    listFn: list,
    favouriteFn: setFavourite,
    deleteFn: deleteProduct,
    restoreFn: restoreProduct,
    moveUpFn: moveUp,
    moveDownFn: moveDown,
    categoryMoveUpFn: categoryMoveUp,
    categoryMoveDownFn: categoryMoveDown,
  })

  const handleChangePage = (event, other) => {
    productsList.setPage(other + 1)
  }

  const handleChangeRowsPerPage = event => {
    productsList.setPerPage(parseInt(event.target.value, 10))
  }

  const handleOpenDeleteDialog = (id, isOwner) => {
    productsList.setDeleteId(id)
    productsList.setDeleteIsOwner(isOwner)
    productsList.setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    productsList.setDeleteOpen(false)
  }

  const handleDelete = async id => {
    productsList.deleteMutation.mutate({ id })
  }

  const handleRestore = async id => {
    productsList.restoreMutation.mutate({ id })
  }

  const handleMoveUp = id => {
    productsList.moveUpMutation.mutate({ id })
  }

  const handleMoveDown = id => {
    productsList.moveDownMutation.mutate({ id })
  }

  const handleAddProductToFavourites = async id => {
    productsList.favouriteMutation.mutate({ id: id, value: true })
  }

  const handleRemoveProductFromFavourites = async id => {
    productsList.favouriteMutation.mutate({ id: id, value: false })
  }

  const getDisplayPrice = price => {
    if (price === 0) {
      return price.toFixed(2)
    } else if (price > 0) {
      return (price / 100).toFixed(2)
    }

    return ''
  }

  if (productsList.query.isInitialLoading) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Devices
      </Typography>

      <Box marginBottom={1}>
        <ProductCategories
          queryKey='hardware-products'
          categories={productsList.query.data.meta.categories}
          productTypeId={5}
          moveUp={categoryMoveUp}
          moveDown={categoryMoveDown}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Product Code</TableCell>
              <TableCell>{t('Upfront Cost')}</TableCell>
              <TableCell>{t('Monthly Cost')}</TableCell>
              <TableCell>{t('Upfront RRP')}</TableCell>
              <TableCell>{t('Monthly RRP')}</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsList.query.data.data.map(row => (
              <TableRow key={row.id} className={`${row.deleted ? classes.muted : ''}`}>
                <TableCell scope='row'>{row.category}</TableCell>
                <TableCell scope='row'>{row.name}</TableCell>
                <TableCell scope='row'>{row.product_code}</TableCell>
                <TableCell scope='row'>
                  <CostPriceTableDisplay
                    value={getDisplayPrice(row.upfront_cost_price)}
                  />
                </TableCell>
                <TableCell scope='row'>
                  <CostPriceTableDisplay
                    value={getDisplayPrice(row.monthly_cost_price)}
                  />
                </TableCell>
                <TableCell scope='row'>{getDisplayPrice(row.upfront_rrp)}</TableCell>
                <TableCell scope='row'>{getDisplayPrice(row.monthly_rrp)}</TableCell>
                <TableCell align='right'>
                  <ProductMoveUpDown
                    productId={row.id}
                    onMoveUp={handleMoveUp}
                    onMoveDown={handleMoveDown}
                  />
                  <ProductFavourite
                    productId={row.id}
                    isFavourite={row.is_favourite}
                    onAdd={handleAddProductToFavourites}
                    onRemove={handleRemoveProductFromFavourites}
                  />
                  {row.deleted ? (
                    <Tooltip title='Restore product' placement='top'>
                      <IconButton
                        aria-label='restore'
                        color='default'
                        onClick={() => handleRestore(row.id)}>
                        <RestoreIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <React.Fragment>
                      <Tooltip title='Edit' placement='top'>
                        <IconButton
                          aria-label='edit'
                          color='default'
                          component={NavLink}
                          to={`/products/hardware/update/${row.id}`}>
                          <TableIcon icon='edit' color='yellow' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Delete' placement='top'>
                        <IconButton
                          aria-label='delete'
                          color='default'
                          onClick={() => handleOpenDeleteDialog(row.id, row.is_owner)}>
                          <TableIcon icon='delete' color='red' />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 15, 25]}
        component='div'
        count={productsList.query.data.meta.total}
        rowsPerPage={parseInt(productsList.query.data.meta.per_page)}
        page={productsList.query.data.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ProductDeleteModal
        open={productsList.deleteOpen}
        handleClose={handleCloseDeleteDialog}
        isOwner={productsList.deleteIsOwner}
        loading={loading}
        handleDelete={() => handleDelete(productsList.deleteId)}
      />

      <Snackbar
        open={productsList.snackbarOpen}
        autoHideDuration={6000}
        onClose={productsList.handleSnackbarClose}>
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={productsList.handleSnackbarClose}
          severity={productsList.snackbarSeverity}>
          {productsList.snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Fab
        className={`${classes.fab} ${user.live_chat_url !== '' && classes.fabShift}`}
        color='secondary'
        aria-label='add'
        component={NavLink}
        to='/products/hardware/create'>
        <AddIcon />
      </Fab>
    </div>
  )
}

export default List
