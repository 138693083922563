import { IconButton, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import TableIcon from './TableIcon'

function ProductMoveUpDown({ productId, onMoveUp, onMoveDown }) {
  return (
    <>
      <Tooltip title='Move Up' placement='top'>
        <IconButton color='default' onClick={() => onMoveUp(productId)}>
          <TableIcon icon='up' color='green' />
        </IconButton>
      </Tooltip>

      <Tooltip title='Move Down' placement='top'>
        <IconButton color='default' onClick={() => onMoveDown(productId)}>
          <TableIcon icon='down' color='red' />
        </IconButton>
      </Tooltip>
    </>
  )
}

ProductMoveUpDown.propTypes = {
  productId: PropTypes.string.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
}

export default ProductMoveUpDown
