import config from 'config'
import { getJsonHeaders } from '../helpers'

export async function home(slug) {
  const result = await fetch(config.API_URL + `/api/library/${slug}/home`)
  return await result.json()
}

export async function search(slug, term) {
  const result = await fetch(config.API_URL + `/api/library/${slug}/search/${term}`)
  return await result.json()
}

export async function getShared(slug, id) {
  let url = `/api/library/${slug}/share/${id}`
  const result = await fetch(config.API_URL + url)
  return await result.json()
}

export async function createShareLink(slug, id, type, body) {
  const res = await fetch(config.API_URL + `/api/library/${slug}/share/${type}/${id}`, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return await res.json()
}

export async function deleteShareLink(slug, id) {
  const res = await fetch(config.API_URL + `/api/library/${slug}/share/${id}`, {
    method: 'DELETE',
    headers: await getJsonHeaders(),
  })

  return await res.json()
}
