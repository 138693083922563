const config = {
  API_URL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '',
  MY_LIBRARY_MAX_FILE_SIZE: 12582912, // 12mb // 12 * 1024 * 1024
  ALGOLIA_APP_ID: process.env.REACT_APP_ALGOLIA_APP_ID
    ? process.env.REACT_APP_ALGOLIA_APP_ID
    : '',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN ? process.env.REACT_APP_SENTRY_DSN : '',
  LOG_ROCKET: process.env.REACT_APP_LOG_ROCKET ? process.env.REACT_APP_LOG_ROCKET : '',
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
    ? process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
    : '',
}

export default config
