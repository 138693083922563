import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { create, get } from 'api/proposals'
import Page from 'components/pdf/Page'
import { LoadingContext } from 'contexts/LoadingContext'
import PDFJS from 'pdfjs-dist'
import React, { useContext, useEffect, useState } from 'react'
import CoveringLetter from './CoveringLetter'
import CoveringLetterQuestions from './CoveringLetterQuestions'

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(6),
  },
  pdfUploadButton: {
    marginBottom: theme.spacing(2),
  },
  pages: {
    display: 'flex',
    flexDirection: 'wrap',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
  page: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  selectedFile: {
    marginTop: theme.spacing(2),
    fontSize: '0.75rem',
    textAlign: 'center',
  },
}))

function Form() {
  const { loading, setLoading } = useContext(LoadingContext)
  const [hideContent, setHideContent] = useState(true)
  const [proposal, setProposal] = useState()
  const [pdf, setPdf] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [errors, setErrors] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const proposal = await get()
      if (proposal.data) {
        const pdf = await PDFJS.getDocument(proposal.data.pdf_url).promise
        setPdf(pdf)
        setProposal(proposal.data)
      }
      setLoading(false)
      setHideContent(false)
    }

    fetchData()
  }, [setLoading])

  const setSnackbar = (severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleChangePdf = event => {
    setLoading(true)
    setErrors([])

    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onloadend = async () => {
      const result = await create(reader.result)

      if (result.success) {
        setProposal(result.data)
        const pdf = await PDFJS.getDocument(result.data.pdf_url).promise
        setPdf(pdf)
        setSnackbarSeverity('success')
        setSnackbarMessage('Successfully uploaded proposal document')
        setSnackbarOpen(true)
        setLoading(false)
      } else {
        setErrors(result.errors)
        setLoading(false)
      }
    }
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Box display='flex' flexDirection='row'>
        <Typography variant='h4' gutterBottom>
          Proposal
        </Typography>
      </Box>

      {pdf && (
        <div className={classes.section}>
          <CoveringLetter
            defaultEditorState={proposal.covering_letter.content}
            readOnly={loading}
            setSnackbar={setSnackbar}
          />
        </div>
      )}

      {pdf && (
        <div className={classes.section}>
          <CoveringLetterQuestions
            questions={proposal.covering_letter.questions}
            setSnackbar={setSnackbar}
          />
        </div>
      )}

      {pdf && (
        <Typography variant='h4' gutterBottom>
          PDF Document
        </Typography>
      )}
      <div className={classes.pdfUploadButton}>
        <input
          accept='application/pdf'
          style={{ display: 'none' }}
          id='proposal'
          name='proposal'
          disabled={loading}
          type='file'
          onChange={handleChangePdf}
        />
        <label htmlFor='proposal'>
          <Button
            size='small'
            disabled={loading}
            variant='contained'
            color='primary'
            component='span'>
            {pdf ? 'Replace Existing PDF' : 'Upload a Proposal PDF to get Started'}
          </Button>
        </label>
      </div>

      {errors.length > 0 && (
        <MuiAlert severity='error'>
          {errors.map((error, index) => {
            return <div key={index}>{error[1]}</div>
          })}
        </MuiAlert>
      )}

      {pdf && (
        <div className={classes.pages}>
          {Array.from(Array(pdf.numPages)).map((_, index) => (
            <Page
              className={classes.page}
              key={index}
              pdf={pdf}
              pageNum={index + 1}
              scale={0.5}
            />
          ))}
        </div>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}

export default Form
