import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { create, get, update } from 'api/training'
import { BreadcrumbContext } from 'contexts/BreadcrumbContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function Form(props) {
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const [hideContent, setHideContent] = useState(true)
  const [values, setValues] = useState({
    title: { value: '', error: '' },
    date: { value: '', error: '' },
    url: { value: '', error: '' },
  })
  const [errors, setErrors] = useState([])
  const classes = useStyles()
  const { setBreadcrumbs } = useContext(BreadcrumbContext)

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const item = await get(id)
      const dataValues = {
        title: { value: item.data.title },
        date: { value: item.data.full_date },
        url: { value: item.data.url },
      }
      setValues(dataValues)
      setBreadcrumbs([
        { label: 'Home', path: '/' },
        { label: 'Training', path: '/training' },
        { label: item.data.title },
      ])
      setLoading(false)
      setHideContent(false)
    }

    if (id !== undefined) {
      fetchData()
    } else {
      setLoading(false)
      setHideContent(false)
    }
  }, [id, setValues, setLoading, setBreadcrumbs])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const body = {
      title: values.title.value,
      date: values.date.value,
      url: values.url.value,
    }
    const result = id ? await update(id, body) : await create(body)

    if (result.success) {
      setLoading(false)
      props.history.push({
        pathname: '/training',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        {id ? 'Update Training Date' : 'Create Training Date'}
      </Typography>
      <Grid item md={5}>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Paper className={classes.paper}>
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='title'
              label='Title'
              name='title'
              defaultValue={values.title.value}
              autoFocus
              inputProps={{
                maxLength: 100,
              }}
              onChange={handleChange.bind(this)}
            />

            <TextField
              type='date'
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='date'
              label='Date'
              name='date'
              defaultValue={values.date.value}
              onChange={handleChange.bind(this)}
            />

            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='url'
              label='Booking URL'
              name='url'
              defaultValue={values.url.value}
              inputProps={{
                maxLength: 100,
              }}
              onChange={handleChange.bind(this)}
            />
          </Paper>

          {errors.length > 0 && (
            <MuiAlert severity='error'>
              {errors.map(error => {
                return <div>{error[1]}</div>
              })}
            </MuiAlert>
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.submit}>
            {id ? 'Update' : 'Create'}
          </Button>
        </form>
      </Grid>
    </div>
  )
}

export default Form
