import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Notifications, Phone } from '@material-ui/icons'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Help from '@material-ui/icons/HelpOutline'
import MenuIcon from '@material-ui/icons/Menu'
import MoreIcon from '@material-ui/icons/MoreVert'
import SubjectIcon from '@material-ui/icons/Subject'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Alert } from '@material-ui/lab'
import Routes from 'Routes'
import { logout } from 'api/auth'
import ContactMenu from 'components/ContactMenu'
import GlobalSearch from 'components/GlobalSearch'
import UserAvatar from 'components/UserAvatar'
import config from 'config'
import AwaitingVerification from 'containers/awaiting-verification/AwaitingVerification'
import Terms from 'containers/terms/Terms'
import { AuthContext } from 'contexts/AuthContext'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { HelpContext } from 'contexts/HelpContext'
import { LoadingContext } from 'contexts/LoadingContext'
import { NotificationContext } from 'contexts/NotificationContext'
import { ViewContext } from 'contexts/ViewContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MenuItems from './menu-items/MenuItems'

const drawerWidth = 260
const footerHeight = 120

const useStyles = makeStyles(theme => ({
  colorDarkBlue: {
    color: theme.palette.darkBlue,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 1,
    },
    background: 'transparent',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    minHeight: '48px',
  },
  mainTopbarContent: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    gap: theme.spacing(2),
    paddingLeft: '248px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: '1',
  },
  logo: {
    padding: '0 1rem',
  },
  drawerPaper: {
    borderRadius: '0 2em 2em 0',
    width: drawerWidth,
    background: 'rgba(255,255,255,1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .MuiListItem-button': {
      color: theme.palette.textLight,
    },
    '& MuiListItemIcon-root': {
      color: theme.palette.textLight,
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '1em',
      paddingRight: '1em',
    },
    [theme.breakpoints.up('md')]: {
      background: 'rgba(255,255,255,0.5)',
    },
  },
  drawerPaperDist: {
    borderRadius: '0 2em 2em 0',
    background: 'rgba(255,255,255,1)',
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& *': {
      color: theme.palette.textLight,
    },
    [theme.breakpoints.up('md')]: {
      background: 'rgba(255,255,255,0.5)',
    },
  },
  drawerContainer: {
    overflow: 'auto',
  },
  menu: {
    margin: '2.2rem 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 3, 0, 3),
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
  },
  innerContent: {
    minHeight: '100%',
    marginBottom: 0 - footerHeight,
    flexGrow: 1,
  },
  poweredBy: {
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '14px',
  },
  push: {
    height: footerHeight,
  },
  footer: {
    height: footerHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerLogo: {
    marginLeft: '12px',
    marginRight: '12px',
    paddingTop: '12px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '8px',
    },
  },
  logout: {
    borderRadius: '0',
    backgroundColor: theme.palette.darkBlue,
    color: theme.palette.white,
    marginBottom: theme.spacing(4),
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    zIndex: 99999,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  topMenuSub: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  notifications: {
    maxWidth: '100vw',
    [theme.breakpoints.up('md')]: {
      maxWidth: '50vw',
    },
  },
  notificationText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '2em',
  },
  search: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

function Layout(props) {
  const { setAuthenticated, setUser, user } = useContext(AuthContext)
  const { notifications, unreadCount } = useContext(NotificationContext)
  const { setLoading } = useContext(LoadingContext)
  const { hasFeature } = useContext(FeatureToggleContext)
  const { viewMenuItems, selectedView, setSelectedView } = useContext(ViewContext)
  const { showHelp, setHelpOpen } = useContext(HelpContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [menuType, setMenuType] = useState(null)
  const { container } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  useEffect(() => {
    if (user.live_chat_url !== '') {
      const script = document.createElement('script')

      script.src = user.live_chat_url
      script.async = true

      document.body.appendChild(script)
    }
  }, [user.live_chat_url])

  const menuItems = () => {
    if (!user.verified || (hasFeature('tandcs') && !user.tandcs)) {
      return null
    }

    return <MenuItems />
  }

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = event => {
    setMenuType('profile')
    setAnchorEl(event.currentTarget)
  }

  const handleContactOpen = event => {
    setMenuType('contact')
    setAnchorEl(event.currentTarget)
  }

  const handleViewMenuOpen = event => {
    setMenuType('view')
    setAnchorEl(event.currentTarget)
  }

  const handleNotificationMenuOpen = event => {
    setMenuType('notification')
    setAnchorEl(event.currentTarget)
  }

  const handleHelp = event => {
    setHelpOpen(true)
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleLogout = async () => {
    setLoading(true)
    if (await logout()) {
      setAuthenticated(false)
      setUser(null)
    }
    setLoading(false)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerClose = () => {
    setMobileOpen(false)
  }

  const handleSelectedView = item => {
    setAnchorEl(null)
    handleMobileMenuClose()
    setSelectedView(item)
  }

  const drawer = (
    <div className={classes.menu}>
      <img className={classes.logo} alt='Logo' src={config.API_URL + '/api/logo'} />
      <List onClick={handleDrawerClose}>{menuItems()}</List>
    </div>
  )

  const renderContent = () => {
    if (!user.verified) {
      return <AwaitingVerification />
    } else if (hasFeature('tandcs') && !user.tandcs) {
      return <Terms />
    } else {
      return <Routes />
    }
  }

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position='fixed' elevation={0} className={classes.appBar}>
          <Toolbar>
            <IconButton
              color='primary'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              className={`${classes.menuButton} ${classes.colorDarkBlue}`}>
              <MenuIcon />
            </IconButton>

            <div className={classes.mainTopbarContent}>
              {user.hasOwnProperty('leave_impersonation') && (
                <Alert severity='info'>
                  You are logged in as another user,{' '}
                  <a href={user.leave_impersonation}>
                    click here to return to your account.
                  </a>
                </Alert>
              )}

              <Box flexGrow='1' className={classes.search}>
                <GlobalSearch />
              </Box>
            </div>

            <div className={classes.sectionDesktop}>
              {showHelp && (
                <Button edge='end'>
                  <Help onClick={handleHelp} />
                </Button>
              )}
              {viewMenuItems.length > 0 && (
                <Button edge='end' onClick={handleViewMenuOpen} endIcon={<ExpandMore />}>
                  View
                </Button>
              )}

              <Button edge='end' onClick={handleProfileMenuOpen}>
                <UserAvatar user={user}></UserAvatar>
              </Button>
              <IconButton aria-label='notifications' onClick={handleNotificationMenuOpen}>
                <Badge badgeContent={unreadCount} color='secondary'>
                  <Notifications />
                </Badge>
              </IconButton>
              {user.type !== 'distributor' &&
                (hasFeature('ipecsone') || hasFeature('ms_bookings')) && (
                  <IconButton aria-label='call' onClick={handleContactOpen}>
                    <Phone />
                  </IconButton>
                )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                onClick={handleMobileMenuOpen}
                className={classes.colorDarkBlue}>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}>
          <MenuItem onClick={handleProfileMenuOpen}>
            {user.first_name} {user.last_name}
            <ExpandMore />
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}>
          <MenuItem onClick={handleContactOpen}>
            Contact
            <ExpandMore />
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={anchorEl}
          style={{ marginTop: '34px' }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          keepMounted
          open={isMenuOpen && menuType === 'profile'}
          onClose={handleMenuClose}>
          {user.type !== 'distributor' && (
            <MenuItem
              className={classes.topMenuSub}
              component={Link}
              to='/getting-started'>
              Getting Started
            </MenuItem>
          )}

          <MenuItem className={classes.topMenuSub} component={Link} to='/my-account'>
            My Account
          </MenuItem>
        </Menu>

        <ContactMenu
          anchorEl={anchorEl}
          isMenuOpen={isMenuOpen && menuType === 'contact'}
          handleMenuClose={handleMenuClose}
        />

        <Menu
          anchorEl={anchorEl}
          style={{ marginTop: '34px' }}
          className={classes.notifications}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          keepMounted
          open={isMenuOpen && menuType === 'notification'}
          onClose={handleMenuClose}>
          {notifications
            .filter(item => !item.read_at)
            .map((item, index) => (
              <MenuItem
                key={index}
                onClick={handleMenuClose}
                component={Link}
                to={'/notifications/' + item.id}>
                <ListItemAvatar>
                  {item.image_thumbnail_path ? (
                    <Avatar src={'/api/' + item.image_thumbnail_path} />
                  ) : (
                    <Avatar>
                      <SubjectIcon></SubjectIcon>
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText primary={item.title} secondary={item.sent_at.readable}>
                  {item.title}
                </ListItemText>
              </MenuItem>
            ))}
          <MenuItem component={Link} to='/notifications'>
            View all notifications...
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={anchorEl}
          style={{ marginTop: '34px' }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          keepMounted
          open={isMenuOpen && menuType === 'view'}
          onClose={handleMenuClose}>
          {viewMenuItems.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleSelectedView(item)
              }}
              className={classes.topMenuSub}>
              {item}{' '}
              {item === selectedView && <VisibilityIcon style={{ marginLeft: '12px' }} />}
            </MenuItem>
          ))}
        </Menu>

        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation='css'>
            <Drawer
              container={container}
              variant='temporary'
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper:
                  user.type === 'distributor'
                    ? classes.drawerPaperDist
                    : classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}>
              {drawer}
              <div className={classes.poweredBy}>
                <Button
                  className={classes.logout}
                  fullWidth
                  color='primary'
                  variant='contained'
                  edge='end'
                  onClick={handleLogout}>
                  Log out
                </Button>
              </div>
            </Drawer>
          </Hidden>
          <Hidden smDown implementation='css'>
            <Drawer
              classes={{
                paper:
                  user.type === 'distributor'
                    ? classes.drawerPaperDist
                    : classes.drawerPaper,
              }}
              variant='permanent'
              open>
              {drawer}
              <div className={classes.poweredBy}>
                <Button
                  className={classes.logout}
                  fullWidth
                  color='primary'
                  variant='contained'
                  edge='end'
                  onClick={handleLogout}>
                  Log out
                </Button>
              </div>
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className={classes.innerContent}>
            {renderContent()}
            <div className={classes.push}></div>
          </div>
          <div className={classes.footer}>
            {hasFeature('reseller_logos') && (
              <img className={classes.footerLogo} alt='Logo' src='/Logo50.png' />
            )}
          </div>
        </main>
      </div>
    </>
  )
}

export default Layout
