import { sdk } from '@ipecsone/web-js-sdk'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { getToken } from 'api/ipecsone'
import { AuthContext } from 'contexts/AuthContext'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { HelpContext } from 'contexts/HelpContext'
import { useIpecsCall } from 'contexts/IpecsCallContext'
import { useContext, useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  topMenuSub: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}))

function ContactMenu({ anchorEl, isMenuOpen, handleMenuClose }) {
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const { hasFeature } = useContext(FeatureToggleContext)
  const { setHelpOpen, setHelpType } = useContext(HelpContext)
  const { handleMakeCall } = useIpecsCall()
  const { auth, call } = sdk
  const [authenticated, setAuthenticated] = useState(false)
  const [callDestination, setCallDestination] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setHelpType('sales-hub')
  })

  const handleOpenHelp = () => {
    setHelpOpen(true)
  }

  const handleCall = async destination => {
    setLoading(true)
    let _callDestination

    try {
      if (!authenticated) {
        const token = await getToken()
        if (!token) {
          throw new Error('IPECS error')
        }

        await auth.login({
          deviceId: token.data.device_id,
          deviceDisplayName: token.data.display_name,
          isPhoneControl: false,
          removeDeviceIds: [],
          serverUrl: token.data.url,
          authToken: token.data.token,
        })
        _callDestination = token.data.call_dest[destination]
        setCallDestination(_callDestination)
        setAuthenticated(true)
      } else {
        _callDestination = callDestination
      }

      await call.getMediaDevicesPermission({ audio: true, video: false })
      setLoading(false)
      handleMakeCall(_callDestination, 'audio')
    } catch (error) {
      setLoading(false)
      alert("Sorry about this, we're unable to find an available agent")
      setAuthenticated(false)
    }
  }

  return (
    <Menu
      anchorEl={anchorEl}
      style={{ marginTop: '34px' }}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}>
      {hasFeature('ipecsone') && (
        <MenuItem
          disabled={loading}
          className={classes.topMenuSub}
          onClick={() => handleCall('oanp')}>
          Call Pragma O&P
        </MenuItem>
      )}
      {hasFeature('ipecsone') && (
        <MenuItem
          disabled={loading}
          className={classes.topMenuSub}
          onClick={() => handleCall('tech')}>
          Call Pragma Tech
        </MenuItem>
      )}
      {hasFeature('ipecsone') && (
        <MenuItem
          disabled={loading}
          className={classes.topMenuSub}
          onClick={() => handleCall('sales')}>
          Call Pragma Sales
        </MenuItem>
      )}
      {hasFeature('ipecsone') && (
        <MenuItem
          disabled={loading}
          className={classes.topMenuSub}
          onClick={() => handleCall('finance')}>
          Call Pragma Finance
        </MenuItem>
      )}
      {hasFeature('ms_bookings') && (
        <MenuItem
          className={classes.topMenuSub}
          component='a'
          target='_blank'
          href={user.ms_bookings_url}>
          Book a Meeting
        </MenuItem>
      )}
      <MenuItem className={classes.topMenuSub} onClick={handleOpenHelp}>
        Help
      </MenuItem>
    </Menu>
  )
}

export default ContactMenu
