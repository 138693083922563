import { makeStyles } from '@material-ui/core/styles'
import { hardware as apiSave } from 'api/quote-builder'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext, useEffect, useState } from 'react'
import Accordion from './components/Accordion'
import DropDownItem from './components/DropDownItem'
import HardwareItem from './components/HardwareItem'
import StandardItem from './components/StandardItem'

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.white,
    padding: theme.spacing(2),
    borderRadius: '2em',
  },
  items: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
    marginTop: theme.spacing(3),
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(auto-fit, 240px)',
    },
  },
}))

const getCrmSelectedItems = quantity => {
  return quantity ? [{ id: 'crm_integration', quantity: quantity }] : []
}

const filterAndGroup = function (items, filterBy, groupBy) {
  const filtered = items.filter(
    item => item.system_type === 'all' || item.system_type === filterBy
  )
  return filtered.reduce(function (rv, x) {
    ;(rv[x[groupBy]] = rv[x[groupBy]] || []).push(x)
    return rv
  }, {})
}

function HardwareStep() {
  const classes = useStyles()
  const [groupedApplications, setGroupedApplications] = useState({})
  const { state, dispatch } = useContext(QuoteContext)
  const systemType = state.services.system_type.value
  const [expanded, setExpanded] = useState('hardware-favourites')

  const handleChangeAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    const grouped = filterAndGroup(
      Object.values(state.options.uc_applications),
      systemType,
      'category'
    )
    setGroupedApplications(grouped)
  }, [systemType, state.options.uc_applications, setGroupedApplications])

  const handleChangeHardware = (category, id, quantity, paymentType) => {
    const item = {
      id: id,
      quantity: quantity,
      payment_type: paymentType,
    }

    if (category in state.hardware && state.hardware[category].length) {
      const itemIndex = state.hardware[category].findIndex(obj => obj.id === id)
      if (quantity > 0) {
        if (itemIndex === -1) {
          state.hardware[category].push(item)
        } else {
          state.hardware[category][itemIndex].quantity = quantity
          state.hardware[category][itemIndex].payment_type = paymentType
        }
      } else {
        const data = state.hardware[category]
        data.splice(itemIndex, 1)
        state.hardware[category] = data
      }
    } else {
      if (quantity > 0) {
        state.hardware[category] = [item]
      }
    }

    dispatch({ type: 'SET_HARDWARE', payload: Object.assign({}, state.hardware) })
  }

  const handleChangeUcApplications = (id, quantity) => {
    handleChangeArrayItem(id, quantity, state.ucApplications, 'SET_UC_APPLICATIONS')
  }

  const handleChangeCallRecording = (id, quantity) => {
    handleChangeArrayItem(id, quantity, state.callRecording, 'SET_CALL_RECORDING')
  }

  const handleChangeArrayItem = (id, quantity, items, action) => {
    const item = {
      id: id,
      quantity: quantity,
    }

    const itemIndex = items.findIndex(obj => obj.id === id)
    if (quantity > 0) {
      itemIndex === -1 ? items.push(item) : (items[itemIndex].quantity = quantity)

      dispatch({ type: action, payload: [...items] })
    } else {
      const data = items
      data.splice(itemIndex, 1)
      dispatch({ type: action, payload: [...data] })
    }
  }

  const handleChangeServices = event => {
    const { name, value } = event.target
    const newData = { ...state.services, [name]: { value: value } }
    dispatch({ type: 'SET_SERVICES', payload: newData })
  }

  const handleChangeCrmIntegration = (id, quantity) => {
    const newData = { ...state.services, [id]: { value: quantity } }
    dispatch({ type: 'SET_SERVICES', payload: newData })
  }

  return (
    <div className={classes.section}>
      <Accordion
        key={`favourites`}
        title='Favourites'
        id={`favourites`}
        expanded={expanded === `hardware-favourites`}
        onChange={handleChangeAccordion(`hardware-favourites`)}>
        <div className={classes.items}>
          {Object.keys(state.options.hardware).map((category, index) => {
            return Object.keys(state.options.hardware[category].items)
              .filter(
                item => state.options.hardware[category]['items'][item]['is_favourite']
              )
              .map((item, index) => {
                return (
                  <HardwareItem
                    key={`favourite-${item}`}
                    options={state.options.hardware}
                    hardware={state.hardware}
                    category={category}
                    item={item}
                    onChangeHardware={handleChangeHardware}
                  />
                )
              })
          })}

          {Object.keys(groupedApplications).map((category, index) => {
            return Object.values(groupedApplications[category])
              .filter(item => {
                return item.is_favourite
              })
              .map((item, index) => {
                return (
                  <StandardItem
                    key={`favourite-${item.id}`}
                    options={state.options.uc_applications}
                    selectedItems={state.applications}
                    id={item.id}
                    onChange={handleChangeUcApplications}
                  />
                )
              })
          })}
          {Object.values(state.options.call_recording)
            .filter(item => {
              return item.is_favourite
            })
            .map((item, index) => {
              return (
                <StandardItem
                  key={`favourite-${item.id}`}
                  options={state.options.call_recording}
                  selectedItems={state.callRecording}
                  id={item.id}
                  onChange={handleChangeCallRecording}
                />
              )
            })}
          {systemType === 'onprem' &&
            (
              Object.keys(state.options.uc_licenses).length > 0 && (
                <DropDownItem
                  options={state.options.uc_licenses}
                  value={state.services.uc_license.value}
                  fieldName='uc_license'
                  label='UC Licence'
                  onChange={handleChangeServices}
                />
              )
            )(
              state.services.uc_license.value && (
                <StandardItem
                  options={{ crm_integration: { name: 'UCP CRM Integration' } }}
                  selectedItems={getCrmSelectedItems(
                    state.services.crm_integration.value
                  )}
                  id='crm_integration'
                  onChange={handleChangeCrmIntegration}
                />
              )
            )}
        </div>
      </Accordion>

      {Object.keys(state.options.hardware).map((category, index) => {
        return (
          <Accordion
            key={`hardware-${index}`}
            title={state.options.hardware[category].name}
            id={`hardware-${index}`}
            expanded={expanded === `hardware-${index}`}
            onChange={handleChangeAccordion(`hardware-${index}`)}>
            <div className={classes.items}>
              {Object.keys(state.options.hardware[category].items).map((item, index) => {
                return (
                  <HardwareItem
                    key={index}
                    options={state.options.hardware}
                    hardware={state.hardware}
                    category={category}
                    item={item}
                    onChangeHardware={handleChangeHardware}
                  />
                )
              })}
            </div>
          </Accordion>
        )
      })}

      {Object.keys(groupedApplications).map((category, index) => {
        return (
          <Accordion
            title={category}
            id={`apps-${index}`}
            expanded={expanded === `apps-${index}`}
            onChange={handleChangeAccordion(`apps-${index}`)}>
            <div className={classes.items}>
              {Object.values(groupedApplications[category]).map((item, index) => {
                return (
                  <StandardItem
                    key={index}
                    options={state.options.uc_applications}
                    selectedItems={state.applications}
                    id={item.id}
                    onChange={handleChangeUcApplications}
                  />
                )
              })}
            </div>
          </Accordion>
        )
      })}

      <Accordion
        title='Call Recording'
        id='callrecording'
        expanded={expanded === 'callrecording'}
        onChange={handleChangeAccordion('callrecording')}>
        <div className={classes.items}>
          {Object.values(state.options.call_recording).map((item, index) => {
            return (
              <StandardItem
                key={index}
                options={state.options.call_recording}
                selectedItems={state.callRecording}
                id={item.id}
                onChange={handleChangeCallRecording}
              />
            )
          })}
        </div>
      </Accordion>

      {systemType === 'onprem' && (
        <Accordion
          title='UCP Options'
          id='ucpoptions'
          expanded={expanded === 'ucpoptions'}
          onChange={handleChangeAccordion('ucpoptions')}>
          <div className={classes.items}>
            {Object.keys(state.options.uc_licenses).length > 0 && (
              <DropDownItem
                options={state.options.uc_licenses}
                value={state.services.uc_license.value}
                fieldName='uc_license'
                label='UC Licence'
                onChange={handleChangeServices}
              />
            )}

            {state.services.uc_license.value && (
              <StandardItem
                options={{ crm_integration: { name: 'UCP CRM Integration' } }}
                selectedItems={getCrmSelectedItems(state.services.crm_integration.value)}
                id='crm_integration'
                onChange={handleChangeCrmIntegration}
              />
            )}
          </div>
        </Accordion>
      )}
    </div>
  )
}

const save = async (id, hardware, applications, callRecording, services) => {
  return await apiSave(id, {
    hardware: Object.keys(hardware)
      .map(key => {
        return hardware[key]
      })
      .flat(),
    uc_applications: applications,
    call_recording: callRecording,
    uc_license: services.uc_license.value,
    crm_integration: services.uc_license.value ? services.crm_integration.value : '',
  })
}

export { save }
export default HardwareStep
