import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core'
import TableIcon from 'components/TableIcon'
import { SimpleTreeItemWrapper, SortableTree } from 'dnd-kit-sortable-tree'
import React from 'react'

const useStyles = makeStyles(theme => ({
  treeItem: {
    border: 'none',
    '& > .dnd-sortable-tree_simple_tree-item': {
      background: theme.palette.white,
      borderRadius: '2em',
      padding: 0,
    },
    '& > .dnd-sortable-tree_folder_line': {
      background: 'none',
    },
    '& > .dnd-sortable-tree_folder_line-to_self': {
      background: 'none',
    },
    '& > .dnd-sortable-tree_folder_line-to_self-end': {
      background: 'none',
    },
  },
  container: {
    maxWidth: '50%',
  },
}))

const TreeItem = React.forwardRef((props, ref) => {
  const classes = useStyles()

  return props.item.delete ? (
    <></>
  ) : (
    <SimpleTreeItemWrapper
      className={classes.treeItem}
      manualDrag={true}
      {...props}
      ref={ref}>
      <Box display='flex' alignItems='center' width='100%' justifyContent='spaceBetween'>
        <div>{props.item.label}</div>
        <Box flexGrow='1'></Box>
        {
          <Tooltip
            title={
              props.item.display_on_homepage ? 'Remove from homepage' : 'Add to homepage'
            }>
            <IconButton onClick={() => props.onClickAddToHome(props.item)}>
              {props.item.display_on_homepage ? (
                <TableIcon icon='home' />
              ) : (
                <TableIcon icon='homeOutline' />
              )}
            </IconButton>
          </Tooltip>
        }
        <Tooltip title='Edit'>
          <IconButton
            onClick={() => props.onClickEdit(props.item)}
            disabled={props.item.type === 'INTERNAL_LINK'}>
            <TableIcon icon='edit' color='yellow' />
          </IconButton>
        </Tooltip>

        <Tooltip title='Delete'>
          <IconButton
            onClick={() => props.onClickDelete(props.item)}
            disabled={
              props.item.type === 'INTERNAL_LINK' || props.item.children?.length > 0
            }>
            <TableIcon icon='delete' />
          </IconButton>
        </Tooltip>
      </Box>
    </SimpleTreeItemWrapper>
  )
})

export function NavigationTree({
  items,
  onChange,
  onClickEdit,
  onClickAddToHome,
  onClickDelete,
}) {
  const transFormItem = item => {
    return {
      id: item.id,
      label: item.label,
      children: items
        .filter(i => i.parent?.id === item.id)
        .map(i => transFormItem(i) ?? null),
      type: item.type,
      type_value: item.type_value,
      display_on_homepage: item.display_on_homepage,
      canHaveChildren: item.type === 'DROPDOWN',
      permissions: item.permissions,
      parent: item.parent ? { id: item.parent.id, label: item.parent.id } : null,
      create: item.create ? true : false,
      update: item.update ? true : false,
      delete: item.delete ? true : false,
    }
  }

  const treeItems = items.filter(i => i.parent === null).map(i => transFormItem(i))

  return (
    <Box maxWidth={'50%'}>
      <SortableTree
        items={treeItems}
        onClickEdit={onClickEdit}
        onClickAddToHome={onClickAddToHome}
        onClickDelete={onClickDelete}
        onItemsChanged={onChange}
        TreeItemComponent={TreeItem}
      />
    </Box>
  )
}
