import { IconButton, Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MuiAlert from '@material-ui/lab/Alert'
import { deleteUser, list } from 'api/users'
import TableIcon from 'components/TableIcon'
import TableSearchBox from 'components/TableSearchBox'
import TableToolbar from 'components/TableToolbar'
import UserAvatar from 'components/UserAvatar'
import { AuthContext } from 'contexts/AuthContext'
import { BreadcrumbContext } from 'contexts/BreadcrumbContext'
import { LoadingContext } from 'contexts/LoadingContext'
import debounce from 'lodash.debounce'
import { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  pagination: {
    marginBottom: '60px',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  fabShift: {
    bottom: 90,
  },
})

const getUpdateLink = (reseller, id) => {
  if (reseller !== undefined) {
    return `/resellers/${reseller}/users/update/${id}`
  }

  return `/users/update/${id}`
}

const getCreateLink = reseller => {
  if (reseller !== undefined) {
    return `/resellers/${reseller}/users/create`
  }

  return `/users/create`
}

function List(props) {
  const { reseller } = useParams()
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const { user } = useContext(AuthContext)
  const [users, setUsers] = useState({})
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [hideContent, setHideContent] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [filters, setFilters] = useState({
    search: '',
  })
  const { setBreadcrumbs } = useContext(BreadcrumbContext)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setUsers(await list(reseller))
      setLoading(false)
      setHideContent(false)
    }

    if (props.location.state) {
      setSnackbarSeverity(props.location.state.severity)
      setSnackbarMessage(props.location.state.message)
      setSnackbarOpen(true)
      props.history.replace()
    }

    fetchData()
  }, [setLoading, props.location, props.history, reseller])

  useEffect(() => {
    if (users.meta && reseller) {
      setBreadcrumbs([
        { label: 'Home', path: '/' },
        { label: 'Partners', path: '/resellers' },
        { label: users.meta.reseller_name, path: `/resellers/update/${reseller}` },
        { label: 'Users' },
      ])
    }
  }, [reseller, users, setBreadcrumbs])

  const handleChangePage = async (event, newPage) => {
    setLoading(true)
    setUsers(await list(reseller, parseInt(users.meta.per_page), parseInt(newPage + 1)))
    setLoading(false)
  }

  const handleChangeRowsPerPage = async event => {
    setLoading(true)
    setUsers(await list(reseller, parseInt(event.target.value, 10)))
    setLoading(false)
  }

  const handleOpenDeleteDialog = id => {
    setDeleteId(id)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async id => {
    setLoading(true)
    const success = await deleteUser(id, reseller)

    if (success) {
      setUsers(await list(reseller, parseInt(users.meta.per_page)))
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully deleted user')
    } else {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to delete user')
    }

    setDeleteOpen(false)
    setLoading(false)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleChangeSearchFilter = async event => {
    const newFilters = { ...filters, search: event.target.value }
    setFilters(newFilters)
    debouncedSearch(reseller, parseInt(users.meta.per_page), newFilters)
  }

  const debouncedSearch = useRef(
    debounce(async (reseller, perPage, newFilters) => {
      setLoading(true)
      setUsers(await list(reseller, perPage, 1, newFilters))
      setLoading(false)
    }, 1000)
  ).current

  if (hideContent || !users.data) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        {reseller
          ? `${users.meta.reseller_name} Users`
          : user.type === 'distributor'
            ? 'Administrators'
            : 'Users'}
      </Typography>

      {users.data.filter(x => !x.verified).length > 0 && (
        <MuiAlert severity='info'>
          You have unverified users. These are users who have created accounts using the
          Sign-up page so do not yet have full access. To verify the user click the edit
          button to the right, toggle on the required permissions and save the user. If
          the user should not have access you can use the delete button to remove their
          account.
        </MuiAlert>
      )}

      <TableContainer component={Paper}>
        <TableToolbar>
          <TableSearchBox label='Search users' handleChange={handleChangeSearchFilter} />
        </TableToolbar>

        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              {(reseller || user.type === 'reseller') && user.role === 'admin' && (
                <>
                  <TableCell>Role</TableCell>
                  <TableCell></TableCell>
                </>
              )}
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.data.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  <UserAvatar user={row}></UserAvatar>
                </TableCell>
                <TableCell scope='row'>
                  {row.first_name} {row.last_name}
                </TableCell>
                <TableCell scope='row'>{row.email}</TableCell>
                {(reseller || user.type === 'reseller') && user.role === 'admin' && (
                  <>
                    <TableCell>{row.role.replace('_', ' ').toUpperCase()}</TableCell>
                    <TableCell>
                      {!row.verified && (
                        <Chip label='UNVERIFIED' variant='outline' color='primary' />
                      )}
                    </TableCell>
                  </>
                )}
                <TableCell align='right'>
                  {row.hasOwnProperty('impersonate_link') && (
                    <IconButton
                      aria-label='Login as'
                      color='default'
                      onClick={() => (window.location.href = row.impersonate_link)}>
                      <TableIcon icon='login' color='green' />
                    </IconButton>
                  )}
                  <Tooltip title='Edit' placement='top'>
                    <IconButton
                      aria-label='edit'
                      color='default'
                      component={NavLink}
                      to={getUpdateLink(reseller, row.id)}>
                      <TableIcon icon='edit' color='yellow' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Delete' placement='top'>
                    <IconButton
                      aria-label='delete'
                      onClick={() => handleOpenDeleteDialog(row.id)}>
                      <TableIcon icon='delete' />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 15, 25]}
        component='div'
        count={users.meta.total}
        rowsPerPage={parseInt(users.meta.per_page)}
        page={users.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='alert-dialog-title'>{'User Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleDelete(deleteId)}
            color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Fab
        className={`${classes.fab} ${user.live_chat_url !== '' && classes.fabShift}`}
        color='secondary'
        aria-label='add'
        component={NavLink}
        to={getCreateLink(reseller)}>
        <AddIcon />
      </Fab>
    </div>
  )
}

export default List
