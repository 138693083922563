import config from 'config'
import { getJsonHeaders } from '../helpers'

export async function list(
  limit = 15,
  page = 1,
  library = 'standard',
  resellerId = undefined
) {
  let url = `/api/library/${library}/categories?per_page=${limit}&page=${page}`

  if (resellerId) {
    url += `&rid=${resellerId}`
  }

  const result = await fetch(config.API_URL + url)
  return await result.json()
}

export async function posts(id = '', limit = 15, page = 1, library = 'standard') {
  let url = `/api/library/${library}/categories/${id}/posts?per_page=${limit}&page=${page}`
  const result = await fetch(config.API_URL + url)
  return await result.json()
}

export async function update(id, body, library = 'standard') {
  try {
    const res = await fetch(config.API_URL + `/api/library/${library}/categories/${id}`, {
      method: 'PUT',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated category',
        category: decoded,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update category',
      errors: [['', 'Failed to update category']],
    }
  }
}

export async function sortPosts(id, posts_sort_order, library) {
  try {
    const res = await fetch(
      config.API_URL + `/api/library/${library}/categories/${id}/posts/sort`,
      {
        method: 'PUT',
        headers: await getJsonHeaders(),
        body: JSON.stringify({ posts_sort_order }),
      }
    )

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully reordered category posts',
        category: decoded,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to sort category posts',
      errors: [['', 'Failed to sort category posts']],
    }
  }
}

export async function create(body, library = 'standard') {
  try {
    const res = await fetch(config.API_URL + `/api/library/${library}/categories`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 201) {
      return {
        success: true,
        message: 'Successfully created category',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to create category',
      errors: [['', 'Failed to create category']],
    }
  }
}

export async function deleteCategory(id, library = 'library') {
  try {
    let url = `/api/library/${library}/categories/${id}`
    const res = await fetch(config.API_URL + url, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}
