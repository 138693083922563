import { Chip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import DraftsIcon from '@material-ui/icons/DraftsOutlined'
import ScheduleIcon from '@material-ui/icons/ScheduleOutlined'
import { isFuture } from 'date-fns'
import format from 'date-fns/format'

function PostStatus({ post, ...props }) {
  const icon = post.published ? (
    <CheckIcon />
  ) : isFuture(new Date(post.publish_date)) ? (
    <ScheduleIcon />
  ) : (
    <DraftsIcon />
  )

  return (
    <Chip
      color={post.published ? 'primary' : 'default'}
      icon={icon}
      label={
        post.published
          ? isFuture(new Date(post.publish_date))
            ? `Scheduled for publication: ${format(
                new Date(post.publish_date),
                'd MMM Y'
              )}`
            : `Published on ${format(new Date(post.publish_date), 'd MMM Y')}`
          : `Draft`
      }></Chip>
  )
}

export default PostStatus
