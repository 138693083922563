import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLessOutlined'
import ExpandMore from '@material-ui/icons/ExpandMoreOutlined'
import Link from 'components/Link'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '60px',
    zIndex: 9999,
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mobileCategoriesButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& > .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  categoriesMenu: {
    height: 0,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 'auto',
      overflow: 'auto',
    },
    zIndex: 9999,
    '& *': {
      color: '#444',
    },
  },
  categoriesMenuMobileOpen: {
    height: 'auto',
    overflow: 'auto',
  },
  categoriesMenuItem: {
    padding: 0,
    margin: 0,
    zIndex: 9999,
    borderRadius: '25px',
  },
  menuItemContainer: {
    [theme.breakpoints.up('md')]: {
      '&:first-child > * > a': {
        //paddingLeft: 0,
      },
    },
    '& > .MuiCollapse-root': {
      maxWidth: '100%',
    },
  },
  menuLink: {
    textDecoration: 'none',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  menuLinkIcon: {
    background: theme.palette.primary[500],
    height: '100%',
    padding: theme.spacing(1, 2),
    '&:hover': {
      background: theme.palette.primary[700],
    },
    display: 'flex',
  },
  collapse: {
    zIndex: 9999,
    maxWidth: '100%',
    '& .MuiBox-root': {
      padding: 0,
    },
  },
}))

function CategoryMenuItem({ category, categoriesOpen, handleCategoryClick, linkPrefix }) {
  const classes = useStyles()

  return (
    <Box className={classes.menuItemContainer}>
      <ListItem
        onClick={() =>
          handleCategoryClick(category.id, category.sub_categories.length > 0)
        }
        className={classes.categoriesMenuItem}
        button
        key={category.id}>
        <Link
          className={`${classes.menuLink} ${
            category.sub_categories.length ? classes.menuLinkWithIcon : ''
          }`}
          to={`/${linkPrefix}/categories/${category.id}`}>
          <ListItemText
            primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
            primary={category.name}
          />
        </Link>
        {category.sub_categories.length > 0 && (
          <Box className={classes.menuLinkIcon}>
            {categoriesOpen[category.id] ? <ExpandLess /> : <ExpandMore />}
          </Box>
        )}
      </ListItem>
      {category.sub_categories.length > 0 && (
        <Collapse
          className={classes.collapse}
          in={categoriesOpen[category.id]}
          timeout='auto'>
          <List component='div' disablePadding>
            {category.sub_categories.map(subCategory => (
              <Box pl={3} key={subCategory.id}>
                <CategoryMenuItem
                  category={subCategory}
                  categoriesOpen={categoriesOpen}
                  handleCategoryClick={handleCategoryClick}
                  linkPrefix={linkPrefix}
                />
              </Box>
            ))}
          </List>
        </Collapse>
      )}
    </Box>
  )
}

function CategoryMenu({ categories, history, linkPrefix }) {
  const classes = useStyles()
  const [categoriesOpen, setCategoriesOpen] = useState({})
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleCategoryClick = (id, hasSubCats) => {
    if (hasSubCats) {
      const newValue = categoriesOpen.hasOwnProperty(id) ? !categoriesOpen[id] : true
      setCategoriesOpen({ ...categoriesOpen, [id]: newValue })
    } else {
      history.push(`/${linkPrefix}/categories/${id}`)
    }
  }
  return (
    <Box className={classes.container}>
      <Button
        onClick={() => {
          setMobileOpen(mobileOpen => !mobileOpen)
        }}
        className={`${classes.mobileCategoriesButton} ${classes.menuLink}`}>
        Categories
        {mobileOpen ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <List
        className={`${classes.categoriesMenu} ${
          mobileOpen && classes.categoriesMenuMobileOpen
        }`}
        disablePadding>
        {categories.all.map(x => (
          <CategoryMenuItem
            key={x.id}
            category={x}
            categoriesOpen={categoriesOpen}
            handleCategoryClick={handleCategoryClick}
            linkPrefix={linkPrefix}
          />
        ))}
      </List>
    </Box>
  )
}

export default CategoryMenu
