import BreadCrumbs from 'components/BreadCrumbs'
import { BreadcrumbContext } from 'contexts/BreadcrumbContext'
import { useContext, useEffect } from 'react'

function ComponentWithBreadCrumbs({ component: C, breadcrumbs, ...props }) {
  const { setBreadcrumbs } = useContext(BreadcrumbContext)

  useEffect(() => {
    if (breadcrumbs) {
      setBreadcrumbs(breadcrumbs)
    }
  }, [breadcrumbs, setBreadcrumbs])

  return (
    <>
      <BreadCrumbs />
      <C {...props} />
    </>
  )
}

export default ComponentWithBreadCrumbs
