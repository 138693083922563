import LibrariesForm from 'containers/site-content/libraries/Form'
import Libraries from 'containers/site-content/libraries/List'
import NavigationForm from 'containers/site-content/navigation/Form'
import Navigation from 'containers/site-content/navigation/List'
import { AuthContext } from 'contexts/AuthContext'
import { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from './components/routes/AuthenticatedRoute'
import UnauthenticatedRoute from './components/routes/UnauthenticatedRoute'
import Candio from './containers/Candio'
import GettingStarted from './containers/GettingStarted'
import AnnouncementForm from './containers/announcements/Form'
import AnnouncementList from './containers/announcements/List'
import BlogList from './containers/blog/List'
import BlogPost from './containers/blog/Post'
import DiscountCodesForm from './containers/discount-codes/Form'
import DiscountCodesList from './containers/discount-codes/List'
import LeasingForm from './containers/leasing/Form'
import LeasingList from './containers/leasing/List'
import LibraryShared from './containers/library/Shared'
import LibraryCategory from './containers/library/categories/Category'
import LibraryCategoryCreate from './containers/library/categories/CategoryCreate'
import LibraryHome from './containers/library/home/LibraryHome'
import LibraryPost from './containers/library/post/Post'
import LibraryPostCreate from './containers/library/post/PostCreate'
import Login from './containers/login/Login'
import Logout from './containers/logout/Logout'
import MyAccount from './containers/my-account/MyAccount'
import MyLibraryCategory from './containers/my-library/categories/Category'
import MyLibraryCategoryCreate from './containers/my-library/categories/CategoryCreate'
import MyLibraryHome from './containers/my-library/home/LibraryHome'
import MyLibraryPost from './containers/my-library/post/Post'
import MyLibraryPostCreate from './containers/my-library/post/PostCreate'
import NotFound from './containers/not-found/NotFound'
import NotificationList from './containers/notifications/List'
import Notification from './containers/notifications/Notification'
import GeographicPort from './containers/number-porting/GeographicPort'
import NumberPortsList from './containers/number-porting/List'
import PortabilityCheck from './containers/number-porting/PortabilityCheck'
import PasswordResetEmailForm from './containers/password-reset/EmailForm'
import PasswordResetPasswordForm from './containers/password-reset/PasswordForm'
import PriceList from './containers/price-list/PriceList'
import BroadbandPackagesForm from './containers/products/broadband-packages/Form'
import BroadbandPackagesList from './containers/products/broadband-packages/List'
import BundledMinutesForm from './containers/products/bundled-minutes/Form'
import BundledMinutesList from './containers/products/bundled-minutes/List'
import CallRecordingForm from './containers/products/call-recording/Form'
import CallRecordingList from './containers/products/call-recording/List'
import HardwareForm from './containers/products/hardware/Form'
import HardwareList from './containers/products/hardware/List'
import NetworkHardwareForm from './containers/products/network-hardware/Form'
import NetworkHardwareList from './containers/products/network-hardware/List'
import NetworkServicesForm from './containers/products/network-services/Form'
import NetworkServicesList from './containers/products/network-services/List'
import NumberPortingForm from './containers/products/number-porting/Form'
import NumberPortingList from './containers/products/number-porting/List'
import SystemProductForm from './containers/products/system/Form'
import SystemProductList from './containers/products/system/List'
import UcApplicationsForm from './containers/products/uc-applications/Form'
import UcApplicationsList from './containers/products/uc-applications/List'
import UcLicensesForm from './containers/products/uc-licenses/Form'
import UcLicensesList from './containers/products/uc-licenses/List'
import ProposalsForm from './containers/proposals/Form'
import QuotesList from './containers/quotes/List'
import QuoteShared from './containers/quotes/Shared'
import QuotesCreate from './containers/quotes/create/Create'
import QuotesView from './containers/quotes/create/View'
import ResellersForm from './containers/resellers/Form'
import ResellersList from './containers/resellers/List'
import SalesDash from './containers/sales-dash/SalesDash'
import Hub from './containers/hub/Hub'
import Signup from './containers/signup/Signup'
import PagesList from './containers/site-content/pages/List'
import PageForm from './containers/site-content/pages/Post'
import PageCreate from './containers/site-content/pages/PostCreate'
import TariffsForm from './containers/tariffs/Form'
import TariffsList from './containers/tariffs/List'
import TrainingForm from './containers/training/Form'
import TrainingList from './containers/training/List'
import UsersForm from './containers/users/Form'
import UsersList from './containers/users/List'
import Status from 'containers/status/Status'

function AppRoutes() {
  const { user } = useContext(AuthContext)

  const homeComponent = () => {
    switch (user && user.type) {
      case 'distributor':
        return Hub
      default:
        return user && user.getting_started_percent < 100 ? GettingStarted : Hub
    }
  }

  const salesDashTypes = () => {
    const types = []
    types.push('distributor')
    types.push('reseller')

    return types
  }

  const libraryTypes = () => {
    return ['distributor', 'reseller']
  }

  const myLibraryTypes = () => {
    return ['reseller']
  }

  return (
    <>
      <Switch>
        <AuthenticatedRoute path='/' exact component={homeComponent()} />

        <AuthenticatedRoute
          types={['reseller']}
          roles={['admin', 'standard']}
          path='/getting-started'
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Getting Started', path: '/getting-started' },
          ]}
          exact
          component={GettingStarted}
        />

        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin', 'standard']}
          path='/status'
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Status', path: '/status' },
          ]}
          exact
          component={Status}
        />

        <AuthenticatedRoute
          types={['reseller', 'distributor']}
          roles={['admin', 'standard']}
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Hub', path: '/hub' },
          ]}
          path='/hub'
          exact
          component={Hub}
        />

        {salesDashTypes().length > 0 && (
          <AuthenticatedRoute
            breadcrumbs={[
              { label: 'Home', path: '/' },
              { label: 'Sales Dash', path: '/sales-dash' },
            ]}
            types={salesDashTypes()}
            roles={['admin']}
            path='/sales-dash'
            exact
            component={SalesDash}
          />
        )}

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Training', path: '/training' },
          ]}
          types={['distributor']}
          roles={['admin']}
          path='/training'
          exact
          component={TrainingList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Training', path: '/training' },
            { label: 'Create' },
          ]}
          types={['distributor']}
          roles={['admin']}
          path='/training/create'
          exact
          component={TrainingForm}
        />
        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin']}
          path='/training/update/:id'
          exact
          component={TrainingForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Announcements', path: '/announcements' },
          ]}
          types={['distributor']}
          roles={['admin']}
          path='/announcements'
          exact
          component={AnnouncementList}
        />

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Announcements', path: '/announcements' },
            { label: 'Create' },
          ]}
          types={['distributor']}
          roles={['admin']}
          path='/announcements/create'
          exact
          component={AnnouncementForm}
        />

        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin']}
          path='/announcements/update/:id'
          exact
          component={AnnouncementForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Notifications', path: '/notifications' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin', 'standard']}
          path='/notifications'
          exact
          component={NotificationList}
        />

        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin', 'standard']}
          path='/notifications/:id'
          exact
          component={Notification}
        />

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'My Library', path: '/my-library' },
          ]}
          types={myLibraryTypes()}
          roles={['admin', 'standard']}
          path='/my-library'
          exact
          component={MyLibraryHome}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'My Library', path: '/my-library' },
            { label: 'Create Category' },
          ]}
          types={myLibraryTypes()}
          roles={['admin', 'standard']}
          path='/my-library/categories/create'
          exact
          component={MyLibraryCategoryCreate}
        />
        <AuthenticatedRoute
          types={myLibraryTypes()}
          roles={['admin', 'standard']}
          path='/my-library/categories/:id'
          exact
          component={MyLibraryCategory}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'My Library', path: '/my-library' },
            { label: 'Create Post' },
          ]}
          types={myLibraryTypes()}
          roles={['admin', 'standard']}
          path='/my-library/posts/create'
          exact
          component={MyLibraryPostCreate}
        />
        <AuthenticatedRoute
          types={myLibraryTypes()}
          roles={['admin', 'standard']}
          path='/my-library/posts/:id'
          exact
          component={MyLibraryPost}
        />

        <AuthenticatedRoute
          types={libraryTypes()}
          roles={['admin', 'standard']}
          path='/library/:slug'
          exact
          component={LibraryHome}
        />
        <AuthenticatedRoute
          types={libraryTypes()}
          roles={['admin', 'standard']}
          path='/library/:slug/categories/create'
          exact
          component={LibraryCategoryCreate}
        />
        <AuthenticatedRoute
          types={libraryTypes()}
          roles={['admin', 'standard']}
          path='/library/:slug/categories/:id'
          exact
          component={LibraryCategory}
        />
        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin', 'standard']}
          path='/library/:slug/posts/create'
          exact
          component={LibraryPostCreate}
        />
        <AuthenticatedRoute
          types={libraryTypes()}
          roles={['admin', 'standard']}
          path='/library/:slug/posts/:id'
          exact
          component={LibraryPost}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'My Account' }]}
          path='/my-account'
          exact
          component={MyAccount}></AuthenticatedRoute>

        <AuthenticatedRoute
          types={libraryTypes()}
          roles={['admin', 'standard']}
          permission='price_list'
          path='/price-list'
          exact
          component={PriceList}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Partners' }]}
          types={['distributor']}
          roles={['admin']}
          path='/resellers'
          exact
          component={ResellersList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Partners', path: '/resellers' },
            { label: 'Create PArtner' },
          ]}
          types={['distributor']}
          roles={['admin']}
          path='/resellers/create'
          exact
          component={ResellersForm}
        />
        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin']}
          path='/resellers/update/:id'
          exact
          component={ResellersForm}
        />

        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin']}
          path='/resellers/:reseller/users'
          exact
          component={UsersList}
        />
        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin']}
          path='/resellers/:reseller/users/create'
          exact
          component={UsersForm}
        />
        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin']}
          path='/resellers/:reseller/users/update/:id'
          exact
          component={UsersForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            {
              label: user?.type === 'distributor' ? 'Administrators' : 'Users',
              path: '/users',
            },
          ]}
          types={['reseller', 'distributor']}
          roles={['admin']}
          path='/users'
          exact
          component={UsersList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            {
              label: user?.type === 'distributor' ? 'Administrators' : 'Users',
              path: '/users',
            },
            {
              label:
                user?.type === 'distributor' ? 'Create Administrator' : 'Create User',
            },
          ]}
          types={['reseller', 'distributor']}
          roles={['admin']}
          path='/users/create'
          exact
          component={UsersForm}
        />
        <AuthenticatedRoute
          types={['reseller', 'distributor']}
          roles={['admin']}
          path='/users/update/:id'
          exact
          component={UsersForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Quotes', path: '/quotes' },
          ]}
          types={['reseller', 'distributor']}
          roles={['admin', 'standard']}
          path='/quotes'
          exact
          component={QuotesList}
        />

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Quotes', path: '/quotes' },
            { label: 'Create quote ' },
          ]}
          types={['reseller']}
          roles={['admin', 'standard']}
          path='/quotes/create'
          exact
          component={QuotesCreate}
        />

        <AuthenticatedRoute
          types={['reseller']}
          roles={['admin', 'standard']}
          path='/quotes/:id/:step?'
          exact
          component={QuotesView}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'System Products' }]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/system'
          exact
          component={SystemProductList}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/system/update/:id'
          exact
          component={SystemProductForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Call Recording' }]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/call-recording'
          exact
          component={CallRecordingList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Call Recording', path: '/products/call-recording' },
            { label: 'Create' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/call-recording/create'
          exact
          component={CallRecordingForm}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/call-recording/update/:id'
          exact
          component={CallRecordingForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Number porting' }]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/number-porting'
          exact
          component={NumberPortingList}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/number-porting/update/:id'
          exact
          component={NumberPortingForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Bundled Minutes Products' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/bundled-minutes'
          exact
          component={BundledMinutesList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Bundled Minutes Products', path: '/products/bundled-minutes' },
            { label: 'Create' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/bundled-minutes/create'
          exact
          component={BundledMinutesForm}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/bundled-minutes/update/:id'
          exact
          component={BundledMinutesForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'UCP Licenses' }]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/uc-licences'
          exact
          component={UcLicensesList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'UCP Licenses', path: '/products/uc-licences' },
            { label: 'Create' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/uc-licences/create'
          exact
          component={UcLicensesForm}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/uc-licences/update/:id'
          exact
          component={UcLicensesForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'UC Applications' }]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/uc-applications'
          exact
          component={UcApplicationsList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'UC Applications', path: '/products/uc-applications' },
            { label: 'Create' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/uc-applications/create'
          exact
          component={UcApplicationsForm}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/uc-applications/update/:id'
          exact
          component={UcApplicationsForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Connectivity Products' }]}
          types={['reseller']}
          roles={['admin']}
          path='/products/connectivity'
          exact
          component={BroadbandPackagesList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Connectivity Products', path: '/products/connectivity' },
            { label: 'Create' },
          ]}
          types={['reseller']}
          roles={['admin']}
          path='/products/connectivity/create'
          exact
          component={BroadbandPackagesForm}
        />
        <AuthenticatedRoute
          types={['reseller']}
          roles={['admin']}
          path='/products/connectivity/update/:id'
          exact
          component={BroadbandPackagesForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Devices' }]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/hardware'
          exact
          component={HardwareList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Devices', path: '/products/hardware' },
            { label: 'Create' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/hardware/create'
          exact
          component={HardwareForm}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/hardware/update/:id'
          exact
          component={HardwareForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Connectivity Hardware' }]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/connectivity-hardware'
          exact
          component={NetworkHardwareList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Connectivity Hardware', path: '/products/connectivity-hardware' },
            { label: 'Create' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/connectivity-hardware/create'
          exact
          component={NetworkHardwareForm}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/connectivity-hardware/update/:id'
          exact
          component={NetworkHardwareForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Engineering Products' }]}
          types={['reseller']}
          roles={['admin']}
          path='/products/engineering'
          exact
          component={NetworkServicesList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Engineering Products', path: '/products/engineering' },
            { label: 'Create' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/engineering/create'
          exact
          component={NetworkServicesForm}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin']}
          path='/products/engineering/update/:id'
          exact
          component={NetworkServicesForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Tariffs' }]}
          types={['reseller']}
          roles={['admin']}
          path='/tariffs'
          exact
          component={TariffsList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Tariffs', path: '/tariffs' },
            { label: 'Create' },
          ]}
          types={['reseller']}
          roles={['admin']}
          path='/tariffs/create'
          exact
          component={TariffsForm}
        />
        <AuthenticatedRoute
          types={['reseller']}
          roles={['admin']}
          path='/tariffs/update/:id'
          exact
          component={TariffsForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Discount Codes' }]}
          types={['reseller']}
          roles={['admin']}
          path='/discount-codes'
          exact
          component={DiscountCodesList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Discount Codes', path: '/discount-codes' },
            { label: 'Create' },
          ]}
          types={['reseller']}
          roles={['admin']}
          path='/discount-codes/create'
          exact
          component={DiscountCodesForm}
        />
        <AuthenticatedRoute
          types={['reseller']}
          roles={['admin']}
          path='/discount-codes/update/:id'
          exact
          component={DiscountCodesForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Leasing' }]}
          types={['reseller']}
          roles={['admin']}
          path='/leasing'
          exact
          component={LeasingList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Leasing', path: '/leasing' },
            { label: 'Create' },
          ]}
          types={['reseller']}
          roles={['admin']}
          path='/leasing/create'
          exact
          component={LeasingForm}
        />
        <AuthenticatedRoute
          types={['reseller']}
          roles={['admin']}
          path='/leasing/update/:id'
          exact
          component={LeasingForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Number Porting' }]}
          types={['distributor', 'reseller']}
          roles={['admin', 'standard']}
          path='/number-porting'
          exact
          component={NumberPortsList}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Number Porting', path: '/number-porting' },
            { label: 'Portability Check' },
          ]}
          types={['distributor', 'reseller']}
          roles={['admin', 'standard']}
          path='/number-porting/portability-check'
          exact
          component={PortabilityCheck}
        />
        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin', 'standard']}
          path='/number-porting/geographic-port/:id?'
          exact
          component={GeographicPort}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Proposal' }]}
          types={['reseller']}
          roles={['admin']}
          path='/proposal'
          exact
          component={ProposalsForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Blog' }]}
          types={['reseller', 'distributor']}
          roles={['admin', 'standard']}
          path='/blog'
          exact
          component={BlogList}
        />

        <AuthenticatedRoute
          types={['reseller', 'distributor']}
          roles={['admin', 'standard']}
          path='/blog/:slug'
          exact
          component={BlogPost}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Candio' }]}
          types={['reseller']}
          roles={['admin', 'standard']}
          path='/candio'
          exact
          component={Candio}
        />

        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin']}
          path='/site-content/navigation'
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Navigation' }]}
          exact
          component={Navigation}
        />

        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin']}
          path='/site-content/navigation/update/:id'
          exact
          component={NavigationForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Pages' }]}
          types={['distributor']}
          roles={['admin']}
          path='/site-content/pages'
          exact
          component={PagesList}
        />

        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Pages', path: '/site-content/pages' },
            { label: 'Create' },
          ]}
          types={['distributor']}
          roles={['admin', 'standard']}
          path='/site-content/pages/create'
          exact
          component={PageCreate}
        />

        <AuthenticatedRoute
          types={['distributor', 'reseller']}
          roles={['admin', 'standard']}
          path='/site-content/pages/:id'
          exact
          component={PageForm}
        />

        <AuthenticatedRoute
          breadcrumbs={[{ label: 'Home', path: '/' }, { label: 'Libraries' }]}
          types={['distributor']}
          roles={['admin']}
          path='/site-content/libraries'
          exact
          component={Libraries}
        />
        <AuthenticatedRoute
          breadcrumbs={[
            { label: 'Home', path: '/' },
            { label: 'Libraries', path: '/site-content/libraries' },
            { label: 'Create' },
          ]}
          types={['distributor']}
          roles={['admin']}
          path='/site-content/libraries/create'
          exact
          component={LibrariesForm}
        />

        <AuthenticatedRoute
          types={['distributor']}
          roles={['admin']}
          path='/site-content/libraries/update/:id'
          exact
          component={LibrariesForm}
        />

        <Route path='/library/shared/:id' exact component={LibraryShared} />
        <Route path='/quote-share/:id' exact component={QuoteShared} />

        <UnauthenticatedRoute
          path='/password/reset'
          exact
          component={PasswordResetEmailForm}
        />
        <UnauthenticatedRoute
          path='/password/reset/:token'
          exact
          component={PasswordResetPasswordForm}
        />

        <AuthenticatedRoute path='/logout' exact component={Logout} />
        <UnauthenticatedRoute path='/login' exact component={Login} />
        <UnauthenticatedRoute path='/signup' exact component={Signup} />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default AppRoutes
