import { CircularProgress, InputAdornment } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Search } from '@material-ui/icons'
import algoliasearch from 'algoliasearch/lite'
import config from 'config'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useRef, useState } from 'react'
import {
  Configure,
  Highlight,
  InstantSearch,
  connectHits,
  connectRefinementList,
  connectSearchBox,
} from 'react-instantsearch-dom'
import { NavLink } from 'react-router-dom'

const displayConfig = {
  Quotes: {
    attributes: [
      { key: 'reseller', label: 'Partner' },
      { key: 'status', label: 'Status' },
    ],
  },
  'Number Ports': {
    attributes: [
      { key: 'reseller', label: 'Partner' },
      { key: 'status', label: 'Status' },
      { key: 'main_number', label: 'Main Number' },
      { key: 'line_type', label: 'Line Type' },
      { key: 'address', label: 'Address' },
    ],
  },
  Library: {
    attributes: [{ key: 'content', label: undefined }],
  },
  Resellers: {
    attributes: [
      { key: 'account_number', label: 'Account Number' },
      { key: 'domains', label: 'Domains' },
    ],
  },
}

const useStyles = makeStyles(theme => ({
  chip: {
    maxWidth: theme.palette.light,
    margin: 'auto',
    fontSize: '0.6rem',
    height: '16px',
    color: 'white',
    marginLeft: '8px',
    marginBottom: '2px',
  },
  searchButton: {
    paddingLeft: '40px',
    paddingRight: '48px',
  },
}))
function getDisplayConfig(type) {
  if (displayConfig.hasOwnProperty(type)) {
    return displayConfig[type]
  } else {
    return {
      attributes: [],
    }
  }
}

function GlobalSearch() {
  const [show, setShow] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const { user } = useContext(AuthContext)

  if (!config.ALGOLIA_APP_ID) {
    return null
  }

  const searchClient = algoliasearch(config.ALGOLIA_APP_ID, user.search_key)

  const handleChangeSearch = e => {
    setSearchValue(e.target.value)
    setShow(e.target.value.length > 0)
  }

  return (
    <InstantSearch searchClient={searchClient} indexName='global_search'>
      <Configure hitsPerPage={8} />
      <div>
        <TextField
          placeholder='Search...'
          variant='outlined'
          fullWidth
          autoFocus
          value={searchValue}
          onChange={handleChangeSearch}
        />

        {show && (
          <Dialog
            maxWidth='xl'
            open={show}
            onClose={() => setShow(false)}
            TransitionComponent={Transition}>
            <div style={{ padding: '12px' }}>
              <CustomSearchBox
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />

              <div style={{ display: 'flex', width: '80vw', height: '80vh' }}>
                <div style={{ width: '200px', padding: '12px' }}>
                  <CustomRefinementList attribute='type' />
                </div>

                <div style={{ flexGrow: '1' }}>
                  <CustomHits setShow={setShow} />
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </InstantSearch>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const CustomSearchBox = connectSearchBox(
  ({ searchValue, setSearchValue, currentRefinement, refine }) => {
    const [searching, setSearching] = useState(false)
    const valueRef = useRef(searchValue)
    const timerRef = useRef()

    const handleChange = e => {
      setSearching(true)
      setSearchValue(e.target.value)
      valueRef.current = e.target.value

      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(async () => {
        await refine(valueRef.current)
        setSearching(false)
      }, 500)
    }

    return (
      <TextField
        placeholder='Search...'
        variant='outlined'
        fullWidth
        autoFocus
        value={searchValue}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              {searching ? <CircularProgress size={24} /> : <Search />}
            </InputAdornment>
          ),
        }}
      />
    )
  }
)

const CustomHits = connectHits(({ setShow, hits }) => (
  <Box display='flex' flexDirection='column' gap={1} padding={1}>
    {hits.length === 0 ? (
      <Box marginTop={3} textAlign='center'>
        <Typography>No results found</Typography>
      </Box>
    ) : (
      <List>
        {hits.map((hit, i) => (
          <Hit key={i} hit={hit} setShow={setShow} />
        ))}
      </List>
    )}
  </Box>
))

const CustomRefinementList = connectRefinementList(({ items, refine }) => (
  <div>
    {items.length === 0 ? (
      <Box marginTop={3} textAlign='center'>
        <Typography>No results found</Typography>
      </Box>
    ) : (
      <>
        {items.map(item => (
          <FormControlLabel
            key={item.label}
            control={
              <Checkbox
                color='primary'
                checked={item.isRefined}
                onChange={event => refine(item.value)}
              />
            }
            label={item.label}
          />
        ))}
      </>
    )}
  </div>
))

function Hit(props) {
  const classes = useStyles()

  return (
    <>
      <ListItem
        button
        component={NavLink}
        to={props.hit.url}
        onClick={() => props.setShow(false)}>
        <ListItemText
          primary={
            <React.Fragment>
              {props.hit.title ?? 'Untitled'}
              <Chip
                label={props.hit.type}
                size='small'
                color='primary'
                className={classes.chip}
              />
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              {getDisplayConfig([props.hit.type]).attributes.map((attr, i) => (
                <React.Fragment key={`attr-${i}`}>
                  {attr.label !== undefined && `${attr.label}: `}
                  <Highlight attribute={attr.key} hit={props.hit} />
                </React.Fragment>
              ))}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider />
    </>
  )
}

export default GlobalSearch
