import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MuiAlert from '@material-ui/lab/Alert'
import { deleteAnnouncement, list } from 'api/announcements'
import TableIcon from 'components/TableIcon'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  pagination: {
    marginBottom: '60px',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
})

function List() {
  const classes = useStyles()
  const [announcements, setAnnouncements] = useState([])

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const { loading, setLoading } = useContext(LoadingContext)
  const [hideContent, setHideContent] = useState(true)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await list()
      setAnnouncements(res)
      setLoading(false)
      setHideContent(false)
    }

    fetchData()
  }, [setLoading, setAnnouncements])

  useEffect(() => {
    setLoading(true)
  }, [setLoading])

  const handleDelete = async id => {
    setLoading(true)
    const success = await deleteAnnouncement(id)

    if (success) {
      const res = await list()
      setAnnouncements(res)
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully deleted announcement')
    } else {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to delete announcement')
    }

    setDeleteOpen(false)
    setLoading(false)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleOpenDeleteDialog = id => {
    setDeleteId(id)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleChangePage = async (event, newPage) => {
    setLoading(true)
    setAnnouncements(
      await list(parseInt(announcements.meta.per_page), parseInt(newPage + 1))
    )
    setLoading(false)
  }

  const handleChangeRowsPerPage = async event => {
    setLoading(true)
    setAnnouncements(await list(parseInt(event.target.value, 10)))
    setLoading(false)
  }

  return hideContent ? null : (
    <>
      <Typography variant='h4' gutterBottom>
        Announcements
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Summary</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Published?</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {announcements.data?.length > 0 ? (
              announcements.data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.summary}</TableCell>
                  <TableCell>
                    <img src={'/api/' + row.image_thumbnail_path} alt='' />
                  </TableCell>

                  <TableCell>{row.published_at ? 'Yes' : 'No'}</TableCell>
                  <TableCell align='right'>
                    <Tooltip title='Edit'>
                      <IconButton
                        aria-label='edit'
                        color='default'
                        component={NavLink}
                        to={`/announcements/update/${row.id}`}>
                        <TableIcon icon='edit' color='yellow' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <IconButton
                        aria-label='delete'
                        onClick={() => handleOpenDeleteDialog(row.id)}>
                        <TableIcon icon='delete' />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  No announcements found.{' '}
                  <Link to='/announcements/create'>Create an announcement</Link>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 15, 25]}
        component='div'
        count={announcements.meta.total}
        rowsPerPage={parseInt(announcements.meta.per_page)}
        page={announcements.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='alert-dialog-title'>{'Announcement Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this announcement?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleDelete(deleteId)}
            color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Fab
        className={`${classes.fab} ${user.live_chat_url !== '' && classes.fabShift}`}
        color='secondary'
        aria-label='add'
        component={NavLink}
        to='/announcements/create'>
        <AddIcon />
      </Fab>
    </>
  )
}

export default List
