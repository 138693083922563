import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  formControl: {
    width: '500px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

function CostPriceField({ values, setValues, valuesProperty, label }) {
  const { user } = useContext(AuthContext)
  const classes = useStyles()

  const handleDropdownChange = event => {
    if (parseInt(event.target.value) === 1) {
      setValues({ ...values, [valuesProperty]: { value: 100 } })
    } else {
      setValues({ ...values, [valuesProperty]: { value: 0 } })
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const renderTextField = () => {
    return (
      <TextField
        variant='standard'
        margin='normal'
        required
        fullWidth
        type='number'
        step='0.01'
        InputLabelProps={{
          shrink: true,
        }}
        id={valuesProperty}
        label={label}
        name={valuesProperty}
        defaultValue={values[valuesProperty].value}
        onBlur={handleChange}
      />
    )
  }

  if (user.type === 'reseller') {
    return renderTextField()
  }

  return (
    <div className={classes.container}>
      <FormControl variant='standard' className={classes.formControl}>
        <InputLabel id='fixed_cost_price'>{label} Has Fixed Cost Price?</InputLabel>
        <Select
          labelId='fixed_cost_price'
          id='fixed_cost_price'
          name='fixed_cost_price'
          value={parseFloat(values[valuesProperty].value) > 0 ? '1' : '0'}
          onChange={handleDropdownChange}
          label='Fixed Cost Price?'>
          <MenuItem value='0'>No</MenuItem>
          <MenuItem value='1'>Yes</MenuItem>
        </Select>
      </FormControl>

      {parseFloat(values[valuesProperty].value) > 0 ? (
        renderTextField()
      ) : (
        <div style={{ marginTop: '2.5rem', width: '100%' }}>
          <small>(Cost will be generated using partner discount)</small>
        </div>
      )}
    </div>
  )
}

export default CostPriceField
