import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import {
  BsEye,
  BsCheckCircle,
  BsXCircle,
  BsTrash3,
  BsArrowUp,
  BsArrowDown,
  BsStar,
  BsBook,
  BsPeople,
  BsStarFill,
  BsPlus,
  BsPencil,
} from 'react-icons/bs'
import { CiLogin } from 'react-icons/ci'
import { AiFillHome, AiOutlineHome } from 'react-icons/ai'

function TableIcon({ icon, color = 'default' }) {
  const useStyles = makeStyles(theme => ({
    red: {
      '& path': {
        stroke: theme.palette.danger.main,
        color: theme.palette.danger.main,
      },
    },
    green: {
      '& path': {
        stroke: theme.palette.success.main,
        color: theme.palette.success.main,
      },
    },
    blue: {
      '& path': {
        stroke: theme.palette.darkBlue,
        color: theme.palette.darkBlue,
      },
    },
    yellow: {
      '& path': {
        stroke: theme.palette.warning.main,
        color: theme.palette.warning.main,
      },
    },
    default: {
      '& path': {
        stroke: theme.palette.lightText,
        color: theme.palette.lightText,
      },
    },
  }))

  const iconMap = {
    delete: BsTrash3,
    view: BsEye,
    tick: BsCheckCircle,
    cross: BsXCircle,
    login: CiLogin,
    up: BsArrowUp,
    down: BsArrowDown,
    star: BsStar,
    starfill: BsStarFill,
    book: BsBook,
    people: BsPeople,
    add: BsPlus,
    edit: BsPencil,
    home: AiFillHome,
    homeOutline: AiOutlineHome,
  }

  const classes = useStyles()

  const IconTag = iconMap[icon]

  return <IconTag className={classes[color]} />
}

export default TableIcon
