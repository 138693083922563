import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  carousel: {
    width: '100%',
  },
}))

function ItemCarousel(props) {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Carousel
      className={classes.carousel}
      navButtonsAlwaysInvisible={true}
      activeIndicatorProps={{ style: { color: theme.palette.darkBlue } }}
      indicatorProps={{ style: { color: theme.palette.lightBlue } }}>
      {props.children}
    </Carousel>
  )
}

export default ItemCarousel
