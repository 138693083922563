import { MenuItem, Select } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { siteNav } from 'api/form-options'
import { create, get, update } from 'api/site-navs'
import { LoadingContext } from 'contexts/LoadingContext'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  image: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  selectedImage: {
    marginTop: theme.spacing(2),
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  productImage: {
    marginTop: theme.spacing(6),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  permissions: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

function Form(props) {
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const [hideContent, setHideContent] = useState(true)
  const [formOptions, setFormOptions] = useState(null)
  const [values, setValues] = useState({
    label: { value: '', error: '' },
    type: { value: '', error: '' },
    type_value: { value: '', error: '' },
  })
  const [errors, setErrors] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const formOptionsRes = await siteNav()

      setFormOptions(formOptionsRes)

      if (id !== undefined) {
        const siteNav = await get(id)
        const dataValues = {
          label: { value: siteNav.data.label },
          type: { value: siteNav.data.type },
          type_value: { value: siteNav.data.type_value },
        }
        setValues(dataValues)
      }
      setLoading(false)
      setHideContent(false)
    }

    fetchData()
  }, [id, setValues, setLoading, setFormOptions])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleChangeType = event => {
    setValues({
      ...values,
      type: { ...values.type, value: event.target.value },
      type_value: { ...values.type_value, value: '' },
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)

    const body = {
      label: values.label.value,
      type: values.type.value,
      type_value: values.type_value.value,
    }

    const result = id ? await update(id, body) : await create(body)

    if (result.success) {
      setLoading(false)
      props.history.push({
        pathname: '/site-content/navigation',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        {id ? 'Update Library' : 'Create Library'}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item sm={12} md={7} xl={5}>
            <Typography variant='h5' gutterBottom>
              Main Info
            </Typography>
            <Paper className={classes.paper}>
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='label'
                label='Label'
                name='label'
                defaultValue={values.label.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />

              <Select
                id='type'
                name='type'
                value={values.type.value}
                onChange={handleChangeType.bind(this)}
                label='Type Value'>
                {formOptions.types.map(o => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
              </Select>

              {values.type.value === 'LIBRARY' || values.type.value === 'PAGE' ? (
                <Select
                  id='type_value'
                  name='type_value'
                  value={values.type_value.value}
                  onChange={handleChange.bind(this)}
                  label='Type Value'>
                  <MenuItem value={``}>Please select</MenuItem>
                  {formOptions.type_values[values.type.value].map(o => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                values.type.value === 'EXTERNAL_LINK' && (
                  <TextField
                    variant='standard'
                    margin='normal'
                    required
                    fullWidth
                    id='type_value'
                    label='Type Value'
                    name='type_value'
                    defaultValue={values.type_value.value}
                    autoFocus
                    onChange={handleChange.bind(this)}
                  />
                )
              )}
            </Paper>

            {errors.length > 0 && (
              <MuiAlert severity='error'>
                {errors.map((error, index) => {
                  return <div key={index}>{error[1]}</div>
                })}
              </MuiAlert>
            )}
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={loading}
              className={classes.submit}>
              {id ? 'Update' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default Form
