import { Box, Button, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CandioBanner from 'components/CandioBanner'
import { AuthContext } from 'contexts/AuthContext'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import News from 'containers/sales-hub/News'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.values.lg,
    margin: 'auto',
  },
  items: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  item: {
    backgroundColor: theme.palette.lightBackground,
    color: theme.palette.lightText,
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  panels: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.lightBackground,
    color: theme.palette.lightText,
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      height: '120px',
      padding: theme.spacing(3),
    },
  },
  panelLink: {
    display: 'block',
  },
  quotes: {
    backgroundColor: theme.palette.lightBackground,
    color: theme.palette.lightText,
    textAlign: 'center',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  quoteButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}))

function Hub() {
  const classes = useStyles()
  const { hasFeature } = useContext(FeatureToggleContext)
  const { user } = useContext(AuthContext)
  const [panels, setPanels] = useState([])
  const { setLoading } = useContext(LoadingContext)
  const [hideContent, setHideContent] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setHideContent(false)
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  useEffect(() => {
    const flattenNavs = navs => {
      let items = []

      return navs
        .map(n => {
          if (n.items && n.items.length) {
            items = [...items, ...n.items]
          }
          return n
        })
        .concat(items.length ? flattenNavs(items) : items)
    }

    setPanels(flattenNavs(user.site_nav).filter(n => n.display_on_homepage))
  }, [user.site_nav])

  if (hideContent) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography variant='h4' gutterBottom>
        Hub
      </Typography>

      <Box mb={4}>
        <Paper className={classes.item}>
          <Typography align='center' variant='h6'>
            <strong>iPECS News</strong>
          </Typography>
          <News />
        </Paper>
      </Box>

      <div className={classes.panels}>
        {panels.map((p, i) => (
          <Paper key={`panel-${i}`} className={`${classes.panel}`}>
            <Typography align='center' variant='h6'>
              <strong>{p.label}</strong>
            </Typography>
            <Typography align='center'>
              <Button
                className={classes.panelLink}
                component={Link}
                to={p.url}
                variant='contained'
                color='primary'>
                View
              </Button>
            </Typography>
          </Paper>
        ))}
      </div>

      {hasFeature('candio_promotion') && (
        <Box mt={3}>
          <CandioBanner />
        </Box>
      )}
    </div>
  )
}

export default Hub
