import { Box, FormControlLabel, FormGroup } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { get as getReseller } from 'api/resellers'
import { create, get, update } from 'api/users'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import UserAvatar from 'components/UserAvatar'
import ImageField from 'components/ImageField'
import { BreadcrumbContext } from 'contexts/BreadcrumbContext'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  currentAvatar: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  orm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(3),
  },
  permissions: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

function Form(props) {
  const { reseller, id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const { user } = useContext(AuthContext)
  const [hideContent, setHideContent] = useState(true)
  const [resellerFeatures, setResellerFeature] = useState([])
  const [isVerified, setIsVerified] = useState(false)
  const [values, setValues] = useState({
    first_name: { value: '', error: '' },
    last_name: { value: '', error: '' },
    email: { value: '', error: '' },
    contact_email: { value: '', error: '' },
    phone_number: { value: '', error: '' },
    mobile_phone_number: { value: '', error: '' },
    job_title: { value: '', error: '' },
    role: { value: '', error: '' },
    quote_permission: { value: false },
    number_porting_permission: { value: false },
    price_list_permission: { value: false },
    sales_permission: { value: false },
    tech_permission: { value: false },
    orders_permission: { value: false },
    billing_permission: { value: false },
    finance_permission: { value: false },
  })
  const [meta, setMeta] = useState({})
  const [errors, setErrors] = useState([])
  const classes = useStyles()
  const { setBreadcrumbs } = useContext(BreadcrumbContext)

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      if (reseller) {
        const resellerResult = await getReseller(reseller)
        setResellerFeature(resellerResult.data.permissions)
      } else {
        setResellerFeature(user.account_features)
      }

      if (id !== undefined) {
        const userResult = await get(id, reseller)
        const dataValues = {
          first_name: { value: userResult.data.first_name },
          last_name: { value: userResult.data.last_name },
          email: { value: userResult.data.email },
          contact_email: { value: userResult.data.contact_email },
          phone_number: { value: userResult.data.phone_number },
          mobile_phone_number: { value: userResult.data.mobile_phone_number },
          job_title: { value: userResult.data.job_title },
          role: { value: userResult.data.role },
          quote_permission: {
            value: userResult.data.permissions.indexOf('quote') !== -1,
          },
          number_porting_permission: {
            value: userResult.data.permissions.indexOf('number_porting') !== -1,
          },
          price_list_permission: {
            value: userResult.data.permissions.indexOf('price_list') !== -1,
          },
          sales_permission: {
            value: userResult.data.permissions.indexOf('sales') !== -1,
          },
          tech_permission: {
            value: userResult.data.permissions.indexOf('tech') !== -1,
          },
          orders_permission: {
            value: userResult.data.permissions.indexOf('orders') !== -1,
          },
          billing_permission: {
            value: userResult.data.permissions.indexOf('billing') !== -1,
          },
          finance_permission: {
            value: userResult.data.permissions.indexOf('finance') !== -1,
          },
          current_avatar: userResult.data.avatar,
        }

        setBreadcrumbs(
          user.type === 'reseller'
            ? [
                { label: 'Home', path: '/' },
                { label: 'Users', path: '/users' },
                { label: `${userResult.data.first_name} ${userResult.data.last_name}` },
              ]
            : [
                { label: 'Home', path: '/' },
                { label: 'Administrators', path: '/users' },
                { label: `${userResult.data.first_name} ${userResult.data.last_name}` },
              ]
        )
        setIsVerified(userResult.data.verified)
        setValues(dataValues)
        setMeta(userResult.meta ?? {})
      }

      setLoading(false)
      setHideContent(false)
    }

    fetchData()
  }, [id, reseller, setValues, setLoading, user.account_features, setBreadcrumbs])

  const hasAccountFeature = feature => {
    return resellerFeatures.indexOf(feature) !== -1
  }

  const canSetRole = () => {
    return (reseller || user.type === 'reseller') && user.role === 'admin'
  }

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleChangeSwitch = event => {
    setValues({ ...values, [event.target.name]: { value: event.target.checked } })
  }

  const handeAvatarChange = data => {
    setValues({ ...values, avatar: data })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const body = {
      first_name: values.first_name.value,
      last_name: values.last_name.value,
      email: values.email.value,
      contact_email: values.contact_email.value,
      phone_number: values.phone_number.value,
      mobile_phone_number: values.mobile_phone_number.value,
      job_title: values.job_title.value,
      avatar: values.avatar,
      role: values.role.value,
    }

    if (canSetRole()) {
      body.quote_permission = values.quote_permission.value
      body.number_porting_permission = values.number_porting_permission.value
      body.price_list_permission = values.price_list_permission.value
      body.sales_permission = values.sales_permission.value
      body.tech_permission = values.tech_permission.value
      body.orders_permission = values.orders_permission.value
      body.billing_permission = values.billing_permission.value
      body.finance_permission = values.finance_permission.value
    }

    if (reseller) {
      body.rid = reseller
    }

    const result = id ? await update(id, body) : await create(body)
    if (result.success) {
      setLoading(false)
      props.history.push({
        pathname: reseller ? `/resellers/${reseller}/users` : '/users',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const getButtonLabel = () => {
    if (id) {
      return isVerified ? 'Update' : 'Update & Verify User'
    } else {
      return 'Create'
    }
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        {id
          ? reseller
            ? `Update ${meta.reseller_name} User`
            : `Update ${user.type === 'distributor' ? 'Administrator' : 'User'}`
          : `Create ${user.type === 'distributor' ? 'Administrator' : 'User'}`}
      </Typography>

      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item sm={12} md={7} xl={5}>
            <Typography variant='h5' gutterBottom>
              Main Info
            </Typography>
            <Paper className={classes.paper}>
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='first_name'
                label='First Name'
                name='first_name'
                defaultValue={values.first_name.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='last_name'
                label='Last Name'
                name='last_name'
                defaultValue={values.last_name.value}
                onChange={handleChange.bind(this)}
              />
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                type='email'
                id='email'
                label='Email'
                name='email'
                defaultValue={values.email.value}
                onChange={handleChange.bind(this)}
                helperText="This user's login email address"
              />
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='job_title'
                label='Job Title'
                name='job_title'
                defaultValue={values.job_title.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />
              <TextField
                variant='standard'
                margin='normal'
                fullWidth
                id='phone_number'
                label='Phone Number'
                name='phone_number'
                defaultValue={values.phone_number.value}
                onChange={handleChange.bind(this)}
              />
              <TextField
                variant='standard'
                margin='normal'
                fullWidth
                id='mobile_phone_number'
                label='Mobile Phone Number'
                name='mobile_phone_number'
                defaultValue={values.mobile_phone_number.value}
                onChange={handleChange.bind(this)}
              />
              {user.type == 'distributor' && (
                <FormControl variant='standard' className={classes.formControl}>
                  <InputLabel id='role_label'>User Type</InputLabel>
                  <Select
                    labelId='role_label'
                    id='role'
                    name='role'
                    value={values.role.value}
                    onChange={handleChange.bind(this)}
                    label='Role'>
                    <MenuItem value='standard'>Standard</MenuItem>
                    <MenuItem value='admin'>Admin</MenuItem>
                  </Select>
                </FormControl>
              )}

              {canSetRole() && (
                <React.Fragment>
                  <FormControl variant='standard' className={classes.formControl}>
                    <InputLabel id='role_label'>User Type</InputLabel>
                    <Select
                      labelId='role_label'
                      id='role'
                      name='role'
                      value={values.role.value}
                      onChange={handleChange.bind(this)}
                      label='Role'>
                      <MenuItem value='standard'>Standard</MenuItem>
                      <MenuItem value='admin'>Admin</MenuItem>
                    </Select>
                  </FormControl>
                  <Box mt={2}>
                    <Typography variant='caption'>
                      Admin users will have access to user manage and configurations
                      settings (products, leasing) etc. standard users will not have
                      access to these settings
                    </Typography>
                  </Box>
                </React.Fragment>
              )}
            </Paper>

            <Paper className={classes.paper}>
              <Typography variant='subtitle1' gutterBottom>
                Profile Image
              </Typography>
              <UserAvatar className={classes.currentAvatar} user={user}></UserAvatar>
              <ImageField onChange={handeAvatarChange} />
            </Paper>

            {errors.length > 0 && (
              <MuiAlert severity='error'>
                {errors.map((error, index) => {
                  return <div key={index}>{error[1]}</div>
                })}
              </MuiAlert>
            )}
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={loading}
              className={classes.submit}>
              {getButtonLabel()}
            </Button>
          </Grid>

          {(hasAccountFeature('quote') ||
            hasAccountFeature('number_porting') ||
            hasAccountFeature('price_list') ||
            hasAccountFeature('sales') ||
            hasAccountFeature('tech') ||
            hasAccountFeature('orders') ||
            hasAccountFeature('billing') ||
            hasAccountFeature('finance')) && (
            <Grid item md={5} xl={5}>
              <Typography variant='h5' gutterBottom>
                Features & Permission
              </Typography>

              <div className={classes.permissions}>
                <Card className={classes.cardRoot}>
                  <CardContent>
                    <Typography variant='body2' component='p'>
                      Limit access to the hub based on the following permissions:
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <FormControl component='fieldset'>
                      {hasAccountFeature('quote') && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.quote_permission.value}
                                onChange={handleChangeSwitch}
                                name='quote_permission'
                                color='primary'
                              />
                            }
                            label='Quote'
                          />
                        </FormGroup>
                      )}

                      {hasAccountFeature('number_porting') && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.number_porting_permission.value}
                                onChange={handleChangeSwitch}
                                name='number_porting_permission'
                                color='primary'
                              />
                            }
                            label='Number porting'
                          />
                        </FormGroup>
                      )}

                      {hasAccountFeature('price_list') && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.price_list_permission.value}
                                onChange={handleChangeSwitch}
                                name='price_list_permission'
                                color='primary'
                              />
                            }
                            label='Price list'
                          />
                        </FormGroup>
                      )}

                      {hasAccountFeature('sales') && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.sales_permission.value}
                                onChange={handleChangeSwitch}
                                name='sales_permission'
                                color='primary'
                              />
                            }
                            label='Sales'
                          />
                        </FormGroup>
                      )}

                      {hasAccountFeature('tech') && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.tech_permission.value}
                                onChange={handleChangeSwitch}
                                name='tech_permission'
                                color='primary'
                              />
                            }
                            label='Tech'
                          />
                        </FormGroup>
                      )}

                      {hasAccountFeature('orders') && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.orders_permission.value}
                                onChange={handleChangeSwitch}
                                name='orders_permission'
                                color='primary'
                              />
                            }
                            label='Orders'
                          />
                        </FormGroup>
                      )}

                      {hasAccountFeature('billing') && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.billing_permission.value}
                                onChange={handleChangeSwitch}
                                name='billing_permission'
                                color='primary'
                              />
                            }
                            label='Billing'
                          />
                        </FormGroup>
                      )}

                      {hasAccountFeature('finance') && (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.finance_permission.value}
                                onChange={handleChangeSwitch}
                                name='finance_permission'
                                color='primary'
                              />
                            }
                            label='Finance'
                          />
                        </FormGroup>
                      )}
                    </FormControl>
                  </CardActions>
                </Card>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  )
}

export default Form
