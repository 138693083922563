import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { create, get, update } from 'api/leasing'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LeasingRatesDialog from 'components/LeasingRatesDialog'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  orm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(3),
  },
  clearAssetLogo: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(2),
  },
  clearAssetText: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

function Form(props) {
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const { hasFeature } = useContext(FeatureToggleContext)
  const [hideContent, setHideContent] = useState(true)
  const [ratesOpen, setRatesOpen] = useState(false)
  const [values, setValues] = useState({
    name: { value: '', error: '' },
    months: { value: 36, error: '' },
    type: { value: 10, error: '' },
    value: { value: 10, error: '' },
    upfront_multiplier: { value: 0, error: '' },
    include_services: { value: 0, error: '' },
  })
  const [errors, setErrors] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const leasing = await get(id)
      const dataValues = {
        name: { value: leasing.data.name },
        months: { value: leasing.data.months },
        type: { value: leasing.data.type },
        value: { value: leasing.data.value },
        upfront_multiplier: { value: leasing.data.upfront_multiplier },
        include_services: { value: leasing.data.include_services ? 1 : 0 },
      }
      setValues(dataValues)
      setLoading(false)
      setHideContent(false)
    }

    if (id !== undefined) {
      fetchData()
    } else {
      setLoading(false)
      setHideContent(false)
    }
  }, [id, setValues, setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleOpenRatesDialog = () => {
    setRatesOpen(true)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const body = {
      name: values.name.value,
      months: values.months.value,
      type: values.type.value,
      value: values.value.value,
      upfront_multiplier: values.upfront_multiplier.value,
      include_services: values.include_services.value,
    }

    const result = id ? await update(id, body) : await create(body)
    if (result.success) {
      setLoading(false)
      props.history.push({
        pathname: '/leasing',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        {id ? 'Update Leasing' : 'Create Leasing'}
      </Typography>
      <Grid container spacing={4}>
        <Grid item md={5} xs={12}>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <Paper className={classes.paper}>
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='name'
                label='Name'
                name='name'
                defaultValue={values.name.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='months'
                label='Months'
                name='months'
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={values.months.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />
              <FormControl variant='standard' className={classes.formControl}>
                <InputLabel id='type_label'>Type</InputLabel>
                <Select
                  labelId='type_label'
                  id='type'
                  name='type'
                  value={values.type.value}
                  onChange={handleChange.bind(this)}
                  label='Type'>
                  <MenuItem value='percentage'>Percentage</MenuItem>
                  <MenuItem value='rate_per_1000'>Rate Per 1000</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='value'
                label='Value'
                name='value'
                type='number'
                step='0.01'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={values.value.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='upfront_multiplier'
                label='Profile'
                name='upfront_multiplier'
                type='number'
                step='0.01'
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={values.upfront_multiplier.value}
                autoFocus
                onChange={handleChange.bind(this)}
              />

              <FormControl variant='standard' className={classes.formControl}>
                <InputLabel id='include_services_label'>Include Services</InputLabel>
                <Select
                  labelId='include_services_label'
                  id='include_services'
                  name='include_services'
                  value={values.include_services.value}
                  onChange={handleChange.bind(this)}
                  label='Include Services'>
                  <MenuItem value='0'>No</MenuItem>
                  <MenuItem value='1'>Yes</MenuItem>
                </Select>
              </FormControl>
            </Paper>

            {errors.length > 0 && (
              <MuiAlert severity='error'>
                {errors.map(error => {
                  return <div>{error[1]}</div>
                })}
              </MuiAlert>
            )}
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              disabled={loading}
              className={classes.submit}>
              {id ? 'Update' : 'Create'}
            </Button>
          </form>
        </Grid>

        {hasFeature('clear_asset_finance') && (
          <Grid item md={5} xs={12}>
            <Paper className={classes.paper} style={{ position: 'relative' }}>
              <img
                className={classes.clearAssetLogo}
                src='/clear_asset.png'
                alt='Clear Asset Finance Logo'
                height='50'
              />

              <Typography variant='subtitle1' gutterBottom>
                Clear Asset Finance
              </Typography>

              <Typography variant='body2' className={classes.clearAssetText}>
                Clear finances a wide variety of capital equipment across all market
                sectors. There is no asset we won’t consider from tangibles to
                intangibles, even 100% software. The Clear team has a wide breadth of
                experience waiting to be utilised, all we need is you.
              </Typography>
            </Paper>

            <Button
              fullWidth
              variant='contained'
              color='default'
              onClick={handleOpenRatesDialog}
              className={classes.submit}>
              Get Rates
            </Button>
          </Grid>
        )}

        <LeasingRatesDialog open={ratesOpen} setOpen={setRatesOpen} />
      </Grid>
    </div>
  )
}

export default Form
