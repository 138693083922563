import { Button, Typography, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { libraryPost } from 'api/form-options'
import { list as listCategories } from 'api/library/categories'
import { create, listTags } from 'api/library/posts'
import PostForm from 'components/library/post/PostForm'
import { BreadcrumbContext } from 'contexts/BreadcrumbContext'
import { LoadingContext } from 'contexts/LoadingContext'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import PublishButton from 'components/library/post/PublishButton'
import { getFormData, uploadFiles } from 'helpers/library'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
  section: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateAreas: '"column-2" "column-1"',

    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(4),
      gridTemplateAreas: '"column-1 column-2"',
      gridTemplateColumns: '8fr 4fr',
    },
  },
  column1: {
    gridArea: 'column-1',
  },
  column2: {
    gridArea: 'column-2',
  },
}))

function PostCreate(props) {
  const classes = useStyles()
  const { slug } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const { addToast } = useToasts()
  const [categories, setCategories] = useState({})
  const [tags, setTags] = useState({})
  const [notify, setNotify] = useState(false)
  const [formOptions, setFormOptions] = useState(null)
  const [values, setValues] = useState({
    name: '',
    content: '',
    image: {},
    featured: '',
    youtube_id: '',
    categories: [],
    tags: [],
    files: [],
    user_has_permission: [],
  })
  const [errors, setErrors] = useState([])
  const { setBreadcrumbs } = useContext(BreadcrumbContext)
  const [scheduleOpen, setScheduleOpen] = useState(false)
  const [scheduleTime, setScheduleTime] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const [catData, tagData, formOptionData] = await Promise.all([
        listCategories(1000, 1, slug),
        listTags(slug),
        libraryPost(),
      ])
      setBreadcrumbs([
        { label: 'Home', path: '/' },
        { label: catData.meta.library.name, path: `/library/${slug}` },
        { label: 'Create Post' },
      ])
      setCategories(catData)
      setTags(tagData)
      setFormOptions(formOptionData)
      setLoading(false)
    }

    fetchData()
  }, [setLoading, setBreadcrumbs, slug])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSave = async (publish = false, notify = false) => {
    setLoading(true)

    const data = getFormData(values)
    data.set('publish', publish ? 1 : 0)
    data.set('notify', notify ? 1 : 0)
    if (scheduleTime) {
      data.set('schedule_time', scheduleTime)
    }

    const res = await create(slug, data)

    try {
      if (values.files) {
        await uploadFiles(slug, res.post.data.id, values.files)

        if (res.success) {
          addToast('Saved Successfully', { appearance: 'success' })
          props.history.push(`/library/${slug}/posts/${res.post.data.id}`)
        } else {
          setErrors(res.errors)
        }
      }
    } catch (e) {
      addToast('There was an error uploading your files', { appearance: 'error' })
    } finally {
      setLoading(false)
    }
  }

  if (!categories.data || !formOptions) {
    return null
  }

  return (
    <>
      <Box className={classes.container}>
        <Typography variant='h4' gutterBottom>
          Create Post
        </Typography>

        <PostForm
          post=''
          categories={categories}
          tags={tags}
          values={values}
          notify={notify}
          errors={errors}
          canEmbedForm={true}
          permissionOptions={formOptions.permissions}
          handleChangeNotify={e => {
            const target = e.target
            setNotify(target.checked)
          }}
          handleChange={handleChange}
        />

        <Box className={classes.section}>
          <Box className={classes.column1}>
            <Box display='flex' gridGap={'1rem'} justifyContent='end'>
              <Button
                disabled={loading}
                onClick={() => {
                  handleSave(false)
                }}
                variant='contained'
                color='primary'>
                Save draft
              </Button>
              <PublishButton
                disabled={loading}
                onClickNow={() => {
                  handleSave(true, notify)
                }}
                onClickScheduled={() => {
                  setScheduleOpen(true)
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={scheduleOpen}
        onClose={() => {
          setScheduleOpen(false)
        }}>
        <DialogTitle id='alert-dialog-title'>{'Scheduled publish'}</DialogTitle>
        <DialogContent>
          <TextField
            label='Time to publish'
            type='datetime-local'
            inputVariant='standard'
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={scheduleTime}
            onChange={e => {
              setScheduleTime(e.target.value)
            }}
          />

          <DialogActions>
            <Button
              disabled={loading}
              onClick={() => {
                handleSave(true, notify, scheduleTime)
              }}
              variant='contained'
              color='primary'>
              Publish
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PostCreate
