import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import MobileStepper from '@material-ui/core/MobileStepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { AuthContext } from 'contexts/AuthContext'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { HelpContext } from 'contexts/HelpContext'
import { LoadingContext } from 'contexts/LoadingContext'
import { QuoteContext } from 'contexts/QuoteContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomerDetailsStep, {
  save as SaveCustomerDetailsStep,
  validate as ValidateCustomerDetailsStep,
} from './steps/CustomerDetailsStep'
import ExpenditureStep, {
  save as SaveExpenditureStep,
  validate as ValidateExpenditureStep,
} from './steps/ExpenditureStep'
import HardwareStep, { save as SaveHardwareStep } from './steps/HardwareStep'
import NetworkStep, { save as SaveNetworkStep } from './steps/NetworkStep'
import NumbersStep, {
  save as SaveNumbersStep,
  validate as ValidateNumbersStep,
} from './steps/NumbersStep'
import PaymentStep, {
  save as SavePaymentStep,
  validate as ValidatePaymentStep,
} from './steps/PaymentStep'
import ProposalStep from './steps/proposal-step/ProposalStep'
import ServicesStep, {
  save as SaveServicesStep,
  validate as ValidateServicesStep,
} from './steps/ServicesStep'
import SummaryStep from './steps/SummaryStep'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '1920px',
    margin: 'auto',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    background: 'transparent',
    marginBottom: theme.spacing(6),
  },
  loader: {
    textAlign: 'center',
    marginTop: theme.spacing(8),
  },
}))

export default function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles()
  const { id, step } = useParams()
  const { hasPermission } = useContext(AuthContext)
  const { state, dispatch, hitLimits, hasLoaded } = useContext(QuoteContext)
  const { setShowHelp, setHelpType } = useContext(HelpContext)
  const { featureToggles } = useContext(FeatureToggleContext)
  const [activeStep, setActiveStep] = useState(0)
  const { loading, setLoading } = useContext(LoadingContext)

  useEffect(() => {
    if (id) {
      const stepIndex = step ? state.steps.findIndex(obj => obj.slug === step) : -1
      if (stepIndex !== -1) {
        setActiveStep(stepIndex)
      }
    }

    if (featureToggles.indexOf('quote_builder_help') !== -1) {
      setHelpType('quote-builder')
      setShowHelp(true)
    }

    setLoading(false)
    return () => {
      setShowHelp(false)
    }
  }, [setLoading, id, step, setShowHelp, setHelpType, featureToggles, state.steps])

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <CustomerDetailsStep />
      case 1:
        return <ServicesStep />
      case 2:
        return <HardwareStep />
      case 3:
        return <NetworkStep />
      case 4:
        return <NumbersStep />
      case 5:
        return <ExpenditureStep />
      case 6:
        return <PaymentStep />
      case 7:
        return <SummaryStep handleNext={handleNext} />
      case 8:
        return (
          <ProposalStep
            src={state.options.proposal_url}
            questions={state.options.proposal_questions}
          />
        )
      default:
        return 'Error'
    }
  }

  const getNextText = activeStep => {
    if (activeStep === 7) {
      return 'Generate Proposal'
    }

    return 'Save'
  }

  const getLastNext = () => {
    return state.steps.length - 1
  }

  const handleNext = async () => {
    let success = true
    setLoading(true)

    let quoteId = id
    if (activeStep === 0) {
      if (
        await ValidateCustomerDetailsStep(state.options, state.customerDetails, dispatch)
      ) {
        quoteId = await SaveCustomerDetailsStep(id, state.customerDetails)
        if (!quoteId) {
          success = false
        }
      } else {
        success = false
      }
    } else if (activeStep === 1) {
      const limit = hitLimits(
        state.services.system_type.value,
        state.services.system_users.value,
        state.services.remote_users.value,
        state.services.number_of_sip_trunks.value
      )

      if (limit && !hasPermission('manual_ucp_config')) {
        success = false
      } else {
        success =
          (await ValidateServicesStep(state.services, dispatch)) &&
          (await SaveServicesStep(id, state.services))
      }
    } else if (activeStep === 2) {
      success = await SaveHardwareStep(
        id,
        state.hardware,
        state.ucApplications,
        state.callRecording,
        state.services
      )
    } else if (activeStep === 3) {
      success = await SaveNetworkStep(
        id,
        state.networkHardware,
        state.networkServices,
        state.broadbandPackages,
        state.services.bundled_minutes.value
      )
    } else if (activeStep === 4) {
      success =
        (await ValidateNumbersStep(state.numbers, dispatch)) &&
        (await SaveNumbersStep(id, state.numbers))
    } else if (activeStep === 5) {
      success =
        (await ValidateExpenditureStep(state.expenditure, dispatch)) &&
        (await SaveExpenditureStep(id, state.expenditure))
    } else if (activeStep === 6) {
      success =
        (await ValidatePaymentStep(state.options, state.payment, dispatch)) &&
        (await SavePaymentStep(id, state.payment))
    }

    if (success && quoteId) {
      const limit = hitLimits(
        state.services.system_type.value,
        state.services.system_users.value,
        state.services.remote_users.value,
        state.services.number_of_sip_trunks.value
      )

      dispatch({ type: 'SET_MANUAL_CONFIG', payload: limit })

      const newSteps = Array.from(state.steps)
      newSteps[activeStep].completed = true
      dispatch({ type: 'SET_STEPS', payload: newSteps })

      if (limit) {
        props.history.push(`/quotes/${quoteId}/manual-config`)
      } else {
        const nextStep = activeStep + 1
        const slug = state.steps[nextStep].slug
        props.history.push(`/quotes/${quoteId}/${slug}`)
      }
    }
    setLoading(false)
  }

  const handleBack = () => {
    window.scrollTo(0, 0)
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleChangeStep = step => {
    if (id) {
      const stepIndex = step ? state.steps.findIndex(obj => obj.slug === step) : -1
      if (stepIndex !== -1) {
        props.history.push(`/quotes/${id}/${step}`)
      }
    }
  }

  const renderMobileStepper = () => {
    return (
      <Hidden mdUp>
        <MobileStepper
          only='xs'
          style={{ marginTop: '24px', marginBottom: '24px' }}
          variant='progress'
          steps={state.steps.length}
          position='static'
          activeStep={activeStep}
          className={classes.root}
          nextButton={
            <Button
              size='small'
              onClick={handleNext}
              disabled={activeStep === getLastNext() || loading}>
              {getNextText(activeStep)}
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={handleBack}
              disabled={activeStep === 0 || loading}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Hidden>
    )
  }

  if (!hasLoaded()) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
          {state.steps
            .filter(x => !x.hasOwnProperty('hide') || !x.hide)
            .map(item => (
              <Step key={item.label} completed={item.completed}>
                <StepLabel
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleChangeStep(item.slug)}>
                  {item.label}
                </StepLabel>
              </Step>
            ))}
        </Stepper>
      </Hidden>

      {renderMobileStepper()}

      <div>
        {getStepContent(activeStep)}

        <Hidden smDown>
          <div align='center'>
            <Button
              disabled={activeStep === 0 || loading}
              onClick={handleBack}
              className={classes.backButton}>
              Back
            </Button>
            {activeStep !== getLastNext() && (
              <Button
                variant='contained'
                color='primary'
                disabled={loading}
                onClick={handleNext}>
                {getNextText(activeStep)}
              </Button>
            )}
          </div>
        </Hidden>
      </div>

      {!loading && <>{renderMobileStepper()}</>}
    </div>
  )
}
