import { getFileUploadUrl, linkUploadedFiles } from 'api/library/posts'

export async function uploadFiles(slug, postId, files) {
  const filesToUpload = files.filter(f => {
    return f.file !== undefined
  })

  const urls = await Promise.all(
    filesToUpload.map(async file =>
      getFileUploadUrl(slug, postId, file.filename, file.file.type)
    )
  )

  const res = await Promise.all(
    urls.map(async (url, index) => {
      const data = {
        method: url.method,
        body: files[index].file,
      }

      if (url.headers) {
        data.headers = url.headers
      }

      return await fetch(url.url, data)
    })
  )

  await linkUploadedFiles(
    slug,
    postId,
    urls.map((u, i) => ({
      filename: filesToUpload[i].filename,
      path: u.filepath,
      size: filesToUpload[i].file.size,
      display_type: filesToUpload[i].display_type,
      folder_name: filesToUpload[i].folder_name,
    }))
  )

  return res
}

export function getFileUploadData(file, path) {
  const data = new FormData()

  data.append('files[]', file.file)
  data.append('filepaths[]', path)

  return data
}

export function getFormData(values) {
  const data = new FormData()
  data.append('name', values.name ?? '')
  data.append('content', values.content ?? '')
  data.append('image', values.image.hasOwnProperty('data') ? values.image.data : '')
  data.append('featured', values.featured ? values.featured : 0)
  data.append('youtube_id', values.youtube_id ?? '')
  data.append('form', values.form ? JSON.stringify(values.form) : '')

  values.user_has_permission?.map(x => data.append('user_has_permission[]', x))
  values.categories.map(x => data.append('categories[]', x.id))
  values.tags.map(x => data.append('tags[]', x))

  if (values.files) {
    values.files
      .filter(x => x.hasOwnProperty('file'))
      .map(x =>
        data.append(
          `new_file_display_types[]`,
          x.hasOwnProperty('display_type') ? x.display_type : 'large'
        )
      )
    values.files
      .filter(x => x.hasOwnProperty('file'))
      .map(x =>
        data.append(
          `new_file_folder_ids[]`,
          x.hasOwnProperty('folder_name') ? `${x.folder_name}` : ''
        )
      )
    values.files
      .filter(x => x.id && x.hasOwnProperty('folder_name'))
      .map(x => data.append(`file_folder_ids[${x.id}]`, `${x.folder_name}` ?? ''))
    values.files
      .filter(x => x.id)
      .map(x =>
        data.append(
          `file_display_types[${x.id}]`,
          x.hasOwnProperty('display_type') ? x.display_type : 'large'
        )
      )
    values.files
      .filter(x => x.hasOwnProperty('deleted') && x.deleted === true)
      .map(x => data.append('files_to_delete[]', x.id))
    values.files.map(x =>
      data.append(
        `file_sort_order[${x.filename}]`,
        x.hasOwnProperty('sort_order') ? `${x.sort_order}` : '0'
      )
    )
  }

  if (values.folders) {
    values.folders
      .filter(x => x.id)
      .map(x =>
        data.append(
          `folder_sort_order[${x.id}]`,
          x.hasOwnProperty('sort_order') ? ` ${x.sort_order}` : '0'
        )
      )
    values.folders.filter(x => !x.id).map(x => data.append(`folders_to_create[]`, x.name))
    values.folders
      .filter(x => x.deleted === true)
      .map(x => data.append(`folders_to_delete[]`, x.name))
  }

  return data
}
