import { sdk } from '@ipecsone/web-js-sdk'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Lottie from 'react-lottie'
import call1Animation from '../animations/call1.json'
import call2Animation from '../animations/call2.json'
import connectingAnimation from '../animations/connecting.json'
import { CircularProgress } from '@material-ui/core'

const callAnimations = [call1Animation, call2Animation]

const IpecsCallContext = React.createContext()
const IpecsCallProvider = ({ children }) => {
  const { call } = sdk

  const [minimized, setMinimized] = useState(false)
  const [connecting, setConnecting] = useState(false)
  const [ringing, setRinging] = useState(false)
  const [disconnecting, setDisconnecting] = useState(false)
  const [activeCall, setActiveCall] = useState()

  const [eventCallRejected, setEventCallRejected] = useState()
  const [eventOutgoingInvalid, setEventOutgoingInvalid] = useState()
  const [eventCallEnded, setEventCallEnded] = useState()
  const [eventOutgoingAnswered, setEventOutgoingAnswered] = useState()
  const [eventOutgoingCallEarlyMediaConnected, setEventOutgoingCallEarlyMediaConnected] =
    useState()

  const callAnimation = useRef()
  const localVideoRef = useRef()
  const remoteVideoRef = useRef()
  const remoteAudioRef = useRef()

  window.onbeforeunload = function () {
    if (connecting || ringing || activeCall) {
      return 'Leaving this page will reset the wizard'
    }
  }

  window.onunload = function () {
    handleHangup()
  }

  const handleCloseOverlay = useCallback(
    ticketToClose => {
      const { ticket, localMedia } = activeCall
      if (ticketToClose === ticket && localMedia && localMedia.stop) {
        localMedia.stop()
      }

      setActiveCall()
      setRinging(false)
      setConnecting(false)
    },
    [activeCall]
  )

  // setup event listeners
  useEffect(() => {
    call.onOutgoingCallRejected(({ ticket }) => {
      setEventCallRejected(ticket)
    })

    call.onOutgoingCallInvalidNumber(({ ticket }) => {
      setEventOutgoingInvalid(ticket)
    })

    call.onCallEnded(({ ticket }) => {
      setEventCallEnded(ticket)
    })

    call.onOutgoingCallAnswered(({ ticket }) => {
      setEventOutgoingAnswered(ticket)
    })

    call.onOutgoingCallEarlyMediaConnected(({ ticket }) => {
      setEventOutgoingCallEarlyMediaConnected(ticket)
    })
  })

  // handle rejected calls
  useEffect(() => {
    if (eventCallRejected && activeCall) {
      const { ticket } = activeCall
      if (eventCallRejected === ticket) {
        handleCloseOverlay(ticket)
        setEventCallRejected()
        alert('No one is available to take this call, please try again later')
        handleCloseOverlay()
      }
    }
  }, [eventCallRejected, activeCall, handleCloseOverlay])

  // handle outgoing invalid
  useEffect(() => {
    if (eventOutgoingInvalid && activeCall) {
      const { ticket } = activeCall
      if (eventOutgoingInvalid === ticket) {
        handleCloseOverlay(ticket)
        setEventOutgoingInvalid()
        alert('No one is available to take this call, please try again later')
      }
    }
  }, [eventOutgoingInvalid, activeCall, handleCloseOverlay])

  // handle call ended
  useEffect(() => {
    if (eventCallEnded && activeCall) {
      const { ticket } = activeCall
      if (eventCallEnded === ticket) {
        handleCloseOverlay(ticket)
        setEventCallEnded()
      }
    }
  }, [eventCallEnded, activeCall, handleCloseOverlay])

  // handle outgoing answered
  useEffect(() => {
    const handle = async () => {
      if (eventOutgoingAnswered && activeCall) {
        const { ticket } = activeCall
        if (eventOutgoingAnswered === ticket) {
          const { ticket, mediaType, localMedia, remoteMedia } = activeCall
          if (eventOutgoingAnswered === ticket) {
            setActiveCall({ ...activeCall, state: 'answered' })

            if (mediaType === 'video') {
              remoteMedia &&
                remoteMedia.connect &&
                remoteMedia.connect(remoteVideoRef.current)

              localMedia &&
                localMedia.connect &&
                localVideoRef.current &&
                localMedia.connect(localVideoRef.current)
            }

            remoteMedia &&
              remoteMedia.connect &&
              remoteMedia.connect(remoteAudioRef.current)

            try {
              await call.startKeepAliveCall(eventOutgoingAnswered)
            } catch (error) {
              console.log('startKeepAliveCall error:', error)
            }

            setEventOutgoingAnswered()
            setConnecting(false)
          }
        }
      }
    }

    handle()
  }, [eventOutgoingAnswered, activeCall, call])

  // handle outgoing call early media connected
  useEffect(() => {
    if (eventOutgoingCallEarlyMediaConnected && activeCall) {
      const { ticket, mediaType, localMedia, remoteMedia } = activeCall
      if (eventOutgoingCallEarlyMediaConnected === ticket) {
        if (mediaType === 'video') {
          remoteMedia &&
            remoteMedia.connect &&
            remoteMedia.connect(remoteVideoRef.current)

          localMedia &&
            localMedia.connect &&
            localVideoRef.current &&
            localMedia.connect(localVideoRef.current)
        }

        remoteMedia && remoteMedia.connect && remoteMedia.connect(remoteAudioRef.current)

        setEventOutgoingCallEarlyMediaConnected()
        setConnecting(false)
      }
    }
  }, [eventOutgoingCallEarlyMediaConnected, activeCall])

  const handleMakeCall = async (destinationNumber, mediaType) => {
    try {
      setConnecting(true)
      setMinimized(false)
      const callInfo = await call.makeCall({
        destinationNumber,
        mediaType: mediaType,
      })
      setActiveCall(callInfo)
      setRinging(true)

      callInfo.localMedia &&
        callInfo.localMedia.connect &&
        localVideoRef.current &&
        callInfo.localMedia.connect(localVideoRef.current)

      // set a random animation
      callAnimation.current =
        callAnimations[Math.floor(Math.random() * callAnimations.length)]

      await remoteAudioRef.current.setSinkId('default')
    } catch (e) {
      alert('Failed to make call')
      setRinging(false)
      setConnecting(false)
      handleCloseOverlay()
    }
  }

  const handleHangup = async () => {
    if (activeCall) {
      setDisconnecting(true)
      await call.hangupCall(activeCall.ticket)
      setDisconnecting(false)
      handleCloseOverlay(activeCall.ticket)
    }
  }

  const defaultContext = {
    activeCall,
    handleMakeCall,
    setMinimized,
  }
  return (
    <IpecsCallContext.Provider value={defaultContext}>
      {(connecting || activeCall) && (
        <div style={minimized ? style.minimized : style.fullScreen}>
          <div style={style.container}>
            <video
              poster=''
              style={style.remoteVideo}
              ref={remoteVideoRef}
              playsInline
              autoPlay></video>

            <video
              style={style.localVideo}
              ref={localVideoRef}
              playsInline
              autoPlay
              muted></video>

            {connecting && (
              <div style={style.infoText}>
                <div>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: connectingAnimation,
                    }}
                    height={minimized ? 60 : 400}
                    width={minimized ? 60 : 400}
                  />
                  <Text>Connecting</Text>
                </div>
              </div>
            )}

            {!connecting && activeCall && (
              <div style={style.infoText}>
                <div>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: callAnimation.current,
                    }}
                    height={minimized ? 60 : 400}
                    width={minimized ? 60 : 400}
                  />

                  <Text>Call in Progress</Text>
                </div>
              </div>
            )}

            <div style={minimized ? style.actionBarMin : style.actionBarFull}>
              <HangupButton
                loading={disconnecting}
                disabled={disconnecting}
                onClick={handleHangup}
                icon={minimized}
              />

              <ToggleSizeButton
                minimized={minimized}
                onClick={() => setMinimized(!minimized)}
              />
            </div>

            <audio ref={remoteAudioRef}></audio>

            {connecting && ringing && (
              <audio
                src='https://storage.googleapis.com/ipecs-engage-web-widget-public/ringbacktone.wav'
                autoPlay
                loop></audio>
            )}
          </div>
        </div>
      )}

      {children}
    </IpecsCallContext.Provider>
  )
}

function Text({ children }) {
  return <p style={{ marginTop: '20px', textAlign: 'center' }}>{children}</p>
}

function HangupButton({ loading, ...otherProps }) {
  const [hover, setHover] = useState(false)

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        ...style.actionBarButton,
        transition: 'all 300ms ease-in-out',
        backgroundColor: !loading && hover ? '#951a26' : '#dc3545',
        color: '#fff',
      }}
      {...otherProps}>
      {loading ? <CircularProgress /> : 'End Call'}
    </button>
  )
}

function ToggleSizeButton({ minimized, ...otherProps }) {
  const [hover, setHover] = useState(false)

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        ...style.actionBarButton,
        transition: 'all 300ms ease-in-out',
        backgroundColor: hover ? '#242424' : '#000',
        color: '#fff',
      }}
      {...otherProps}>
      {minimized ? 'Expand' : 'Minimize'}
    </button>
  )
}

function useIpecsCall() {
  return useContext(IpecsCallContext)
}

const style = {
  fullScreen: {
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000',
    color: '#fff',
    zIndex: 99999,
  },
  minimized: {
    bottom: 0,
    left: 0,
    position: 'fixed',
    width: '260px',
    height: '220px',
    backgroundColor: '#000',
    color: '#fff',
    zIndex: 99999,
    transition: 'all 150ms ease-in-out',
    borderTop: 'solid 2px #505050',
    borderRight: 'solid 2px #505050',
    borderTopRightRadius: '10px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  remoteVideo: {
    width: '100%',
    height: 'calc(100% - 100px)',
    zIndex: 2,
  },
  localVideo: {
    position: 'absolute',
    right: '20px',
    bottom: '120px',
    height: '20%',
    width: '20%',
    borderRadius: '20px',
    zIndex: 3,
  },
  infoText: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100% - 100px)',
    zIndex: 1,
  },
  actionBarFull: {
    backgroundColor: '#1d1e1f',
    border: 'solid 2px #282828',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '0 1.2rem',
    borderRadius: '20px',
    marginBottom: '20px',
    zIndex: 4,
  },
  actionBarMin: {
    height: '100px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 4,
  },
  actionBarButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexGrow: '1',
    textDecoration: 'none',
    width: '100px',
    height: '32px',
    cursor: 'pointer',
    lineHeight: '1',
    fontSize: '16px',
    transition: 'all 300ms ease-in-out',
    borderRadius: '8px',
    border: 'solid 1px #525252',
  },
  incomingAlert: {
    top: 0,
    right: 0,
    position: 'fixed',
    zIndex: 99999,
  },
}

export { IpecsCallProvider, useIpecsCall }
