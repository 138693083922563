import { Box, Grid, useTheme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { dismissGettingStarted } from 'api/auth'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useState } from 'react'
import Tour from 'reactour'

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: 'opacity 0.5s, border-color 0.5s',
    borderColor: theme.palette.success.main,
    borderStyle: 'solid',
    borderWidth: '2px',
  },
  cardContent: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  category: {
    fontSize: 14,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  tour: {
    borderRadius: '2em',
  },
}))

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 30,
    borderRadius: 50,
  },
  bar: {
    borderRadius: 50,
  },
}))(LinearProgress)

function GettingStarted() {
  const { user, setUser } = useContext(AuthContext)
  const classes = useStyles()

  const handleDismiss = index => {
    const newItems = Array.from(user.getting_started)
    newItems[index] = { ...newItems[index], dismissed: true }
    const per = Math.round(
      (newItems.filter(x => x.dismissed).length / newItems.length) * 100
    )
    // min stops routes.js taking home component redirect and stopping tour working at 100%
    setUser({
      ...user,
      getting_started: newItems,
      getting_started_percent: Math.min(per, 99),
    })
    dismissGettingStarted(newItems[index].id)
  }

  return (
    <Box mb={4}>
      <Typography variant='h4' gutterBottom>
        Getting Started
      </Typography>

      <Box mb={3}>
        <BorderLinearProgress
          variant='determinate'
          value={user.getting_started_percent < 2 ? 2 : user.getting_started_percent}
        />
      </Box>

      {user.getting_started.filter(x => !x.dismissed).length === 0 && (
        <Box mt={2} mb={2}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Typography variant='h5' className={classes.title} component='h2'>
                Congratulations!
              </Typography>
              <Typography variant='body2' component='div'>
                <p> You're all set and ready to use The Hub!</p>
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}

      <Grid container spacing={2}>
        {user.getting_started.map((x, index) => (
          <Grid key={index} item sm={12} md={12} lg={4}>
            <GettingStartedCard index={index} data={x} onDismiss={handleDismiss} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

function GettingStartedCard({ index, data, onDismiss }) {
  const classes = useStyles()
  const [dismissed, setDismissed] = useState(data.dismissed)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const theme = useTheme()

  const handleDismiss = () => {
    setDismissed(true)
    onDismiss(index)
  }

  return (
    <React.Fragment>
      <Card
        className={classes.card}
        style={{
          opacity: dismissed ? '0.6' : '1',
          borderColor: dismissed ? theme.palette.success.main : 'rgba(255,255,255,1)',
        }}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.category} color='textSecondary' gutterBottom>
            {data.category}
          </Typography>
          <Typography variant='h5' className={classes.title} component='h2'>
            {data.title} {data.dismissed}
          </Typography>
          <Typography variant='body2' component='div'>
            <div dangerouslySetInnerHTML={{ __html: data.message }} />
          </Typography>
        </CardContent>
        <Box flexGrow='1'></Box>
        {data.tour && (
          <CardActions>
            <Button size='small' onClick={handleDismiss} disabled={dismissed}>
              Dismiss
            </Button>
          </CardActions>
        )}
      </Card>

      {data.tour && (
        <Tour
          className={classes.tour}
          highlightedMaskClassName={classes.tour}
          accentColor={theme.palette.darkBlue}
          steps={data.tour.steps.map(x => {
            x.action = node => {
              node.onclick = () => {
                setIsTourOpen(false)
              }
            }
            return x
          })}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          showCloseButton={false}
          showButtons={data.tour.steps.length > 1}
          showNavigation={data.tour.steps.length > 1}
          showNumber={data.tour.steps.length > 1}
        />
      )}
    </React.Fragment>
  )
}

export default GettingStarted
