import React from 'react'
import SearchResults from './SearchResults'
import Summary from './Summary'

function Home({ data, searchResults, search, handleChangeSearch, linkPrefix, history }) {
  return (
    <React.Fragment>
      {search !== '' ? (
        <SearchResults data={searchResults} linkPrefix={linkPrefix} />
      ) : (
        <Summary data={data} history={history} linkPrefix={linkPrefix} />
      )}
    </React.Fragment>
  )
}

export default Home
