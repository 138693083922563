import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { AuthContext } from 'contexts/AuthContext'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'

const INDENT_PX = 12

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: '#FFF2',
  },
}))

function MenuItems() {
  const classes = useStyles()
  const { hasFeature } = useContext(FeatureToggleContext)
  const { user } = useContext(AuthContext)

  return (
    <>
      {user.type === 'reseller' && user.getting_started_percent < 100 && (
        <>
          <ListItem
            button
            key='Getting Started'
            component={NavLink}
            to='/getting-started'>
            <ListItemText primary='Getting Started' />{' '}
            <CircularProgressWithLabel value={user.getting_started_percent} />
          </ListItem>
          <Divider className={classes.divider} />
        </>
      )}

      {user.site_nav.map((x, i) => (
        <SiteNavItem key={`site-nav-${i}`} item={x} indent={1} />
      ))}

      {user.type === 'reseller' && hasFeature('candio_promotion') && (
        <React.Fragment>
          <Divider className={classes.divider} />

          <ListItem button key='Candio' component={NavLink} to='/candio'>
            <CandioListItemText primary='Candio' />{' '}
            <img
              height='22px'
              src='https://candio.co.uk/wp-content/uploads/cropped-Favicon-2-Candio-32x32.png'
              alt='Candio'
            />
          </ListItem>
        </React.Fragment>
      )}
    </>
  )
}

function SiteNavItem({ item, indent }) {
  switch (item.type) {
    case 'DROPDOWN':
      return <SiteNavDropDown item={item} indent={indent} />
    case 'PAGE':
      return <SiteNavPage item={item} component={NavLink} to={item.url} indent={indent} />
    case 'LIBRARY':
      return (
        <SiteNavLibrary item={item} component={NavLink} to={item.url} indent={indent} />
      )
    case 'INTERNAL_LINK':
      return (
        <SiteNavLink
          item={item}
          component={NavLink}
          to={item.type_value}
          indent={indent}
        />
      )
    case 'EXTERNAL_LINK':
      return (
        <SiteNavLink
          item={item}
          component='a'
          target='_blank'
          href={item.type_value}
          indent={indent}
        />
      )
    default:
      return null
  }
}

function SiteNavLibrary({ item, indent, ...otherProps }) {
  return (
    <ListItem button {...otherProps} style={{ paddingLeft: `${indent * INDENT_PX}px` }}>
      <ListItemText primary={item.label} />
    </ListItem>
  )
}

function SiteNavPage({ item, indent, ...otherProps }) {
  return (
    <ListItem button {...otherProps} style={{ paddingLeft: `${indent * INDENT_PX}px` }}>
      <ListItemText primary={item.label} />
    </ListItem>
  )
}

function SiteNavDropDown({ item, indent }) {
  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(!open)

  return (
    <>
      <ListItem
        id={item.div_id}
        button
        onClick={handleClick}
        style={{ paddingLeft: `${indent * INDENT_PX}px` }}>
        <ListItemText primary={item.label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {item.items.map((x, i) => (
            <SiteNavItem
              key={`site-nav-${i}`}
              item={x}
              indent={indent + 1}
              style={{ paddingLeft: '24px' }}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

function SiteNavLink({ item, indent, ...otherProps }) {
  return (
    <ListItem
      id={item.div_id}
      button
      style={{ paddingLeft: `${indent * INDENT_PX}px` }}
      {...otherProps}>
      <ListItemText primary={item.label} />
    </ListItem>
  )
}

function CircularProgressWithLabel(props) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress variant='determinate' {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'>
        <Typography
          variant='caption'
          component='div'
          color='textSecondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const CandioListItemText = withStyles(theme => ({
  primary: {
    fontFamily: '"Montserrat", sans-serif',
  },
}))(ListItemText)

export default MenuItems
