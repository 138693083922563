import { IconButton } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import LessIcon from '@material-ui/icons/ChevronLeft'
import MoreIcon from '@material-ui/icons/ChevronRight'
import ViewResellerIcon from '@material-ui/icons/ArrowForward'
import UndoIcon from '@material-ui/icons/Undo'
import MuiAlert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  deleteQuote,
  list,
  setLost as apiSetLost,
  setOpen as apiSetOpen,
  setWon as apiSetWon,
} from 'api/quotes'
import logo from 'assets/QuickQuote50.png'
import TableSearchBox from 'components/TableSearchBox'
import TableToolbar from 'components/TableToolbar'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import { getPence, getPounds } from 'helpers/currency'
import debounce from 'lodash.debounce'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import DeleteDialog from './DeleteDialog'
import LostDialog from './LostDialog'
import OpenDialog from './OpenDialog'
import QuoteOptions from './QuoteOptions'
import WonDialog from './WonDialog'
import TableIcon from 'components/TableIcon'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  pagination: {
    marginBottom: '6MuiIconButton-roo0px',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  fabShift: {
    bottom: 90,
  },
  toggle: {
    marginLeft: theme.spacing(3),
  },
  filterSalesAgent: {
    width: 300,
  },
  moreActions: {
    overflow: 'hidden',
    transition: 'all 0.2s ease-in-out',
  },
}))

function List(props) {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const { user } = useContext(AuthContext)
  const [quotes, setQuotes] = useState({})
  const [wonOpen, setWonOpen] = useState(false)
  const [lostOpen, setLostOpen] = useState(false)
  const [openOpen, setOpenOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [targetId, setTargetId] = useState('')
  const [hideContent, setHideContent] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [filters, setFilters] = useState({
    search: '',
    status: '',
  })
  const [moreActions, setMoreActions] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const data = await list()
      setQuotes(data)
      setLoading(false)
      setHideContent(false)
    }

    if (props.location.state) {
      setSnackbarSeverity(props.location.state.severity)
      setSnackbarMessage(props.location.state.message)
      setSnackbarOpen(true)
      props.history.replace()
    }

    fetchData()
  }, [setLoading, props.location, props.history])

  const handleChangeStatusFilter = async event => {
    setLoading(true)
    const value = event.target.value
    const newFilters = { ...filters, status: value === 'all' ? '' : value }
    setFilters(newFilters)
    setQuotes(await list(parseInt(quotes.meta.per_page), 1, newFilters))
    setLoading(false)
  }

  const handleChangeSalesAgentFilter = async value => {
    setLoading(true)
    const newFilters = { ...filters, user_id: value === null ? '' : value.id }
    setFilters(newFilters)
    setQuotes(await list(parseInt(quotes.meta.per_page), 1, newFilters))
    setLoading(false)
  }

  const handleChangeSearchFilter = async event => {
    const newFilters = { ...filters, search: event.target.value }
    setFilters(newFilters)
    debouncedSearch(parseInt(quotes.meta.per_page), newFilters)
  }

  const debouncedSearch = useRef(
    debounce(async (perPage, newFilters) => {
      setLoading(true)
      setQuotes(await list(perPage, 1, newFilters))
      setLoading(false)
    }, 1000)
  ).current

  const handleChangePage = async (event, newPage) => {
    setLoading(true)
    setQuotes(await list(parseInt(quotes.meta.per_page), parseInt(newPage + 1), filters))
    setLoading(false)
  }

  const handleChangeRowsPerPage = async event => {
    setLoading(true)
    setQuotes(await list(parseInt(event.target.value), 1, filters))
    setLoading(false)
  }

  const handleOpenWonDialog = id => {
    setTargetId(id)
    setWonOpen(true)
  }

  const handleCloseWonDialog = () => {
    setWonOpen(false)
  }

  const handleWon = async id => {
    setLoading(true)
    const success = await apiSetWon(id)

    if (success) {
      setQuotes(await list(parseInt(quotes.meta.per_page), 1, filters))
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully set quote as won')
    } else {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to set quote as won')
    }

    setWonOpen(false)
    setLoading(false)
    setSnackbarOpen(true)
  }

  const handleOpenLostDialog = id => {
    setTargetId(id)
    setLostOpen(true)
  }

  const handleCloseLostDialog = () => {
    setLostOpen(false)
  }

  const handleOpenOpenDialog = id => {
    setTargetId(id)
    setOpenOpen(true)
  }

  const handleCloseOpenDialog = () => {
    setOpenOpen(false)
  }

  const handleOpen = async id => {
    setLoading(true)
    const success = await apiSetOpen(id)

    if (success) {
      setQuotes(await list(parseInt(quotes.meta.per_page), 1, filters))
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully set quote as open')
    } else {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to set quote as open')
    }

    setOpenOpen(false)
    setLoading(false)
    setSnackbarOpen(true)
  }

  const handleLost = async id => {
    setLoading(true)
    const success = await apiSetLost(id)

    if (success) {
      setQuotes(await list(parseInt(quotes.meta.per_page), 1, filters))
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully set quote as lost')
    } else {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to set quote as lost')
    }

    setLostOpen(false)
    setLoading(false)
    setSnackbarOpen(true)
  }

  const handleOpenDeleteDialog = id => {
    setTargetId(id)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async id => {
    setLoading(true)
    const success = await deleteQuote(id)

    if (success) {
      setQuotes(await list(parseInt(quotes.meta.per_page), 1, filters))
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully deleted quote')
    } else {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to delete quote')
    }

    setDeleteOpen(false)
    setLoading(false)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <div style={{ height: '90px', display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt='QuickQuote' />
        {user.role === 'admin' && user.type === 'reseller' && (
          <div style={{ display: 'inline-block', marginTop: '12px' }}>
            <QuoteOptions initialValues={quotes.meta.settings} />
          </div>
        )}
      </div>
      <TableContainer component={Paper}>
        <TableToolbar>
          <TableSearchBox
            label='Search by deal name'
            handleChange={handleChangeSearchFilter}
          />

          {quotes.meta.sales_agents.length > 0 && (
            <Autocomplete
              className={classes.filterSalesAgent}
              id='sales-agent-filter'
              options={quotes.meta.sales_agents}
              getOptionLabel={option => `${option.first_name} ${option.last_name}`}
              onChange={(_, newValue) => {
                handleChangeSalesAgentFilter(newValue)
              }}
              renderInput={params => (
                <TextField {...params} label='Sales Agent' variant='outlined' />
              )}
            />
          )}

          <Select
            id='status'
            variant='outlined'
            disabled={loading}
            onChange={handleChangeStatusFilter}
            defaultValue='all'
            name='status'>
            <MenuItem value='all'>All Quotes</MenuItem>
            <MenuItem value='won'>Won Quotes</MenuItem>
            <MenuItem value='lost'>Lost Quotes</MenuItem>
          </Select>
        </TableToolbar>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              {quotes.data[0]?.hasOwnProperty('user_name') && (
                <TableCell>Sales Agent</TableCell>
              )}
              <TableCell>Deal Name</TableCell>
              <TableCell>Business Name</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Won/Lost</TableCell>
              {quotes.data[0]?.hasOwnProperty('user_name') && (
                <TableCell>Total Value</TableCell>
              )}
              <TableCell>Contract Length</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quotes.data.map(row => (
              <TableRow key={row.id}>
                {row.hasOwnProperty('user_name') && (
                  <TableCell scope='row'>{row.user_name}</TableCell>
                )}
                <TableCell scope='row'>{row.deal_name}</TableCell>
                <TableCell scope='row'>{row.business_name}</TableCell>
                <TableCell scope='row'>
                  {row.first_name} {row.last_name}
                </TableCell>
                <TableCell scope='row'>{row.created_at}</TableCell>
                <TableCell scope='row'>
                  {['won', 'lost'].includes(row.status) &&
                    row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                </TableCell>
                {row.hasOwnProperty('total_value') && (
                  <TableCell scope='row'>
                    £{getPounds(row.total_value)}.{getPence(row.total_value)}
                  </TableCell>
                )}
                <TableCell scope='row'>{row.contract_length}</TableCell>
                {user.type === 'distributor' && (
                  <TableCell style={{ justifyContent: 'flex-end' }}>
                    <Tooltip
                      placement='top'
                      title={<React.Fragment>View Reseller Users</React.Fragment>}>
                      <IconButton
                        className={classes.button}
                        aria-label='view reseller'
                        color='primary'
                        component={NavLink}
                        to={`/resellers/${row.reseller.id}/users`}>
                        <ViewResellerIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
                {user.type === 'reseller' && (
                  <TableCell style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip
                      placement='top'
                      title={<React.Fragment>View</React.Fragment>}>
                      <IconButton
                        className={classes.button}
                        aria-label='edit'
                        color='primary'
                        component={NavLink}
                        to={`/quotes/${row.id}`}>
                        <TableIcon icon='view' color='blue' />
                      </IconButton>
                    </Tooltip>
                    <div
                      className={classes.moreActions}
                      style={{
                        width: moreActions ? '160px' : '0px',
                      }}>
                      <div style={{ display: 'flex' }}>
                        {row.status === 'created' ? (
                          <React.Fragment>
                            <Tooltip
                              placement='top'
                              title={<React.Fragment>Set quote as won</React.Fragment>}>
                              <IconButton
                                className={classes.button}
                                aria-label='won'
                                color='default'
                                onClick={() => handleOpenWonDialog(row.id)}>
                                <TableIcon icon='tick' color='green' />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              placement='top'
                              title={<React.Fragment>Set quote as lost</React.Fragment>}>
                              <IconButton
                                className={classes.button}
                                disabled={row.status === 'lost'}
                                aria-label='lost'
                                color='default'
                                onClick={() => handleOpenLostDialog(row.id)}>
                                <TableIcon icon='cross' color='red' />
                              </IconButton>
                            </Tooltip>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <IconButton style={{ visibility: 'hidden' }}>
                              <UndoIcon />
                            </IconButton>

                            <Tooltip
                              placement='top'
                              title={<React.Fragment>Set quote as open</React.Fragment>}>
                              <IconButton
                                className={classes.button}
                                aria-label='open'
                                color='default'
                                onClick={() => handleOpenOpenDialog(row.id)}>
                                <UndoIcon />
                              </IconButton>
                            </Tooltip>
                            <div></div>
                          </React.Fragment>
                        )}
                        <Tooltip
                          placement='top'
                          title={<React.Fragment>Delete</React.Fragment>}>
                          <IconButton
                            className={classes.button}
                            aria-label='delete'
                            color='secondary'
                            onClick={() => handleOpenDeleteDialog(row.id)}>
                            <TableIcon icon='delete' />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>

                    <Tooltip
                      placement='top'
                      title={
                        <React.Fragment>
                          {moreActions ? 'Less options' : 'More options'}
                        </React.Fragment>
                      }>
                      <IconButton
                        className={classes.button}
                        aria-label='more'
                        color='default'
                        onClick={() => setMoreActions(!moreActions)}>
                        {moreActions ? <LessIcon /> : <MoreIcon />}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 15, 25]}
        component='div'
        count={quotes.meta.total}
        rowsPerPage={parseInt(quotes.meta.per_page)}
        page={quotes.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <WonDialog
        loading={loading}
        quoteId={targetId}
        open={wonOpen}
        handleClose={handleCloseWonDialog}
        handleSubmit={handleWon}
      />

      <LostDialog
        loading={loading}
        quoteId={targetId}
        open={lostOpen}
        handleClose={handleCloseLostDialog}
        handleSubmit={handleLost}
      />

      <OpenDialog
        loading={loading}
        quoteId={targetId}
        open={openOpen}
        handleClose={handleCloseOpenDialog}
        handleSubmit={handleOpen}
      />

      <DeleteDialog
        loading={loading}
        quoteId={targetId}
        open={deleteOpen}
        handleClose={handleCloseDeleteDialog}
        handleSubmit={handleDelete}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Fab
        className={`${classes.fab} ${user.type === 'reseller' && classes.fabShift}`}
        color='secondary'
        aria-label='add'
        component={NavLink}
        to='/quotes/create'>
        <AddIcon />
      </Fab>
    </div>
  )
}

export default List
