import { makeStyles } from '@material-ui/core/styles'
import { ContentState, convertToRaw } from 'draft-js'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'

const useStyles = makeStyles(theme => ({
  editor: {
    height: 'auto',
  },
}))

function PostContentEditor({ name, value, onChange, placeHolder }) {
  const [initialContentState, setInitialContentState] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    try {
      // is this valid "raw" draft-js content?
      setInitialContentState(JSON.parse(value))
    } catch (e) {
      setInitialContentState(convertToRaw(ContentState.createFromText(value)))
    }
  }, [value])

  const handleContentStateChange = state => {
    onChange({
      target: { name: name, value: state },
    })
  }

  return (
    initialContentState && (
      <Editor
        initialContentState={initialContentState}
        placeholder={placeHolder}
        onContentStateChange={handleContentStateChange}
        editorClassName={classes.editor}></Editor>
    )
  )
}

export default PostContentEditor
