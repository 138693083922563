import DateFnsUtils from '@date-io/date-fns'
import red from '@material-ui/core/colors/red'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import background from 'assets/background.webp'
import ScrollToTop from 'components/ScrollToTop'
import config from 'config'
import { IpecsCallProvider } from 'contexts/IpecsCallContext'
import NotificationContext from 'contexts/NotificationContext'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import App from './App'
import AuthContext from './contexts/AuthContext'
import FeatureTogglesContext from './contexts/FeatureToggleContext'
import HelpContext from './contexts/HelpContext'
import LoadingContext from './contexts/LoadingContext'
import MainNavContext from './contexts/MainNavContext'
import ViewContext from './contexts/ViewContext'
import './i18n'
import './index.css'
import * as serviceWorker from './serviceWorker'
import TagManager from 'react-gtm-module'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createIDBPersister } from './IDBPersister'

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  })
}

if (config.GOOGLE_TAG_MANAGER_ID) {
  const tagManagerArgs = {
    gtmId: config.GOOGLE_TAG_MANAGER_ID,
  }

  TagManager.initialize(tagManagerArgs)
}

const theme = createTheme({
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#50ACE2',
    },
    success: {
      main: '#50E293',
    },
    danger: {
      main: '#FF3E80',
    },
    secondary: red,
    lightBlue: '#A8D6F1',
    darkBlue: '#50ACE2',
    muted: '#b3b3b3',
    white: '#fff',
    lightBackground: 'rgba(255,255,255,0.8)',
    lightText: '#444',
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: '2em',
      },
    },
    MuiAccordion: {
      root: {
        borderRadius: '2em',
        '&:before': {
          content: 'none',
        },
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
        marginBottom: '2em',
      },
      rounded: {
        '&:first-child': {
          borderTopLeftRadius: '2em',
          borderTopRightRadius: '2em',
        },
        '&:last-child': {
          borderBottomLeftRadius: '2em',
          borderBottomRightRadius: '2em',
        },
        borderRadius: '2em',
      },
    },
    MuiChip: {
      colorPrimary: {
        color: '#FFF',
      },
    },
    MuiButtonGroup: { contained: { borderRadius: '2em' } },
    MuiButton: {
      containedPrimary: {
        color: '#FFF',
      },
      outlinedPrimary: {
        color: '#FFF',
        borderColor: '#FFF',
        '&:hover': {
          color: '#A8D6F1',
        },
      },
      root: {
        borderRadius: '2em',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: '2em',
        backgroundColor: 'rgba(255,255,255,0.8)',
      },
      rounded: {
        borderRadius: '2em',
      },
    },
    MuiOutlinedInput: {
      root: {
        background: '#fff',
        borderRadius: '2em',
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
        border: 'none',
      },
      input: {
        padding: '0.5em 1em',
      },
      notchedOutline: {
        border: 'none',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&.MuiOutlinedInput-root': {
          padding: '0.5em',
          '& > .MuiAutocomplete-input': {
            padding: '0!important',
          },
        },
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: '0px',
        left: 'calc(-50%)',
        right: 'calc(50%)',
        position: 'absolute',
      },
      lineHorizontal: {
        borderTopStyle: 'none',
        backgroundColor: '#fff',
        height: '24px',
        zIndex: '-1',
        position: 'relative',
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundImage: `url(${background})`,
          backgroundAttachment: 'fixed',
        },
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.21em',
      },
      h4: {
        marginBottom: '0.21em',
        fontWeight: 400,
      },
      h5: {
        fontSize: '1.3em',
        fontWeight: 400,
      },
      h6: {
        fontSize: '1.2em',
        fontWeight: 400,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(20px, 10px) scale(1)',
      },
      root: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '80%',
        height: '100%',
      },
      shrink: {
        maxWidth: '100%',
      },
    },
    MuiTable: {
      root: {
        width: 'calc(100% - 4em)',
        margin: 'auto',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        padding: '0.5em',
      },
      head: {
        borderBottom: '1px solid #707070',
        color: '#50ACE2',
        fontWeight: 700,
      },
    },
  },
})

const muiAlertToast = ({ appearance, children }) => (
  <MuiAlert
    style={{ marginBottom: '12px' }}
    elevation={6}
    variant='filled'
    severity={appearance}>
    {children}
  </MuiAlert>
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5000, // 5 seconds
      cacheTime: 1000 * 60 * 60 * 24 * 5, // 5 days, this should be higher than maxAge on persistent cache
    },
  },
})

const persister = createIDBPersister('pragmahub')

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <LoadingContext>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister: persister,
            maxAge: 1000 * 60 * 60 * 24 * 5, // 3 days
            hydrateOptions: {},
            dehydrateOptions: {
              shouldDehydrateQuery: ({ queryKey, state }) => {
                return state.status === 'success'
              },
            },
          }}>
          <AuthContext>
            <Sentry.ErrorBoundary fallback={'An error has occurred'}>
              <MainNavContext>
                <FeatureTogglesContext>
                  <IpecsCallProvider>
                    <ToastProvider
                      components={{ Toast: muiAlertToast }}
                      autoDismiss={true}
                      placement='bottom-center'>
                      <HelpContext>
                        <ViewContext>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <NotificationContext>
                              <App />
                            </NotificationContext>
                          </MuiPickersUtilsProvider>
                        </ViewContext>
                      </HelpContext>
                    </ToastProvider>
                  </IpecsCallProvider>
                </FeatureTogglesContext>
              </MainNavContext>
            </Sentry.ErrorBoundary>
          </AuthContext>
        </PersistQueryClientProvider>
      </LoadingContext>
    </ThemeProvider>
    <ScrollToTop />
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
