import { Button, TextField } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { list as listCategories } from 'api/library/categories'
import {
  deletePost,
  listTags,
  update as updatePost,
  get as getPost,
} from 'api/library/posts'
import PostForm from 'components/library/post/PostForm'
import { LoadingContext } from 'contexts/LoadingContext'
import { getFormData, uploadFiles } from 'helpers/library'
import { useContext, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useParams } from 'react-router-dom'
import { libraryPost } from 'api/form-options'
import PublishButton from 'components/library/post/PublishButton'

const useStyles = makeStyles(theme => ({
  section: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateAreas: '"column-2" "column-1"',

    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(4),
      gridTemplateAreas: '"column-1 column-2"',
      gridTemplateColumns: '8fr 4fr',
    },
  },
  column1: {
    gridArea: 'column-1',
  },
  column2: {
    gridArea: 'column-2',
  },
}))

function PostEdit({ post, setPost, toggleEditMode, history }) {
  const classes = useStyles()
  const { slug } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const { addToast } = useToasts()
  const [categories, setCategories] = useState({})
  const [tags, setTags] = useState({})
  const [values, setValues] = useState(post.data)
  const [errors, setErrors] = useState([])
  const [notify, setNotify] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [formOptions, setFormOptions] = useState(null)
  const [scheduleOpen, setScheduleOpen] = useState(false)
  const [scheduleTime, setScheduleTime] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const [catData, tagData, formOptionData] = await Promise.all([
        listCategories(1000, 1, slug),
        listTags(slug),
        libraryPost(),
      ])
      setCategories(catData)
      setTags(tagData)
      setFormOptions(formOptionData)

      setLoading(false)
    }

    fetchData()
  }, [setLoading, slug])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSave = async (publish = false, notify = false) => {
    setLoading(true)

    const data = getFormData(values)
    data.set('publish', publish ? 1 : 0)
    data.set('notify', notify ? 1 : 0)

    if (scheduleTime) {
      data.set('schedule_time', scheduleTime)
    }

    const res = await updatePost(slug, post.data.id, data)

    try {
      if (values.files) {
        await uploadFiles(slug, res.post.data.id, values.files)
        if (res.success) {
          setPost(await getPost(post.data.id))
          addToast('Saved Successfully', { appearance: 'success' })
          toggleEditMode()
        } else {
          setErrors(res.errors)
        }
      }
    } catch (e) {
      addToast('There was an error uploading your files', { appearance: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    setLoading(true)
    const res = await deletePost(slug, post.data.id)
    setLoading(false)

    if (res) {
      addToast('Deleted Successfully', { appearance: 'success' })
      history.push(`/library/${slug}`)
    } else {
      setErrors([['', 'Error deleting post']])
      setDeleteOpen(false)
    }
  }

  const handleOpenDeleteDialog = id => {
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  if (!categories.data || !formOptions) {
    return null
  }

  return (
    <Box>
      <PostForm
        post={post}
        categories={categories}
        tags={tags}
        values={values}
        errors={errors}
        notify={notify}
        permissionOptions={formOptions.permissions}
        canEmbedForm={true}
        handleChangeNotify={e => {
          const target = e.target
          setNotify(target.checked)
        }}
        handleChange={handleChange}
      />

      <Box className={classes.section}>
        <Box className={classes.column1}>
          <Box display='flex' gridGap={'1rem'} justifyContent='end'>
            {post.data.published ? (
              <>
                <Button
                  disabled={loading}
                  onClick={() => {
                    handleSave(false)
                  }}
                  variant='contained'
                  color='default'>
                  Switch to draft
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => {
                    handleSave(true)
                  }}
                  variant='contained'
                  color='primary'>
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={loading}
                  onClick={() => {
                    handleSave(false)
                  }}
                  variant='contained'
                  color='primary'>
                  Save draft
                </Button>
                <PublishButton
                  disabled={loading}
                  onClickNow={() => {
                    handleSave(true, notify)
                  }}
                  onClickScheduled={() => {
                    setScheduleOpen(true)
                  }}
                />
              </>
            )}

            <Button
              disabled={loading}
              onClick={handleOpenDeleteDialog}
              variant='contained'
              color='secondary'>
              Delete
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={scheduleOpen}
        onClose={() => {
          setScheduleOpen(false)
        }}>
        <DialogTitle id='alert-dialog-title'>{'Scheduled publish'}</DialogTitle>
        <DialogContent>
          <TextField
            label='Time to publish'
            type='datetime-local'
            inputVariant='standard'
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={scheduleTime}
            onChange={e => {
              setScheduleTime(e.target.value)
            }}
          />

          <DialogActions>
            <Button
              disabled={loading}
              onClick={() => {
                handleSave(true, notify, scheduleTime)
              }}
              variant='contained'
              color='primary'>
              Publish
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='alert-dialog-title'>{'Library Post Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this library post?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleDelete} color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default PostEdit
