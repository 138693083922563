import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { useState } from 'react'

function SiteNavFormModal({
  open,
  type,
  label,
  typeValue,
  permissions,
  formOptions,
  loading,
  allowChangeType = true,
  onChange,
  onSubmit,
  onClose,
}) {
  const [error, setError] = useState('')

  const handleSubmit = e => {
    if (!type || !type || (type !== 'DROPDOWN' && !typeValue)) {
      setError('Please complete all required fields')
    } else {
      setError('')
      onSubmit(e)
    }
  }

  const typeLabelMap = {
    EXTERNAL_LINK: 'URL',
    LIBRARY: 'Please select library',
    PAGE: 'Please select page',
  }

  const handleChangePermissions = e => {
    const { name, checked } = e.target

    onChange({
      target: {
        name: 'permissions',
        value: checked ? [...permissions, name] : permissions.filter(p => p !== name),
      },
    })
  }

  return (
    formOptions && (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogContent>
          <Box display='flex' flexDirection='column'>
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='label'
              label='Label'
              name='label'
              defaultValue={label}
              autoFocus
              onChange={onChange}
            />

            <FormControl>
              <InputLabel id='type_input_label'>Type</InputLabel>
              <Select
                id='type'
                name='type'
                required
                fullWidth
                value={type}
                onChange={onChange}
                disabled={!allowChangeType}
                labelId='type_input_label'>
                {formOptions.types.map(o => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {type === 'LIBRARY' || type === 'PAGE' ? (
              <FormControl>
                <InputLabel id='type_value_input_label'>
                  {typeLabelMap[type] ?? 'Value'}
                </InputLabel>
                <Select
                  id='type_value'
                  name='type_value'
                  required
                  value={typeValue}
                  onChange={onChange}
                  fullWidth
                  labelId='type_value_input_label'>
                  <MenuItem value={``}>Please select</MenuItem>
                  {formOptions.type_values[type].map(o => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              type === 'EXTERNAL_LINK' && (
                <TextField
                  variant='standard'
                  margin='normal'
                  required
                  fullWidth
                  id='type_value'
                  label={typeLabelMap[type] ?? 'Type value'}
                  name='type_value'
                  defaultValue={typeValue}
                  autoFocus
                  onChange={onChange}
                />
              )
            )}

            <FormControl component='fieldset' margin='normal'>
              <FormLabel component='legend'>Required permission(s)</FormLabel>

              <FormGroup row>
                {formOptions.permissions.map(o => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        checked={permissions.find(p => p === o.value) ? true : false}
                        onChange={handleChangePermissions}
                        name={o.value}
                      />
                    }
                    key={o.value}
                    label={o.label}></FormControlLabel>
                ))}
              </FormGroup>
              {permissions.length === 0 && (
                <MuiAlert severity='warning'>
                  This menu item will be visible to all users
                </MuiAlert>
              )}
            </FormControl>

            {error && <MuiAlert severity='error'>{error}</MuiAlert>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={loading && type !== null}
            onClick={handleSubmit}
            color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}

SiteNavFormModal.propTypes = {
  id: PropTypes.number,
  afterSubmit: PropTypes.func,
}

export default SiteNavFormModal
