import config from 'config'
import { getJsonHeaders, getQueryStringFromFiltersObject } from './helpers'

export async function list(limit = 15, page = 1, filters = {}) {
  try {
    let queryString = `?per_page=${limit}&page=${page}`
    const filterString = getQueryStringFromFiltersObject(filters)

    if (filterString) {
      queryString += `&${filterString}`
    }

    const result = await fetch(config.API_URL + '/api/site-navs' + queryString)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function get(id) {
  try {
    const result = await fetch(config.API_URL + `/api/site-navs/${id}`)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function create(body) {
  try {
    const res = await fetch(config.API_URL + `/api/site-navs`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 201) {
      const decoded = await res.json()
      return {
        data: decoded.data.id,
        success: true,
        message: 'Successfully created navigation item',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to create navigation item',
    }
  }
}

export async function update(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/site-navs/${id}`, {
      method: 'PUT',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated navigation item',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update navigation item',
      errors: [['', 'Failed to update navigation item']],
    }
  }
}

export async function setParent(id, parentId) {
  try {
    const res = await fetch(config.API_URL + `/api/site-navs/${id}/set-parent`, {
      method: 'PUT',
      headers: await getJsonHeaders(),
      body: JSON.stringify({ parent_id: parentId }),
    })

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated navigation item',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update navigation item',
      errors: [['', 'Failed to update navigation item']],
    }
  }
}

export async function sort(sort_orders) {
  try {
    const res = await fetch(config.API_URL + `/api/site-navs/sort`, {
      method: 'PUT',
      headers: await getJsonHeaders(),
      body: JSON.stringify({ sort_orders }),
    })

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully reordered menu items',
      }
    }

    const decoded = await res.json()
    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to reorder menu items',
      errors: [['', 'Failed to reorder menu items']],
    }
  }
}

export async function deleteSiteNav(id) {
  try {
    const res = await fetch(config.API_URL + `/api/site-navs/${id}`, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}
