import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiAlert from '@material-ui/lab/Alert'
import { create, get, update } from 'api/products/broadband-packages'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  orm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(3),
  },
}))

function Form(props) {
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const queryClient = useQueryClient()
  const [hideContent, setHideContent] = useState(true)
  const [values, setValues] = useState({
    name: { value: '', error: '' },
    product_code: { value: '', error: '' },
    description: { value: '', error: '' },
    upfront_cost_price: { value: '', error: '' },
    monthly_cost_price: { value: '', error: '' },
    upfront_rrp: { value: '', error: '' },
    monthly_rrp: { value: '', error: '' },
    new_install_upfront_cost_price: { value: '', error: '' },
    new_install_monthly_cost_price: { value: '', error: '' },
    new_install_upfront_rrp: { value: '', error: '' },
    new_install_monthly_rrp: { value: '', error: '' },
    migration_upfront_cost_price: { value: '', error: '' },
    migration_monthly_cost_price: { value: '', error: '' },
    migration_upfront_rrp: { value: '', error: '' },
    migration_monthly_rrp: { value: '', error: '' },
    interop_url: { value: '', error: '' },
  })
  const [errors, setErrors] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const product = await get(id)
      const dataValues = {
        name: { value: product.data.name },
        product_code: { value: product.data.product_code },
        description: { value: product.data.description },
        upfront_cost_price: { value: getDisplayPrice(product.data.upfront_cost_price) },
        monthly_cost_price: { value: getDisplayPrice(product.data.monthly_cost_price) },
        upfront_rrp: { value: getDisplayPrice(product.data.upfront_rrp) },
        monthly_rrp: { value: getDisplayPrice(product.data.monthly_rrp) },
        new_install_upfront_cost_price: {
          value: getDisplayPrice(product.data.new_install_upfront_cost_price),
        },
        new_install_monthly_cost_price: {
          value: getDisplayPrice(product.data.new_install_monthly_cost_price),
        },
        new_install_upfront_rrp: {
          value: getDisplayPrice(product.data.new_install_upfront_rrp),
        },
        new_install_monthly_rrp: {
          value: getDisplayPrice(product.data.new_install_monthly_rrp),
        },
        migration_upfront_cost_price: {
          value: getDisplayPrice(product.data.migration_upfront_cost_price),
        },
        migration_monthly_cost_price: {
          value: getDisplayPrice(product.data.migration_monthly_cost_price),
        },
        migration_upfront_rrp: {
          value: getDisplayPrice(product.data.migration_upfront_rrp),
        },
        migration_monthly_rrp: {
          value: getDisplayPrice(product.data.migration_monthly_rrp),
        },
        interop_url: { value: product.data.interop_url },
      }
      setValues(dataValues)
      setLoading(false)
      setHideContent(false)
    }

    if (id !== undefined) {
      fetchData()
    } else {
      setLoading(false)
      setHideContent(false)
    }
  }, [id, setValues, setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const body = {
      name: values.name.value,
      product_code: values.product_code.value,
      description: values.description.value,
      upfront_cost_price: values.upfront_cost_price.value * 100,
      monthly_cost_price: values.monthly_cost_price.value * 100,
      upfront_rrp: values.upfront_rrp.value * 100,
      monthly_rrp: values.monthly_rrp.value * 100,
      new_install_upfront_cost_price: values.new_install_upfront_cost_price.value * 100,
      new_install_monthly_cost_price: values.new_install_monthly_cost_price.value * 100,
      new_install_upfront_rrp: values.new_install_upfront_rrp.value * 100,
      new_install_monthly_rrp: values.new_install_monthly_rrp.value * 100,
      migration_upfront_cost_price: values.migration_upfront_cost_price.value * 100,
      migration_monthly_cost_price: values.migration_monthly_cost_price.value * 100,
      migration_upfront_rrp: values.migration_upfront_rrp.value * 100,
      migration_monthly_rrp: values.migration_monthly_rrp.value * 100,
      interop_url: values.interop_url.value,
    }

    const result = id ? await update(id, body) : await create(body)
    if (result.success) {
      setLoading(false)
      queryClient.removeQueries('broadband-packages-products')
      props.history.push({
        pathname: '/products/connectivity',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const getDisplayPrice = price => {
    if (price === 0) {
      return price.toFixed(2)
    } else if (price > 0) {
      return (price / 100).toFixed(2)
    }

    return ''
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        {id ? 'Update Connectivity' : 'Create Connectivity'}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={5}>
            <Paper className={classes.paper}>
              <TextField
                variant='standard'
                margin='normal'
                required
                fullWidth
                id='name'
                label='Name'
                name='name'
                defaultValue={values.name.value}
                autoFocus
                onChange={handleChange}
              />
              <TextField
                variant='standard'
                margin='normal'
                fullWidth
                id='product_code'
                label='Product Code'
                name='product_code'
                defaultValue={values.product_code.value}
                onChange={handleChange}
              />
              <TextField
                variant='standard'
                margin='normal'
                fullWidth
                id='description'
                label='Description'
                name='description'
                defaultValue={values.description.value}
                onChange={handleChange}
                multiline
                rows={4}
              />

              <TextField
                variant='standard'
                margin='normal'
                fullWidth
                id='interop_url'
                label='Interop Checker URL'
                name='interop_url'
                defaultValue={values.interop_url.value}
                onChange={handleChange}
              />
            </Paper>

            {errors.length > 0 && (
              <MuiAlert severity='error'>
                {errors.map(error => {
                  return <div>{error[1]}</div>
                })}
              </MuiAlert>
            )}
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={loading}
              className={classes.submit}>
              {id ? 'Update' : 'Create'}
            </Button>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Base Pricing</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Pricing values={values} handleChange={handleChange} prefix='' />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>New Install Additional Pricing</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Typography variant='caption'>
                    These values are added to the base price to to calculator the total
                    cost and sell prices
                  </Typography>

                  <Pricing
                    values={values}
                    handleChange={handleChange}
                    prefix='new_install_'
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Migration Additional Pricing</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Typography variant='caption'>
                    These values are added to the base price to to calculator the total
                    cost and sell prices
                  </Typography>

                  <Pricing
                    values={values}
                    handleChange={handleChange}
                    prefix='migration_'
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

function Pricing({ values, handleChange, prefix }) {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          variant='standard'
          margin='normal'
          required
          fullWidth
          type='number'
          step='0.01'
          InputLabelProps={{
            shrink: true,
          }}
          id={`${prefix}upfront_cost_price`}
          label={t('Upfront Cost')}
          name={`${prefix}upfront_cost_price`}
          defaultValue={values[`${prefix}upfront_cost_price`].value}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant='standard'
          margin='normal'
          required
          fullWidth
          type='number'
          step='0.01'
          id={`${prefix}monthly_cost_price`}
          label={t('Monthly Cost')}
          name={`${prefix}monthly_cost_price`}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={values[`${prefix}monthly_cost_price`].value}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant='standard'
          margin='normal'
          required
          fullWidth
          type='number'
          step='0.01'
          id={`${prefix}upfront_rrp`}
          label={t('Upfront RRP')}
          name={`${prefix}upfront_rrp`}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={values[`${prefix}upfront_rrp`].value}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant='standard'
          margin='normal'
          required
          fullWidth
          type='number'
          step='0.01'
          id={`${prefix}monthly_rrp`}
          label={t('Monthly Sell')}
          name={`${prefix}monthly_rrp`}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={values[`${prefix}monthly_rrp`].value}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  )
}

export default Form
