import config from 'config'
import { getCookie, getJsonHeaders } from '../helpers'

export async function list(slug, categoryId = '', limit = 15, page = 1, search = '') {
  let url = `/api/library/${slug}/posts?per_page=${limit}&page=${page}`

  if (categoryId) {
    url += `&cats=${categoryId}`
  }

  if (search) {
    url += `&search=${search}`
  }

  const result = await fetch(config.API_URL + url)
  return await result.json()
}

export async function get(slug, id) {
  let url = `/api/library/${slug}/posts/${id}`
  const result = await fetch(config.API_URL + url)
  return await result.json()
}

export async function update(slug, id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/library/${slug}/posts/${id}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-XSRF-TOKEN': await getCookie('XSRF-TOKEN'),
      },
      body: body,
    })

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully updated post',
        post: decoded,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to update post',
      errors: [['', 'Failed to update post']],
    }
  }
}

export async function create(slug, body) {
  try {
    const res = await fetch(config.API_URL + `/api/library/${slug}/posts`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-XSRF-TOKEN': await getCookie('XSRF-TOKEN'),
      },
      body: body,
    })

    const decoded = await res.json()

    if (res.status === 201) {
      return {
        success: true,
        message: 'Successfully created post',
        post: decoded,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to create post',
      errors: [['', 'Failed to create post']],
    }
  }
}

export async function getFileUploadUrl(slug, postId, filename, type) {
  let url = `/api/library/${slug}/posts/${postId}/get-file-upload-url?filename=${encodeURIComponent(
    filename
  )}&type=${type}`

  const headers = await getJsonHeaders()

  const result = await fetch(config.API_URL + url, {
    headers: headers,
    method: 'GET',
  })
  return await result.json()
}

export async function linkUploadedFiles(slug, postId, fileDetails) {
  let url = `/api/library/${slug}/posts/${postId}/link-uploaded-files`

  const headers = await getJsonHeaders()

  const res = await fetch(config.API_URL + url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ file_details: fileDetails }),
  })

  if (res.ok) {
    return true
  } else {
    throw new Error(`${res.status} ${res.statusText}`)
  }
}

export async function deletePost(slug, id) {
  try {
    let url = `/api/library/${slug}/posts/${id}`
    const res = await fetch(config.API_URL + url, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
      body: JSON.stringify({}),
    })

    if (res.status === 204) {
      return true
    }

    return false
  } catch (e) {
    return false
  }
}

export async function listTags(slug) {
  let url = `/api/library/${slug}/tags`
  const result = await fetch(config.API_URL + url)
  return await result.json()
}

export async function getPriceList() {
  let url = `/api/library/price-list`
  const result = await fetch(config.API_URL + url)

  if (result.status === 404) {
    return false
  }

  return await result.json()
}

export async function createPriceList() {
  try {
    const body = {}

    const res = await fetch(config.API_URL + `/api/library/price-list`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    const decoded = await res.json()

    if (res.status === 201) {
      return {
        success: true,
        message: 'Successfully created price list',
        post: decoded,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to create post',
      errors: [['', 'Failed to create price list']],
    }
  }
}

export async function getCloudOrdersInfo() {
  let url = `/api/library/cloud-orders-info`
  const result = await fetch(config.API_URL + url)

  if (result.status === 404) {
    return false
  }

  return await result.json()
}

export async function createCloudOrdersInfo() {
  try {
    const body = {}

    const res = await fetch(config.API_URL + `/api/library/cloud-orders-info`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    const decoded = await res.json()

    if (res.status === 201) {
      return {
        success: true,
        message: 'Successfully created cloud orders info',
        post: decoded,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to create cloud orders info',
      errors: [['', 'Failed to create cloud orders info']],
    }
  }
}

export async function submitForm(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/library/posts/${id}/form`, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    const decoded = await res.json()

    if (res.status === 200) {
      return {
        success: true,
        message: 'Successfully submitted form',
        post: decoded,
      }
    }

    return {
      success: false,
      message: decoded.message,
      errors: Object.entries(decoded.errors).map(error => {
        return error
      }),
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to submit form',
      errors: [['', 'Failed to submit form']],
    }
  }
}
