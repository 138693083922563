import { Link, Breadcrumbs as MuiBreadcrumbs, makeStyles } from '@material-ui/core'
import { BreadcrumbContext } from 'contexts/BreadcrumbContext'
import { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    position: 'relative',
    marginBottom: '3px',
  },
}))

function BreadCrumbs() {
  const { breadcrumbs } = useContext(BreadcrumbContext)
  const classes = useStyles()

  return (
    <MuiBreadcrumbs className={classes.breadcrumbs}>
      {breadcrumbs.map((crumb, index) => (
        <Link key={index} href={crumb.path}>
          {crumb.label ?? 'LABEL'}
        </Link>
      ))}
    </MuiBreadcrumbs>
  )
}

export default BreadCrumbs
