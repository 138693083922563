import config from 'config'

export async function getToken() {
  try {
    const result = await fetch(config.API_URL + '/api/ipecsone/token')
    return await result.json()
  } catch (e) {
    return false
  }
}
