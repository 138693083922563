import { Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core'
import { LoadingContext } from 'contexts/LoadingContext'
import { NotificationContext } from 'contexts/NotificationContext'
import format from 'date-fns/format'
import draftToHtml from 'draftjs-to-html'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'api/notifications'
import { BreadcrumbContext } from 'contexts/BreadcrumbContext'

function Notification() {
  const params = useParams()

  const { markNotificationRead } = useContext(NotificationContext)
  const { loading, setLoading } = useContext(LoadingContext)
  const { setBreadcrumbs } = useContext(BreadcrumbContext)

  const [notification, setNotification] = useState(null)
  const [notificationImage, setNotificationImage] = useState(null)

  // preload the image before showing content to avoid reflow
  const loadImage = async notification =>
    new Promise((resolve, reject) => {
      const img = new Image()
      img.src = '/api/' + notification.image_full_path
      setNotificationImage(img)
      img.addEventListener('load', () => {
        resolve(img)
      })
      img.addEventListener('error', () => {
        reject(img)
      })
    })

  useEffect(() => {
    setLoading(true)

    const getData = async () => {
      const res = await get(params.id)
      setNotification(res.data)
      setBreadcrumbs([
        { label: 'Home', path: '/' },
        { label: 'Notifications', path: '/notifications' },
        { label: res.data.title },
      ])
      return res.data
    }

    getData().then(data => {
      let promises = []

      if (data.image_full_path) {
        promises.push(loadImage(data))
      }
      if (!data.read_at) {
        promises.push(
          markNotificationRead(params.id).then(value => setNotification(value))
        )
      }

      Promise.all(promises).then(resolved => {
        setLoading(false)
      })
    })
  }, [params.id, setLoading, setNotification, markNotificationRead, setBreadcrumbs])

  return (
    !loading &&
    notification && (
      <Grid container>
        <Grid item sm={8} lg={6}>
          <Card>
            {notificationImage && (
              <CardMedia
                component='img'
                image={notificationImage.src}
                title={notification.title}
              />
            )}
            <CardContent>
              <h1>{notification.title}</h1>

              <Typography variant='subtitle1'>{notification.summary}</Typography>

              <div
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(notification.content) || notification.content,
                }}></div>

              <Typography variant='subtitle2'>
                {format(
                  new Date(notification.sent_at.datetime),
                  'do MMMM yyyy, HH:mm:ss'
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  )
}

export default Notification
