import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MuiAlert from '@material-ui/lab/Alert'
import { siteNav } from 'api/form-options'
import { create, deleteSiteNav, list, sort, update } from 'api/site-navs'
import SiteNavFormModal from 'components/navigation/SiteNavFormModal'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import { useContext, useEffect, useState } from 'react'
import { NavigationTree } from './NavItemList'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  white: {
    color: theme.palette.background.default,
    marginLeft: '1rem',
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    minWidth: 650,
  },
  pagination: {
    marginBottom: '60px',
  },
  userTypeSelect: {
    marginBottom: '30px',
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  fabShift: {
    bottom: 90,
  },
  subNav: {
    marginLeft: '4em',
    background: 'red',
  },
}))

function List(props) {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const [savingChanges, setSavingChanges] = useState(false)
  const [sitenavs, setSiteNavs] = useState({})
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [hideContent, setHideContent] = useState(true)
  const [selectedNav, setSelectedNav] = useState(null)
  const [pendingChanges, setPendingChanges] = useState(false)
  const [formOptions, setFormOptions] = useState(null)
  const { user, reloadUser } = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await list(1000)
      setSiteNavs(res)

      setFormOptions(await siteNav('reseller'))
      setLoading(false)
      setHideContent(false)
    }

    if (props.location?.state) {
      props.history.replace()
    }

    fetchData()
  }, [setLoading, props.location, props.history])

  const reloadAll = async () => {
    setSiteNavs(await list(1000))
  }

  const handleSaveChanges = async () => {
    setLoading(true)
    setSavingChanges(true)

    // create all dropdowns first - we need the ids if there any children
    const dropdownsCreated = await Promise.all(
      sitenavs.data
        .filter(n => n.type === 'DROPDOWN')
        .filter(n => (n.create ? true : false))
        .map(async (n, i) => {
          const res = await create(n)

          return { tempId: n.id, id: res.data }
        })
    )

    // for the same reason, update dropdowns next
    const dropdownsUpdated = await Promise.all(
      sitenavs.data
        .filter(n => n.type === 'DROPDOWN' && n.update === true)
        .map(async (n, i) => {
          await update(n.id, n)

          return { id: n.id, tempId: n.id }
        })
    )

    // create all ids and return the new ids for each
    const created = await Promise.all(
      sitenavs.data
        .filter(n => n.type !== 'DROPDOWN')
        .filter(n => (n.create ? true : false))
        .map(async (n, i) => {
          if (n.parent) {
            n.parent.id = [...dropdownsCreated, ...dropdownsUpdated].find(
              d => d.tempId === n.parent.id
            ).id
          }
          const res = await create(n)

          return { tempId: n.id, id: res.data }
        })
    )

    // perform all necessary updates
    await Promise.all(
      sitenavs.data
        .filter(n => (n.update ? true : false))
        .map(async (n, i) => {
          await update(n.id, n)
        })
    )

    // delete any marked for deletion
    await Promise.all(
      sitenavs.data
        .filter(n => (n.delete ? true : false))
        .map(async (n, i) => {
          await deleteSiteNav(n.id)
        })
    )

    // sort what remains by index
    // replace temporary ids with newly created ones
    const toSort = sitenavs.data
      .map(n =>
        created.find(c => c.tempId === n.id)
          ? { ...n, id: created.find(c => c.tempId === n.id).id }
          : n
      )
      .filter(n => (n.delete ? false : true))

    await sort(
      toSort.map((n, i) => ({
        id: n.id,
        index: i,
        parent_id: n.parent ? n.parent.id : null,
      }))
    )

    await reloadAll()

    await reloadUser()

    setLoading(false)
    setSavingChanges(false)
    setPendingChanges(false)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleOpenDeleteDialog = nav => {
    setDeleteId(nav.id)
    setDeleteOpen(true)
  }

  const handleOpenCreateDialog = id => {
    setSelectedNav({
      label: '',
      type: '',
      display_on_homepage: false,
      type_value: '',
      permissions: [],
    })
  }

  const handleOpenEditDialog = nav => {
    setSelectedNav({
      id: nav.id,
      label: nav.label,
      type: nav.type,
      type_value: nav.type_value,
      permissions: nav.permissions ?? [],
      display_on_homepage: nav.display_on_homepage,
    })
  }

  const handleSubmitSelectedNav = async () => {
    const body = {
      label: selectedNav.label,
      type: selectedNav.type,
      type_value: selectedNav.type_value,
      user_has_permission: selectedNav.permissions,
      display_on_homepage: selectedNav.display_on_homepage,
    }

    if (selectedNav.id) {
      setSiteNavs(sitenavs => ({
        ...sitenavs,
        data: sitenavs.data.map(nav =>
          nav.id === selectedNav.id
            ? {
                ...nav,
                label: selectedNav.label,
                type: selectedNav.type,
                type_value: selectedNav.type_value,
                user_has_permission: selectedNav.permissions,
                display_on_homepage: selectedNav.display_on_homepage,
                update: true,
              }
            : nav
        ),
      }))
    } else {
      setSiteNavs(sitenavs => ({
        ...sitenavs,
        data: [
          {
            ...body,
            div_id: null,
            editable: true,
            parent: null,
            id: `${sitenavs.data.length}`,
            create: true,
          },
          ...sitenavs.data,
        ],
      }))
    }

    setPendingChanges(true)

    setSelectedNav(null)
  }

  const handleChangeSelectedNav = e => {
    const { name, value } = e.target
    setSelectedNav(selectedNav => ({ ...selectedNav, [name]: value }))
  }

  const handleCloseCreateDialog = () => {
    setSelectedNav(null)
  }

  const handleClickAddToHome = async nav => {
    setSiteNavs(sitenavs => ({
      ...sitenavs,
      data: sitenavs.data.map(n =>
        n.id === nav.id
          ? { ...n, display_on_homepage: !n.display_on_homepage, update: true }
          : n
      ),
    }))

    setPendingChanges(true)
  }

  const handleDelete = async id => {
    setSiteNavs(sitenavs => {
      const deleteItem = (navs, id) => {
        return (
          navs
            .map(nav => ({
              ...nav,
              delete: nav.id === id,
              items: deleteItem(nav.items ?? [], id),
            }))
            // if newly created, remove this completely so we don't try
            // and delete a nav that doesn't yet exist on the backend
            .filter(nav => !(nav.create && nav.delete))
        )
      }

      return {
        ...sitenavs,
        data: deleteItem(sitenavs.data, id),
      }
    })

    setPendingChanges(true)

    setDeleteOpen(false)
  }

  const handleOrganiseItems = async (items, other) => {
    if (other.type === 'dropped') {
      const flattenNavs = navs => {
        let children = []

        return navs
          .map(n => {
            if (n.children && n.children.length) {
              children = [...children, ...n.children]
            }
            return n
          })
          .concat(children.length ? flattenNavs(children) : children)
      }

      const newSiteNavs = flattenNavs(items)

      const transformItem = item => ({
        id: item.id,
        label: item.label,
        items: item.children?.map(i => transformItem(i) ?? null),
        type: item.type,
        display_on_homepage: item.display_on_homepage,
        create: item.create ? true : false,
        update: item.update ? true : false,
        type_value: item.type_value,
        parent: item.parentId
          ? {
              id: sitenavs.data.find(n => n.id === item.parentId).id,
              label: sitenavs.data.find(n => n.id === item.parentId).label,
            }
          : null,
        permissions: item.permissions,
      })

      setSiteNavs({ ...sitenavs, data: newSiteNavs.map(t => transformItem(t)) })

      setPendingChanges(true)
    }
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Menu Items
      </Typography>

      {sitenavs.data && (
        <NavigationTree
          onClickEdit={handleOpenEditDialog}
          onClickAddToHome={handleClickAddToHome}
          onClickDelete={handleOpenDeleteDialog}
          onChange={handleOrganiseItems}
          items={sitenavs.data}
        />
      )}

      {selectedNav && (
        <SiteNavFormModal
          open={selectedNav !== null}
          label={selectedNav?.label}
          type={selectedNav?.type}
          formOptions={formOptions}
          displayOnHomePage={selectedNav.display_on_homepage}
          loading={loading}
          permissions={selectedNav?.permissions ?? []}
          typeValue={selectedNav?.type_value}
          allowChangeType={selectedNav?.type !== 'DROPDOWN' ?? true}
          onChange={handleChangeSelectedNav}
          onClose={handleCloseCreateDialog}
          onSubmit={handleSubmitSelectedNav}
        />
      )}

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='alert-dialog-title'>{'Menu Item Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this menu item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleDelete(deleteId)}
            color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {pendingChanges && (
        <Snackbar open={pendingChanges} autoHideDuration={null}>
          <MuiAlert elevation={6} variant='filled' severity={'info'}>
            <div className={classes.alert}>
              <Typography ml={2}>
                {savingChanges ? 'Saving your changes...' : 'You have pending changes'}
              </Typography>
              {savingChanges ? (
                <>
                  <CircularProgress className={classes.white} size={24} />
                </>
              ) : (
                <>
                  <Button
                    className={classes.white}
                    color='primary'
                    disabled={savingChanges}
                    onClick={() => {
                      handleSaveChanges()
                    }}>
                    Save
                  </Button>
                </>
              )}
            </div>
          </MuiAlert>
        </Snackbar>
      )}

      <Fab
        className={`${classes.fab} ${user.live_chat_url !== '' && classes.fabShift}`}
        color='secondary'
        aria-label='add'
        onClick={handleOpenCreateDialog}>
        <AddIcon />
      </Fab>
    </div>
  )
}

export default List
