import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Link from 'components/Link'
import TextContentRenderer from 'components/TextContentRenderer'
import Post from './Post'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(6),
  },
  column1: {
    gridArea: 'column-1',
  },
  column2: {
    gridArea: 'column-2',
  },
  featuredCategoryCard: {
    height: '100%',
    position: 'relative',
  },
  featuredCategoryPush: {
    height: '50px',
  },
  featuredCategoryActions: {
    height: '50px',
    position: 'absolute',
    bottom: '0',
    right: '0',
    '& > button': {
      fontWeight: '600',
    },
  },
  featuredCategories: {
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateColumns: 'repeat(1, 1fr)',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  categoriesMenu: {
    background: theme.palette.darkBlue,
    '& *': {
      color: '#FFF',
    },
    borderRadius: '2em',
  },
  categoriesMenuItem: {
    padding: 0,
    margin: 0,
    '&:first-child': {
      borderRadius: '2em 2em 0 0',
    },
    '&:last-child': {
      borderRadius: '0 0 2em 2em',
    },
  },

  menuItemDivider: {
    background: theme.palette.lightBlue,
  },
  recentlyUpdated: {
    display: 'grid',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    background: '#fff',
    gridColumnGap: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  videos: {
    display: 'grid',
    gridGap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  video: {
    height: '200px',
    width: '100%',
    padding: '0 1rem',
  },
  menuLink: {
    textDecoration: 'none',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  menuLinkIcon: {
    background: theme.palette.primary[500],
    height: '100%',
    padding: theme.spacing(2, 3),
    '&:hover': {
      background: theme.palette.primary[700],
    },
  },
}))

function Summary({ data, history, linkPrefix }) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.column1}>
        {data.posts.featured.length > 0 && (
          <Box mb={4}>
            <Typography variant='h5' gutterBottom>
              Featured
            </Typography>

            <div className={classes.featuredCategories}>
              {data.posts.featured.map(x => {
                if (x.featured === 'video') {
                  return <FeaturedPostVideo key={x.id} post={x} linkPrefix={linkPrefix} />
                } else {
                  return (
                    <FeaturedPostContent key={x.id} post={x} linkPrefix={linkPrefix} />
                  )
                }
              })}
            </div>
          </Box>
        )}

        {data.categories.featured.length > 0 && (
          <Box mb={4}>
            <Typography variant='h5' gutterBottom>
              Featured Categories
            </Typography>

            <div className={classes.featuredCategories}>
              {data.categories.featured.map(x => (
                <FeaturedCategory key={x.id} category={x} linkPrefix={linkPrefix} />
              ))}
            </div>
          </Box>
        )}

        <Box>
          <Typography variant='h5' gutterBottom>
            Recently Updated
          </Typography>

          <Paper className={classes.recentlyUpdated}>
            {data.posts.recently_updated.length === 0 && (
              <p>Nothing has been recently updated</p>
            )}

            {data.posts.recently_updated.map(x => (
              <Post key={x.id} post={x} limitWidth={true} linkPrefix={linkPrefix} />
            ))}
          </Paper>
        </Box>
      </Box>
    </Box>
  )
}

function FeaturedPostContent({ post, linkPrefix }) {
  const classes = useStyles()

  return (
    <Link to={`/${linkPrefix}/posts/${post.id}`}>
      <Card className={classes.featuredCategoryCard}>
        <CardContent>
          <Typography variant='h5' component='h2'>
            {post.name}
          </Typography>
          {post.image && (
            <>
              <img
                style={{
                  margin: '1rem auto',
                  height: '200px',
                  maxWidth: '100%',
                  display: 'flex',
                }}
                src={post.thumb.length > 0 ? post.thumb : post.image}
                alt='Featured post'
              />
            </>
          )}
          <TextContentRenderer content={post.content} />
          <div style={{ clear: 'both' }}></div>
        </CardContent>
        <div className={classes.featuredCategoryPush}></div>
        <CardActions className={classes.featuredCategoryActions}>
          <Button size='small' color='primary'>
            View
          </Button>
        </CardActions>
      </Card>
    </Link>
  )
}

function FeaturedPostVideo({ post, linkPrefix }) {
  const classes = useStyles()

  return (
    <Link to={`/${linkPrefix}/posts/${post.id}`}>
      <Card className={classes.featuredCategoryCard}>
        <CardContent>
          <Typography variant='h5' component='h2'>
            {post.name}
          </Typography>
        </CardContent>

        <iframe
          title='video'
          className={classes.video}
          src={`https://www.youtube.com/embed/${post.youtube_id}`}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen></iframe>
      </Card>
    </Link>
  )
}

function FeaturedCategory({ category, linkPrefix }) {
  const classes = useStyles()

  return (
    <Link to={`/${linkPrefix}/categories/${category.id}`}>
      <Card className={classes.featuredCategoryCard}>
        <CardContent>
          <Typography variant='h5' component='h2'>
            {category.name}
          </Typography>

          <TextContentRenderer content={category.description} preview={true} />
        </CardContent>
        <div className={classes.featuredCategoryPush}></div>
        <CardActions className={classes.featuredCategoryActions}>
          <Button size='small'>View</Button>
        </CardActions>
      </Card>
    </Link>
  )
}

export default Summary
