import { Button, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { libraryPost } from 'api/form-options'
import { list as listCategories } from 'api/library/categories'
import { create, listTags } from 'api/library/posts'
import PostForm from 'components/library/post/PostForm'
import { LoadingContext } from 'contexts/LoadingContext'
import { getFormData, uploadFiles } from 'helpers/library'
import { useContext, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
  section: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateAreas: '"column-2" "column-1"',

    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(4),
      gridTemplateAreas: '"column-1 column-2"',
      gridTemplateColumns: '8fr 4fr',
    },
  },
  column1: {
    gridArea: 'column-1',
  },
  column2: {
    gridArea: 'column-2',
  },
}))

function PostCreate(props) {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const { addToast } = useToasts()
  const [categories, setCategories] = useState({})
  const [tags, setTags] = useState({})
  const [formOptions, setFormOptions] = useState(null)
  const [values, setValues] = useState({
    name: '',
    content: '',
    image: {},
    featured: '',
    youtube_id: '',
    categories: [],
    tags: [],
    files: [],
    user_has_permission: [],
  })
  const [errors, setErrors] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const [catData, tagData, formOptionData] = await Promise.all([
        listCategories(1000, 1, 'pages'),
        listTags('pages'),
        libraryPost(),
      ])
      setCategories(catData)
      setTags(tagData)
      setFormOptions(formOptionData)
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSave = async (publish = false) => {
    setLoading(true)

    const data = getFormData(values)
    data.set('publish', publish ? 1 : 0)
    const res = await create('pages', data)

    try {
      if (values.files) {
        await uploadFiles('pages', res.post.data.id, values.files)

        if (res.success) {
          addToast('Saved Successfully', { appearance: 'success' })
          props.history.push(`/site-content/pages/${res.post.data.id}`)
        } else {
          setErrors(res.errors)
        }
      }
    } catch (e) {
      addToast('There was an error uploading your files', { appearance: 'error' })
    } finally {
      setLoading(false)
    }
  }

  if (!categories.data || !formOptions) {
    return null
  }

  return (
    <Box className={classes.container}>
      <Typography variant='h4' gutterBottom>
        Create Page
      </Typography>

      <PostForm
        post=''
        showCategories={false}
        showTags={false}
        allowFeatured={false}
        categories={categories}
        permissionOptions={formOptions.permissions}
        tags={tags}
        values={values}
        errors={errors}
        handleChange={handleChange}
      />

      <Box className={classes.section}>
        <Box
          className={classes.column1}
          display='flex'
          gridGap={'1rem'}
          justifyContent='end'>
          <Button
            disabled={loading}
            onClick={() => {
              handleSave(false)
            }}
            variant='contained'
            color='default'>
            Save draft
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              handleSave(true)
            }}
            variant='contained'
            color='primary'>
            Publish
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PostCreate
