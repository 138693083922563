import { Button, Menu, MenuItem } from '@material-ui/core'
import { useState } from 'react'

function PublishButton({ loading, onClickNow, onClickScheduled }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickNow = () => {
    setAnchorEl(null)
    onClickNow()
  }

  const handleClickScheduled = () => {
    setAnchorEl(null)
    onClickScheduled()
  }

  return (
    <>
      <Button
        aria-controls='publish-menu'
        disabled={loading}
        variant='contained'
        color='primary'
        aria-haspopup='true'
        onClick={handleClick}>
        Publish
      </Button>
      <Menu
        id='publish-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleClickNow}>Now</MenuItem>
        <MenuItem onClick={handleClickScheduled}>Scheduled...</MenuItem>
      </Menu>
    </>
  )
}

export default PublishButton
