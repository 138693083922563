import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import { IconButton } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MuiAlert from '@material-ui/lab/Alert'
import { deleteLeasing, list } from 'api/leasing'
import LeasingRatesDialog from 'components/LeasingRatesDialog'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import TableIcon from 'components/TableIcon'
import { AuthContext } from 'contexts/AuthContext'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  fab: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  paper: {
    marginBottom: '50px',
    padding: theme.spacing(2),
  },
  clearAsset: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 4fr 1fr',
    alignItems: 'center',
  },
}))

function List(props) {
  const classes = useStyles()
  const { hasFeature } = useContext(FeatureToggleContext)
  const { loading, setLoading } = useContext(LoadingContext)
  const [leasings, setLeasings] = useState({})
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [ratesOpen, setRatesOpen] = useState(false)
  const [hideContent, setHideContent] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const { user } = useContext(AuthContext)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setLeasings(await list())
      setLoading(false)
      setHideContent(false)
    }

    if (props.location.state) {
      setSnackbarSeverity(props.location.state.severity)
      setSnackbarMessage(props.location.state.message)
      setSnackbarOpen(true)
      props.history.replace()
    }

    fetchData()
  }, [setLoading, props.location, props.history])

  const handleChangePage = async (event, newPage) => {
    setLoading(true)
    setLeasings(await list(parseInt(leasings.meta.per_page), parseInt(newPage + 1)))
    setLoading(false)
  }

  const handleChangeRowsPerPage = async event => {
    setLoading(true)
    setLeasings(await list(parseInt(event.target.value, 10)))
    setLoading(false)
  }

  const handleOpenDeleteDialog = id => {
    setDeleteId(id)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleOpenRatesDialog = () => {
    setRatesOpen(true)
  }

  const handleDelete = async id => {
    setLoading(true)
    const success = await deleteLeasing(id)

    if (success) {
      setLeasings(await list(parseInt(leasings.meta.per_page)))
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully deleted leasing')
    } else {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to delete leasing')
    }

    setDeleteOpen(false)
    setLoading(false)
    setSnackbarOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Leasing
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Months</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Profile</TableCell>
              <TableCell>Include Services</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leasings.data.map(row => (
              <TableRow key={row.id}>
                <TableCell scope='row'>{row.name}</TableCell>
                <TableCell scope='row'>{row.months}</TableCell>
                <TableCell scope='row'>
                  {row.type.replace(/_/g, ' ').toUpperCase()}
                </TableCell>
                <TableCell scope='row'>{row.value}</TableCell>
                <TableCell scope='row'>{row.upfront_multiplier}</TableCell>
                <TableCell scope='row'>{row.include_services ? 'Yes' : 'No'}</TableCell>
                <TableCell align='right'>
                  <IconButton
                    aria-label='edit'
                    color='default'
                    component={NavLink}
                    to={`/leasing/update/${row.id}`}>
                    <TableIcon icon='view' color='blue' />
                  </IconButton>
                  <IconButton
                    aria-label='delete'
                    color='secondary'
                    onClick={() => handleOpenDeleteDialog(row.id)}>
                    <TableIcon icon='delete' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[5, 15, 25]}
        component='div'
        count={leasings.meta.total}
        rowsPerPage={parseInt(leasings.meta.per_page)}
        page={leasings.meta.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {hasFeature('clear_asset_finance') && (
        <Paper className={classes.paper}>
          <div className={classes.clearAsset}>
            Clear Asset Finance
            <img src='/clear_asset.png' alt='Clear Asset Finance Logo' height='50' />
            Clear makes the process of securing finance simple, quick and a positive
            experience.
            <Button variant='contained' color='default' onClick={handleOpenRatesDialog}>
              Get Rates
            </Button>
          </div>
        </Paper>
      )}

      <LeasingRatesDialog open={ratesOpen} setOpen={setRatesOpen} />

      <Dialog open={deleteOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle id='alert-dialog-title'>{'Leasing Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this leasing?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleDelete(deleteId)}
            color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert
          elevation={6}
          variant='filled'
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Fab
        className={`${classes.fab} ${user.live_chat_url !== '' && classes.fabShift}`}
        color='secondary'
        aria-label='add'
        component={NavLink}
        to='/leasing/create'>
        <AddIcon />
      </Fab>
    </div>
  )
}

export default List
