import { IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import TableIcon from './TableIcon'

function ProductFavourite({ productId, isFavourite, onAdd, onRemove }) {
  return isFavourite ? (
    <Tooltip title='Remove from favourites' placement='top'>
      <IconButton
        color='default'
        onClick={() => {
          onRemove(productId)
        }}>
        <TableIcon icon='starfill' color='yellow' />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title='Add to favourites' placement='top'>
      <IconButton
        color='default'
        onClick={() => {
          onAdd(productId)
        }}>
        <TableIcon icon='star' color='yellow' />
      </IconButton>
    </Tooltip>
  )
}

ProductFavourite.propTypes = {
  productId: PropTypes.string.isRequired,
  isFavourite: PropTypes.number.isRequired,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
}

export default ProductFavourite
