import React, { useState } from 'react'

const BreadcrumbContext = React.createContext()
const WithBreadcrumbs = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const defaultContext = {
    breadcrumbs,
    setBreadcrumbs,
  }

  return (
    <BreadcrumbContext.Provider value={defaultContext}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

export { BreadcrumbContext }
export default WithBreadcrumbs
