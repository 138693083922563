import {
  Button,
  Checkbox,
  FormHelperText,
  IconButton,
  Paper,
  TextField,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import DownIcon from '@material-ui/icons/ArrowDownward'
import UpIcon from '@material-ui/icons/ArrowUpward'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiAlert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ImageCropper from 'components/ImageCropper'
import TextContentEditor from 'components/TextContentEditor'
import { FileManagerProvider } from 'contexts/FileManagerContext'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import FileEditor from './FileEditor'
import PostStatus from './PostStatus'

const useStyles = makeStyles(theme => ({
  section: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateAreas: '"column-2" "column-1"',

    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(4),
      gridTemplateAreas: '"column-1 column-2"',
      gridTemplateColumns: '8fr 4fr',
    },
  },
  column1: {
    gridArea: 'column-1',
  },
  column2: {
    gridArea: 'column-2',
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  video: {
    width: '100%',
    height: '400px',
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControlNoMargin: {
    minWidth: '100%',
  },
}))

function PostForm({
  post,
  categories,
  notify = false,
  showCategories = true,
  tags,
  showTags = true,
  allowFeatured = true,
  values,
  errors,
  handleChangeNotify,
  canEmbedForm,
  handleChange,
}) {
  const classes = useStyles()
  const [imageBase64, setImageBase64] = useState()
  const [imageError, setImageError] = useState(false)
  const minImageWidth = 400
  const minImageHeight = 200

  const handleChangeContent = event => {
    handleChange({
      target: { ...event.target, value: JSON.stringify(event.target.value) },
    })

    if (!event.target.value && values.featured === 'content') {
      handleChange({ target: { name: 'featured', value: 0 } })
    }
  }

  const handleChangeYoutubeId = event => {
    handleChange(event)

    if (!event.target.value && values.featured === 'video') {
      handleChange({ target: { name: 'featured', value: 0 } })
    }
  }

  const handleChangeCategory = value => {
    handleChange({ target: { name: 'categories', value: value } })
  }

  const handleChangeTags = value => {
    handleChange({ target: { name: 'tags', value: value } })
  }

  const handleBlurTag = value => {
    if (value) {
      const newTags = Array.from(values.tags)
      newTags.push(value)
      handleChange({ target: { name: 'tags', value: newTags } })
    }
  }

  const readFileAsBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(file)
    })

  const handleImageChange = async event => {
    event.persist()
    const file = event.target.files[0]
    if (!file) return
    try {
      const image = new Image()
      const imageBase64 = await readFileAsBase64(file)

      image.onload = () => {
        if (image.width < minImageWidth || image.height < minImageHeight) {
          setImageError(true)
          event.target.value = null
          setImageBase64()
        } else {
          setImageBase64(imageBase64)
          setImageError(false)
        }
      }

      image.src = imageBase64
    } catch (error) {
      console.error('Error occurred:', error)
    }
  }

  const handleEditImage = value => {
    handleChange({
      target: { name: 'image', value: { data: value } },
    })
  }

  const handleDeleteFile = id => {
    const newFiles = values.files.map(x => {
      if (x.id === id) {
        x.deleted = true
      }

      return x
    })
    handleChange({ target: { name: 'files', value: newFiles } })
  }

  const handleDeleteFolder = id => {
    handleChange({
      target: {
        name: 'folders',
        value: values.folders.map(x => (x.id === id ? { ...x, deleted: true } : x)),
      },
    })
  }

  const handleAddFile = newFiles => {
    handleChange({ target: { name: 'files', value: newFiles } })
  }

  const handleAddFolder = folderName => {
    handleChange({
      target: { name: 'folders', value: [...values.folders, { name: folderName }] },
    })
  }

  const handleSortFiles = files => {
    handleChange({
      target: {
        name: 'files',
        value: values.files.map(x => files.find(y => y.filename === x.filename) ?? x),
      },
    })
  }

  const handleSortFolders = folders => {
    handleChange({
      target: {
        name: 'folders',
        value: folders,
      },
    })
  }

  const handleUpdateFileDisplayType = (id, value) => {
    handleChange({
      target: {
        name: 'files',
        value: values.files.map(x => (x.id === id ? { ...x, display_type: value } : x)),
      },
    })
  }

  const getFeaturedOptions = () => {
    const options = [{ value: '0', label: 'No' }]

    if (values.content) {
      options.push({ value: 'content', label: 'Content' })
    }

    if (values.youtube_id) {
      options.push({ value: 'video', label: 'Video' })
    }

    return options
  }

  return (
    <Box mb={8}>
      <Box mb={2}>
        <PostStatus post={values}></PostStatus>
      </Box>

      <TextField
        variant='standard'
        margin='normal'
        required
        fullWidth
        id='name'
        label='Name'
        name='name'
        value={values.name}
        autoFocus
        onChange={handleChange}
        helperText='The main display name for the post.'
      />

      {showCategories && (
        <Box mb={3}>
          <Autocomplete
            multiple
            id='categories'
            options={categories.data.map(x => ({ id: x.id, name: x.name }))}
            getOptionLabel={option => option.name}
            value={values.categories.map(x => ({ id: x.id, name: x.name }))}
            onChange={(_, newValue) => {
              handleChangeCategory(newValue)
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant='standard'
                label='Categories'
                placeholder='Categories'
                helperText='You can select one or more categories.  The post will displayed when viewing any of the categories.'
              />
            )}
          />
        </Box>
      )}

      {showTags && (
        <Box mb={3}>
          <Autocomplete
            multiple
            freeSolo
            id='tags'
            options={tags.data ? tags.data : []}
            value={values.tags}
            onChange={(_, newValue) => {
              handleChangeTags(newValue)
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant='standard'
                onBlur={event => {
                  handleBlurTag(event.target.value)
                }}
                label='Tags'
                placeholder='Tags'
                helperText='Tags are used to help when searching the library.  Enter some text and press return to add it as a text or select from previous tags.'
              />
            )}
          />
        </Box>
      )}

      <Box className={classes.section}>
        <Box className={classes.column1}>
          <Box mb={2} mt={1}>
            <input
              accept='image/*'
              style={{ display: 'none' }}
              id='image'
              name='image'
              multiple
              type='file'
              onChange={handleImageChange}
            />
            <label htmlFor='image'>
              <Button variant='contained' component='span' color='primary'>
                {post.data && post.data.image
                  ? 'Set New Feature Image'
                  : 'Set Feature Image (Optional)'}
              </Button>
            </label>
          </Box>

          {!imageBase64 && post.data && post.data.image && (
            <Box mb={3}>
              <img
                style={{ maxWidth: '100%' }}
                alt='Post feature'
                src={post.data.image}
              />
            </Box>
          )}

          {imageBase64 && (
            <Box mb={2}>
              <ImageCropper
                imageBase64={imageBase64}
                onChange={handleEditImage}
                minImageWidth={minImageWidth}
                minImageHeight={minImageHeight}
                maxRenderWidth={1920}
              />
            </Box>
          )}

          {imageError && (
            <Box mb={2}>
              <MuiAlert severity='error'>
                Please use a photo that is at least 400x200 pixels
              </MuiAlert>
            </Box>
          )}

          <Paper className={classes.paper}>
            <TextContentEditor
              placeholder='Add your post content here'
              name='content'
              value={values.content}
              onChange={handleChangeContent}
            />
          </Paper>

          {canEmbedForm && (
            <Paper className={classes.paper}>
              <Typography variant='h6' gutterBottom>
                Embedded Form
              </Typography>
              <FormCreater
                data={post ? post.data.form : null}
                handleChange={handleChange}
              />
            </Paper>
          )}

          <Paper className={classes.paper}>
            <TextField
              variant='standard'
              margin='normal'
              fullWidth
              id='youtube_id'
              label='Youtube ID (Optional)'
              name='youtube_id'
              value={values.youtube_id ?? ''}
              onChange={handleChangeYoutubeId}
            />

            <Typography variant='h6' gutterBottom>
              Single Video
            </Typography>
            <p>
              When viewing a YouTube video the in a browser, the URL should have v=
              towards the end, this is the ID needed.
            </p>
            <p>
              For example, if the url was https://www.youtube.com/watch?v=DmE5DY7iNCw, you
              should enter DmE5DY7iNCw into the field above.
            </p>

            <Typography variant='h6' gutterBottom>
              Playlists
            </Typography>
            <p>
              If viewing a YouTube playlist it will have list= towards the end, this is
              the ID needed.
            </p>
            <p>
              You will need to prefix the ID with videoseries?list= in the field above.
            </p>
            <p>
              For example, if the url was
              https://www.youtube.com/playlist?list=PLEl123--321, you should enter
              videoseries?list=PLEl123--321 into the field above.
            </p>

            {values.youtube_id && (
              <iframe
                title='video'
                className={classes.video}
                src={`https://www.youtube.com/embed/${values.youtube_id}`}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen></iframe>
            )}
          </Paper>

          {allowFeatured && (
            <Paper className={classes.paper}>
              <FormControl variant='standard' className={classes.formControl}>
                <InputLabel id='featured_label'>Featured</InputLabel>
                <Select
                  labelId='featured_label'
                  id='featured'
                  name='featured'
                  value={values.featured ? values.featured : 0}
                  onChange={handleChange}
                  label='Featured'>
                  {getFeaturedOptions().map(x => (
                    <MenuItem key={x.value} value={x.value}>
                      {x.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Featured posts will display on the library homepage. You can either
                  feature the text content (and feature image if added), or the video.
                </FormHelperText>
              </FormControl>
            </Paper>
          )}
        </Box>

        <FileManagerProvider post={post}>
          <Box className={classes.column2}>
            <FileEditor
              onAddFile={handleAddFile}
              onDeleteFile={handleDeleteFile}
              onAddFolder={handleAddFolder}
              onDeleteFolder={handleDeleteFolder}
              onSetFileDisplayType={handleUpdateFileDisplayType}
              onSortFiles={handleSortFiles}
              onSortFolders={handleSortFolders}
            />
          </Box>
        </FileManagerProvider>
      </Box>

      {(!post || !post.data.published) && handleChangeNotify && (
        <FormControlLabel
          control={<Checkbox checked={notify} onChange={handleChangeNotify} />}
          label={'Notify users when this post is published'}
        />
      )}

      {errors.length > 0 && (
        <MuiAlert severity='error'>
          {errors.map(x => (
            <div>{x[1]}</div>
          ))}
        </MuiAlert>
      )}
    </Box>
  )
}

function FormCreater({ data, handleChange }) {
  const defaultForm = { email_to: [], name: '', description: '', fields: [] }
  const defaultNewField = { type: '', label: '', required: '1', option_items: [] }

  const classes = useStyles()
  const [formEnabled, setFormEnabled] = useState(data ? true : false)
  const [form, setForm] = useState(data ? data : defaultForm)
  const [newField, setNewField] = useState(defaultNewField)

  const handleToggleFormEnabled = () => {
    const newValue = !formEnabled
    // disabling form will send null to backend to pass validation
    handleChange({ target: { name: 'form', value: newValue ? form : null } })
    setFormEnabled(newValue)
  }

  const handleChangeEmailTo = value => {
    handleChangeForm('email_to', value)
  }

  const handleChangeFormName = e => {
    handleChangeForm('name', e.target.value)
  }

  const handleChangeFormDescription = e => {
    handleChangeForm('description', e.target.value)
  }

  const handleChangeForm = (fieldName, value) => {
    const newForm = { ...form }
    newForm[fieldName] = value
    setForm(newForm)
    handleChange({ target: { name: 'form', value: newForm } })
  }

  const handleChangeNewFieldType = e => {
    handleChangeNewField('type', e.target.value)
  }

  const handleChangeNewFieldLabel = e => {
    handleChangeNewField('label', e.target.value)
  }

  const handleChangeNewFieldRequired = e => {
    handleChangeNewField('required', e.target.value)
  }

  const handleChangeNewField = (key, value) => {
    const newValue = { ...newField }
    newValue[key] = value
    setNewField(newValue)
  }

  const handleAddNewField = () => {
    const newForm = { ...form }
    newForm.fields.push(newField)
    setForm(newForm)
    setNewField(defaultNewField)
  }

  const canAddNewField = () => {
    const optionitemsSet = ['dropdown', 'multiselect'].includes(newField.type)
      ? newField.option_items.length > 0
      : true

    return optionitemsSet && newField.type.length > 0 && newField.label.length > 0
  }

  const removeField = index => {
    const newForm = { ...form }
    newForm.fields.splice(index, 1)
    setForm(newForm)
  }

  const moveFieldUp = index => {
    const newForm = { ...form }
    const item = newForm.fields[index]
    const newIndex = index - 1

    if (newIndex >= 0) {
      newForm.fields.splice(index, 1)
      newForm.fields.splice(newIndex, 0, item)
      setForm(newForm)
    }
  }

  const moveFieldDown = index => {
    const newForm = { ...form }
    const item = newForm.fields[index]
    const newIndex = index + 1

    if (newIndex < newForm.fields.length) {
      newForm.fields.splice(index, 1)
      newForm.fields.splice(newIndex, 0, item)
      setForm(newForm)
    }
  }

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={formEnabled}
            onChange={handleToggleFormEnabled}
            name='form_enabled'
            color='primary'
          />
        }
        label='Enabled'
      />

      {formEnabled && (
        <>
          <TextField
            variant='standard'
            margin='normal'
            required
            fullWidth
            id='form_name'
            label='Form Name'
            name='form_name'
            value={form.name}
            inputProps={{ maxLength: 100 }}
            onChange={handleChangeFormName}
            helperText='This will be displayed to users.'
          />

          <TextField
            multiline
            rows={5}
            variant='standard'
            margin='normal'
            fullWidth
            id='form_description'
            label='Form Description'
            name='form_description'
            value={form.description}
            inputProps={{ maxLength: 600 }}
            onChange={handleChangeFormDescription}
            helperText='This will be displayed to users.'
          />

          <Autocomplete
            multiple
            freeSolo
            id='form_email_to'
            options={[]}
            value={form.email_to}
            onChange={(_, newValue) => handleChangeEmailTo(newValue)}
            renderInput={params => (
              <TextField
                {...params}
                variant='standard'
                onBlur={event => {
                  if (event.target.value) {
                    const items = Array.from(form.email_to)
                    items.push(event.target.value)
                    handleChangeEmailTo(items)
                  }
                }}
                label='Email To'
                placeholder='email1@example.com, mail2@example.com'
                helperText='Email address(es) that is emailed when the form is submitted.'
              />
            )}
          />

          <Box marginTop={3}>
            <Typography variant='h6' gutterBottom>
              Form Fields
            </Typography>

            <TableContainer>
              <Table>
                <TableBody>
                  {form.fields.map((x, index) => (
                    <TableRow key={x.label}>
                      <TableCell>
                        {x.type}
                        <br />
                        {x.required === '0' || !x.required ? '(optional)' : '(required)'}
                      </TableCell>
                      <TableCell>
                        {x.label}
                        {['dropdown', 'multiselect'].includes(x.type) && (
                          <>
                            <Box marginTop={1} marginBottom={1}>
                              <em>{x.option_items.length} Option(s)...</em>
                            </Box>
                            {x.option_items.map((x, index) => (
                              <div key={`${x.name}-${index}`}>{x}</div>
                            ))}
                          </>
                        )}
                      </TableCell>
                      <TableCell align='right'>
                        <IconButton
                          style={{ visibility: index !== 0 ? 'visible' : 'hidden' }}
                          color='primaryt'
                          onClick={() => moveFieldUp(index)}>
                          <UpIcon />
                        </IconButton>
                        <IconButton
                          style={{
                            visibility:
                              index !== form.fields.length - 1 ? 'visible' : 'hidden',
                          }}
                          color='primaryt'
                          onClick={() => moveFieldDown(index)}>
                          <DownIcon />
                        </IconButton>
                        <IconButton color='secondary' onClick={() => removeField(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box marginTop={4}>
            <strong>Add new form field</strong>
            <FormControl variant='standard' className={classes.formControlNoMargin}>
              <InputLabel id='new_field_type_label'>Form Field Type</InputLabel>
              <Select
                labelId='new_field_type_label'
                id='new_field_type'
                name='new_field_type'
                value={newField.type}
                onChange={handleChangeNewFieldType}
                label='Form Field Type'>
                <MenuItem value='textfield'>Textfield</MenuItem>
                <MenuItem value='textarea'>Textarea</MenuItem>
                <MenuItem value='dropdown'>Dropdown</MenuItem>
                <MenuItem value='multiselect'>Multiselect</MenuItem>
              </Select>
            </FormControl>

            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='new_field_label'
              label='Label'
              name='new_field_label'
              inputProps={newField.type !== 'multiselect' && { maxLength: 100 }}
              value={newField.label}
              onChange={handleChangeNewFieldLabel}
            />

            <FormControl variant='standard' className={classes.formControlNoMargin}>
              <InputLabel id='new_field_required_label'>Required?</InputLabel>
              <Select
                labelId='new_field_required_label'
                id='new_field_required'
                name='new_field_required'
                value={newField.required}
                onChange={handleChangeNewFieldRequired}
                label='Required?'>
                <MenuItem value='1'>Yes</MenuItem>
                <MenuItem value='0'>no</MenuItem>
              </Select>
            </FormControl>

            {['dropdown', 'multiselect'].includes(newField.type) && (
              <Autocomplete
                multiple
                freeSolo
                id='new_field_option_items'
                options={[]}
                value={newField.option_items}
                onChange={(_, newValue) => handleChangeNewField('option_items', newValue)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='standard'
                    onBlur={event => {
                      if (event.target.value) {
                        const newOptionItems = Array.from(newField.option_items)
                        newOptionItems.push(event.target.value)
                        handleChangeNewField('option_items', newOptionItems)
                      }
                    }}
                    label='Options'
                    placeholder='Option 1, Option 2, Option 3'
                    helperText='Options to display'
                  />
                )}
              />
            )}

            <Box marginTop={3}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                disabled={!canAddNewField()}
                onClick={handleAddNewField}>
                Add Field
              </Button>
            </Box>
          </Box>
        </>
      )}
    </div>
  )
}

PostForm.propTypes = {
  canEmbedForm: PropTypes.bool,
}

PostForm.defaultProps = {
  canEmbedForm: false,
}

export default PostForm
