import { Paper } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { confirmTerms } from 'api/auth'
import { AuthContext } from 'contexts/AuthContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('md'),
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  text: {
    marginBottom: theme.spacing(3),
  },
}))

function Terms(props) {
  const { setLoading } = useContext(LoadingContext)
  const { user, setUser } = useContext(AuthContext)
  const [confirming, setConfirming] = useState(false)
  const [error, setError] = useState('')
  const classes = useStyles()

  useEffect(() => {
    setLoading(false)
  })

  const handleConfirm = async event => {
    event.preventDefault()

    setConfirming(true)
    if (await confirmTerms()) {
      setUser({ ...user, tandcs: true })
    } else {
      setError('Failed to confirm terms and conditions')
      setConfirming(false)
    }
  }

  return (
    <div className={classes.container}>
      <Typography variant='h4' gutterBottom>
        Terms and Conditions
      </Typography>

      {error && (
        <MuiAlert className={classes.error} severity='error'>
          {error}
        </MuiAlert>
      )}

      <Paper className={classes.paper}>
        <Typography className={classes.text}>
          This tool is designed to make the preparation of iPECS Cloud and iPECS UCP
          quotes a simple and easily repeatable task. All efforts have been made to ensure
          that prices and configuration logic provided in the default version of this tool
          are accurate. However the administration of this tool is managed by an
          administrator within your company so please ensure that they have completed all
          set up and individual pricing for your company before releasing final quotations
          to your customers.
        </Typography>

        <Typography className={classes.text}>
          The iPECS QuickQuote tool is designed to be used by iPECS Trained Sales
          professionals with a full understanding of the iPECS Cloud and iPECS UCP
          platforms.
        </Typography>

        <Typography className={classes.text}>
          The management of user access to this tool is managed by a nominated sales
          administrator with your business so please ensure that access privileges are
          regularly reviewed and updated.
        </Typography>

        <Typography className={classes.text}>
          It remains the sole responsibility of the reseller to ensure that any quotes,
          configurations or pricing provided to end users is accurate and up to date.
        </Typography>

        <Typography className={classes.text}>
          If you wish to ask any further questions before agreeing to these terms then
          please email: sales@wearepragma.co.uk before continuing.
        </Typography>

        <Typography className={classes.text}>E&OE</Typography>
      </Paper>

      <Button
        disabled={confirming}
        onClick={handleConfirm}
        variant='contained'
        color='primary'>
        I agree to the terms and conditions
      </Button>
    </div>
  )
}

export default Terms
