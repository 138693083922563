import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'api/library/posts'
import EditButton from 'components/library/post/EditButton'
import PostView from 'components/library/post/PostView'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PostEdit from './PostEdit'
import { BreadcrumbContext } from 'contexts/BreadcrumbContext'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
}))

function Post(props) {
  const classes = useStyles()
  const { id } = useParams()
  const { setLoading } = useContext(LoadingContext)
  const [post, setPost] = useState()
  const [editMode, setEditMode] = useState(false)
  const { setBreadcrumbs } = useContext(BreadcrumbContext)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await get('my-library', id)
      setPost(res)
      setBreadcrumbs([
        { label: 'Home', path: '/' },
        { label: 'My Library', path: 'My Library' },
        { label: res.data.name },
      ])
      setLoading(false)
    }

    fetchData()
  }, [id, setLoading, setBreadcrumbs])

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  if (!post) {
    return null
  }

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Box mb={2}>
          <EditButton editMode={editMode} toggleEditMode={toggleEditMode} />
        </Box>

        {editMode ? (
          <PostEdit
            post={post}
            setPost={setPost}
            toggleEditMode={toggleEditMode}
            history={props.history}
          />
        ) : (
          <PostView post={post} />
        )}
      </Box>
    </React.Fragment>
  )
}

export default Post
