import config from 'config'

export async function siteNav(type) {
  try {
    const result = await fetch(config.API_URL + '/api/form-options/site-nav/' + type)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function libraryPost(type) {
  try {
    const result = await fetch(config.API_URL + '/api/form-options/library-post/' + type)
    return await result.json()
  } catch (e) {
    return false
  }
}
